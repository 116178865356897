import React, { useEffect } from "react";
import SignUpComponent from "./signup.page";
import qs from "query-string";
import { connect } from "react-redux";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { initiateWithPreselectPlan } from "../../redux/slice/signup.slice";
import { useHistory, useRouteMatch } from "react-router-dom";
import { FULL_FREE_SIGN_UP, PARTIAL_FREE_SIGN_UP } from "../../../../modules/public/config/public.route";
import { ROOT } from "app/config/route";

function SignUpContainer({ location: { search }, initiateWithPreselectPlan }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const type = "SignUp";
  const route = useRouteMatch();
  const history = useHistory();
  const path = route.path;

  useEffect(() => {
    // localStorage.removeItem('session_id')
    // localStorage.removeItem('session_expiration')

    if (!search) return;
    const { plan, type } = qs.parse(search);
    initiateWithPreselectPlan({ plan, type });
  }, [initiateWithPreselectPlan, search]);

  useEffect(() => { // replace two incoming full and partial free sign up routes to root
    if(path === FULL_FREE_SIGN_UP || path === PARTIAL_FREE_SIGN_UP) {
      history.replace(ROOT);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SignUpComponent isMobile={isMobile} type={type} />;
}

const mapDispatchToProps = dispatch => ({
  initiateWithPreselectPlan: value => dispatch(initiateWithPreselectPlan(value))
});

export default connect(null, mapDispatchToProps)(SignUpContainer);
