import React, { useRef } from "react";
import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import { DragIndicator as DragIcon } from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import InputTextField from "components/input/inputTextField";
import { ErrorMessage } from "formik";

const useStyles = makeStyles((theme) => ({
  content: {
    border: "1px solid #D0D5DD",
    backgroundColor: "#ffffff",
    borderRadius: 4,
  },
  prizeImg: {
    width: 90,
    height: 90,
    objectFit: "contain",
    borderRadius: 4,
    marginRight: 12,
    border: "1px solid #c2c2c2",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "4fr 7fr",
  },
  radioRoot: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export default function DraggablePrizeCardComponent({
  index,
  moveCard,
  lang,
  prizeList,
  isSubmitting,
  prize,
  formik,
}) {
  const classes = useStyles();
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (!isSubmitting) {
        moveCard(dragIndex, hoverIndex, prizeList);
      }

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { index, type: "card" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const prizeRank = {
    1: getLang(lang, "label.GRAND_PRIZE"),
    2: getLang(lang, "label.SECOND_PRIZE"),
    3: getLang(lang, "label.THIRD_PRIZE"),
    4: getLang(lang, "label.FOURTH_PRIZE"),
    5: getLang(lang, "label.FIFTH_PRIZE"),
  };

  let prizeWithRank = prizeList.filter((item) => !!Number(item.rank_position));

  return (
    <Box ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Box mb={1.5} width="100%" key={index} display="flex">
        <Box
          display="flex"
          alignItems="flex-start"
          flex={1}
          className={classes.content}
          py={1.5}
          pl={0.5}
          pr={1}
        >
          <Box mr={0.5}>
            <IconButton size="small">
              <DragIcon style={{ fontSize: 22 }} />
            </IconButton>
          </Box>
          <Box display="flex">
            <img
              className={classes.prizeImg}
              alt="prize"
              src={prize.image_url}
            />
            <Box>
              <Typography variant="body1">
                <b>
                  {!!Number(prize.rank_position)
                    ? prizeRank[Number(prize.rank_position)]
                    : !!prizeWithRank
                    ? getLang(lang, "label.OTHER_PRIZE")
                    : getLang(lang, "label.OUR_PRIZE")}{" "}
                  | {prize.name}
                </b>
              </Typography>
              <Box className={classes.field} mt={1.0}>
                <Typography variant="body2">
                  {getLang(lang, "label.TYPE")}
                </Typography>
                <Typography variant="body2">
                  {prize.type === 2
                    ? getLang(lang, "label.CREDIT_VALUE")
                    : prize.type === 3
                    ? getLang(lang, "label.REDEMPTION_CODE")
                    : getLang(lang, "label.ITEM")}
                </Typography>
              </Box>
              <Box className={classes.field} mt={1}>
                <Typography variant="body2">
                  {getLang(lang, "label.QUANTITY")}
                </Typography>
                <Typography variant="body2">{prize.quantity}</Typography>
              </Box>
              <Box className={classes.field} display="flex" mt={1}>
                <Typography variant="body2" style={{ marginTop: 4 }}>
                  {getLang(lang, "label.DRAW_METHOD")}
                </Typography>
                <Box>
                  <RadioGroup
                    name="selected"
                    value={prize.draw_type || null}
                    onChange={() => {}}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          size="small"
                          classes={{ root: classes.radioRoot }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {getLang(lang, "label.ONE_BY_ONE")}
                        </Typography>
                      }
                      onChange={() => {
                        let temp = [...prizeList];
                        temp[index] = {
                          ...temp[index],
                          draw_type: 1,
                        };
                        formik.setFieldValue("prizes", temp);
                      }}
                    />
                    <FormControlLabel
                      value={3}
                      control={
                        <Radio
                          size="small"
                          classes={{ root: classes.radioRoot }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {getLang(lang, "label.ONE_BY_ONE_CAROUSEL")}
                        </Typography>
                      }
                      onChange={() => {
                        let temp = [...prizeList];
                        temp[index] = {
                          ...temp[index],
                          draw_type: 3,
                        };
                        formik.setFieldValue("prizes", temp);
                      }}
                    />
                    <FormControlLabel
                      value={2}
                      control={
                        <Radio
                          size="small"
                          classes={{ root: classes.radioRoot }}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {getLang(lang, "label.ALL_AT_ONCE")}
                        </Typography>
                      }
                      onChange={() => {
                        let temp = [...prizeList];
                        temp[index] = {
                          ...temp[index],
                          draw_type: 2,
                        };
                        formik.setFieldValue("prizes", temp);
                      }}
                    />
                  </RadioGroup>
                </Box>
              </Box>

              <Box className={classes.field} display="flex" mt={1}>
                <Typography variant="body2" style={{ marginTop: 4 }}>
                  {getLang(lang, "label.DRAW_DURATION")}
                </Typography>
                <Box display="flex">
                  <InputTextField
                    value={prize.draw_duration}
                    type="number"
                    InputStyle={{
                      backgroundColor: "white",
                      border: "1px solid #d0d5dd",
                      height: 27,
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    inputStyle={{ fontSize: 14 }}
                    style={{ width: "50%", marginRight: 8 }}
                    onChange={(e) => {
                      let temp = [...prizeList];
                      temp[index] = {
                        ...temp[index],
                        draw_duration: e.target.value,
                      };
                      formik.setFieldValue("prizes", temp);
                    }}
                    error={
                      formik.touched.prizes?.[index]?.draw_duration &&
                      formik.errors.prizes?.[index]?.draw_duration
                        ? true
                        : false
                    }
                    helperText={ErrorMessage({
                      name: `prizes.[${index}].draw_duration`,
                    })}
                  />
                  <Typography variant="body2" style={{ marginTop: 4 }}>
                    {getLang(lang, "label.SECONDS")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
