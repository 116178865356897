import { addAlert } from "modules/notification";
import React from "react";
import MyDealerListTableComponent from "./myDealerListTable.component";
import { connect, useSelector } from "react-redux";

function MyDealerListTableContainer({
  hasStockflowDealerViewAccessRight,
  hasStockflowDealerEditAccessRight,
  hasStockflowDealerAddAccessRight,
  rows,
  rowsPerPage,
  paginationOptions,
  page,
  totalFiltered,
  isFetching,
  isError,
  isSearchResult,
  handleReload,
  handleViewDetail,
  handleReplaceDealerDialog,
  handleEditPointDialog,
  handleChangePage,
  handleChangeRowPerPage,
  handleUpdateDealerStatus,
  handleUpdateDealerUpline,
  language,
  handleVisibleInviteDialog,
  handleLoadIncentivePointDialog,
  handleSelectAllDealer,
  handleSelectAvailable,
  handleDeselectAll,
  handleSelectDealer,
  isSelectAll,
  selectedUUIDs,
  handleResendEmail,
  isDealerPage = false,
  addSuccessAlert,
}) {
  const lang = useSelector(state => state.constant.languages);
  return (
    <MyDealerListTableComponent
      hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
      hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
      hasStockflowDealerAddAccessRight={hasStockflowDealerAddAccessRight}
      rows={rows}
      rowsPerPage={rowsPerPage}
      language={language}
      paginationOptions={paginationOptions}
      page={page}
      totalFiltered={totalFiltered}
      isFetching={isFetching}
      isError={isError}
      isSearchResult={isSearchResult}
      handleReload={handleReload}
      handleViewDetail={handleViewDetail}
      handleReplaceDealerDialog={handleReplaceDealerDialog}
      handleEditPointDialog={handleEditPointDialog}
      handleChangePage={handleChangePage}
      handleChangeRowPerPage={handleChangeRowPerPage}
      handleUpdateDealerStatus={handleUpdateDealerStatus}
      handleUpdateDealerUpline={handleUpdateDealerUpline}
      handleVisibleInviteDialog={handleVisibleInviteDialog}
      handleLoadIncentivePointDialog={handleLoadIncentivePointDialog}
      handleSelectAllDealer={handleSelectAllDealer}
      handleSelectAvailable={handleSelectAvailable}
      handleDeselectAll={handleDeselectAll}
      handleSelectDealer={handleSelectDealer}
      isSelectAll={isSelectAll}
      selectedUUIDs={selectedUUIDs}
      handleResendEmail={handleResendEmail}
      lang={lang}
      isDealerPage={isDealerPage}
      addSuccessAlert={addSuccessAlert}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  addSuccessAlert: (value) =>
    dispatch(addAlert({ severity: "success", message: value })),
});

export default connect(
  null,
  mapDispatchToProps
)(MyDealerListTableContainer);