import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDealerDetails, getDealerDropdown, updateDealerUpline } from 'modules/stockflow/redux';
import { selectLoading } from 'modules/notification';
import UpdateDealerUplineDialogComponent from './updateDealerUplineDialog.component';

function UpdateDealerUplineDialogContainer({
  isOpen,
  setOpen,
  id,
  dealerDetails, 
  handleReload,
}) {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.constant.languages);
  const fetchDetails = useSelector(state => state.stockflowDealer.dealerDetails);
  const dealerDropdown = useSelector(state => state.stockflowDealer.dropdowns.dealerListDropdownData);
  const isFetching = useSelector(state => selectLoading(state, getDealerDetails.typePrefix));
  const isSubmitting = useSelector(state => selectLoading(state, updateDealerUpline.typePrefix));
  const isFetchingDealerDropdown = useSelector(state => selectLoading(state, getDealerDropdown.typePrefix));
  const isError = useSelector(state => state.stockflowDealer.updateDealerUpline.isError);
  const [uplineType, setUplineType] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const details = dealerDetails || fetchDetails;

  const SELECT_UPLINE = 'selectUpline';
  const NO_UPLINE = 'noUpline';

  useEffect(() => {
    if (!id || !!dealerDetails) return;
    dispatch(getDealerDetails(id));
    // eslint-disable-next-line
  }, [id, dispatch])

  useEffect(() => {
    if (!details) return;
    const upline = details.upline ? SELECT_UPLINE : NO_UPLINE;
    setUplineType(upline);
    if (upline === SELECT_UPLINE) {
      dispatch(getDealerDropdown(details.branch.uuid));
      setSelectedDealer(details.upline);
    }
  }, [details, dispatch])

  const handleUplineTypeChange = (e) => {
    const value = e.target.value;
    setUplineType(value);
    if (e.target.value === SELECT_UPLINE) {
      dispatch(getDealerDropdown(details.branch?.uuid));
    } else {
      setSelectedDealer(null);
    }
  }

  const handleUplineSelectChange = (dealer) => {
    setSelectedDealer(dealer);
  }

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleUplineUpdate = () => {
    if (isFetching || !details) return;
    dispatch(
      updateDealerUpline({
        dealerUuid: id || details.uuid,
        targetDealerUuid: selectedDealer?.uuid || null,
      })
    );
  }

  useEffect(() => {
    if (isSubmitting || isError) return;
    handleReload();
    // eslint-disable-next-line
  }, [isSubmitting, isError])

  return (
    <UpdateDealerUplineDialogComponent
      open={isOpen}
      handleClose={handleClose}
      lang={lang}
      handleUplineUpdate={handleUplineUpdate}
      details={details || details}
      isFetching={isFetching}
      uplineType={uplineType}
      handleUplineTypeChange={handleUplineTypeChange}
      handleUplineSelectChange={handleUplineSelectChange}
      isSubmitting={isSubmitting}
      selectedDealer={selectedDealer}
      dealerDropdown={dealerDropdown}
      isFetchingDealerDropdown={isFetchingDealerDropdown}
      SELECT_UPLINE={SELECT_UPLINE}
      NO_UPLINE={NO_UPLINE}
    />
  );
}

export default UpdateDealerUplineDialogContainer;
