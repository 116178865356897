import { createAsyncThunk } from '@reduxjs/toolkit';
import accountApi from 'app/api/account';
import serialNumberApi from 'app/api/serialnumber';

export const PRODUCTS = "products";

export const getCurrencyDropdown = createAsyncThunk(
  `${PRODUCTS}/getCurrencyDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.getConfiguredCurrencyDropdownList({
      search: payload?.search || ''
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);

export const checkFacebookEmbed = createAsyncThunk(
  `${PRODUCTS}/checkFacebookEmbed`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi.checkFacebookEmbed(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
);
