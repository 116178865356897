import {
  addLanguage,
  updateComponent,
  deleteLanguage,
  addComponent
} from "../slice/productPage.slice";
import {
  getInitialAuthenticationContent,
  createAuthModal,
  createMarketplaceContentModel,
  createGalleryContentModel
} from "../../utils/productPage.util";
import { checkFacebookEmbed, getCurrencyDropdown } from "../action/productPage.action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";


const addLanguageMiddleware = ({ dispatch, getState }) => next => action => {
  const { type, payload } = action;
  if (type === addLanguage.type) {
    /**
     * Alter components which are affected by the language addition
     * Those components include authentication, text, and URL link
     */
    const {
      components,
      columns: { compIds }
    } = getState().productPage;

    const affectedComponentTypes = ["authentication", "text", "website"];
    const affectedCompIds = compIds.filter(id => {
      const type = id.split("-")[0];
      return affectedComponentTypes.includes(type);
    });

    affectedCompIds.forEach(id => {
      const type = id.split("-")[0];
      let newContent;
      switch (type) {
        case affectedComponentTypes[0]:
          newContent = {
            messages: [
              ...components[id].content.messages,
              { lang: payload, value: "" }
            ]
          }
          break;

        case affectedComponentTypes[1]:
          newContent = components[id].content;
          const data = Array.from(newContent.data);
          const newTextData = { lang: payload, value: "<p></p>" };
          data.push(newTextData);
          newContent = { data };
          break;

        case affectedComponentTypes[2]:
          newContent = components[id].content;
          const descriptions = Array.from(newContent.descriptions);
          const newDescData = { lang: payload, value: "" };
          descriptions.push(newDescData);
          newContent = { ...newContent, descriptions };
          break;

        default:
          break;
      }
      dispatch(updateComponent({ id, content: newContent }));
    });

    next(action);
  } else {
    next(action);
  }
};

const deleteLanguageMiddleware = ({ dispatch, getState }) => next => action => {
  const { type, payload } = action;
  if (type === deleteLanguage.type) {
    /**
     * Alter components which are affected by the language deletion
     * Those components include authentication, text, and URL link
     */
    const {
      components,
      columns: { compIds }
    } = getState().productPage;

    const affectedComponentTypes = ["authentication", "text", "website"];
    const affectedCompIds = compIds.filter(id => {
      const type = id.split("-")[0];
      return affectedComponentTypes.includes(type);
    });

    affectedCompIds.forEach(id => {
      const type = id.split("-")[0];
      let newContent;

      switch (type) {
        case affectedComponentTypes[0]:
          newContent = {
            messages: [
              ...components[id].content.messages.filter(x => x.lang !== payload),
            ]
          };
          break;

        case affectedComponentTypes[1]:
          newContent = components[id].content;
          const data = Array.from(newContent.data).filter(
            ({ lang }) => lang !== payload
          );
          newContent = { ...newContent, data };
          break;

        case affectedComponentTypes[2]:
          newContent = components[id].content;
          const descriptions = Array.from(newContent.descriptions).filter(
            ({ lang }) => lang !== payload
          );
          newContent = { ...newContent, descriptions };
          break;

        default:
          break;
      }
      dispatch(updateComponent({ id, content: newContent }));
    });

    next(action);
  } else {
    next(action);
  }
};

const addComponentMiddleware = ({ getState }) => next => action => {
  const { type, payload } = action;
  if (type === addComponent.type && payload.prefillDefaultContent) {
    /**
     * if prefillDefaultContent flag exists and is true in its payload,
     * modify the payload with a default content of its component
     */
    const { lang } = getState().productPage;
    const { type } = payload;
    let content;

    switch (type) {
      case "authentication":
        const initialValues = createAuthModal().content;

        content = getInitialAuthenticationContent("new", lang, initialValues);
        break;

      case "marketplace":
        content = createMarketplaceContentModel();
        break;

      case "gallery":
        content = createGalleryContentModel();
        break;

      default:
        content = {};
        break;
    }

    const modifiedPayload = { type, content };

    next({ ...action, payload: modifiedPayload });
  } else {
    next(action);
  }
};

const getCurrencyDropdownMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action);

  const { type, payload } = action

  switch (type) {
    case getCurrencyDropdown.pending.type:
      dispatch(setLoading({ id: getCurrencyDropdown.typePrefix, state: true }));
      break;
    case getCurrencyDropdown.fulfilled.type:
      dispatch(setLoading({ id: getCurrencyDropdown.typePrefix, state: false }));
      break;
    case getCurrencyDropdown.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: getCurrencyDropdown.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};

const checkFacebookEmbedMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action);

  const { type, payload } = action

  switch (type) {
    case checkFacebookEmbed.pending.type:
      dispatch(setLoading({ id: checkFacebookEmbed.typePrefix, state: true }));
      break;
    case checkFacebookEmbed.fulfilled.type:
      dispatch(setLoading({ id: checkFacebookEmbed.typePrefix, state: false }));
      break;
    case checkFacebookEmbed.rejected.type:
      const lang = getState().constant.languages
      dispatch(setLoading({ id: checkFacebookEmbed.typePrefix, state: false }));
      dispatch(
        addAlert({
          severity: "error",
          message: getApiLang(lang, payload.code)
        })
      );
      break;
    default:
      break;
  }
};
export default [
  addLanguageMiddleware,
  deleteLanguageMiddleware,
  addComponentMiddleware,
  getCurrencyDropdownMiddleware,
  checkFacebookEmbedMiddleware
];
