import React, { useRef } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  DragIndicator as DragIcon,
  Help as HelpIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import { ErrorMessage } from "formik";
import { DustBinSVG } from "modules/lucky-draw/utils/static";
import {
  participantFieldTypeChoice,
  additionalParticipantFormFields,
} from "modules/lucky-draw-v2/utils/constants";
import AntSwitch from "components/switch";

const useStyles = makeStyles((theme) => ({
  content: {
    border: "1px solid #DBDBDB",
    backgroundColor: "#ffffff",
  },
  selectRoot: {
    padding: 11,
  },
  optionContainer: {
    border: "1px solid #c6c5c5",
    borderRadius: 4,
    padding: "4px 12px",
  },
  chip: {
    backgroundColor: "#f3f3f3",
    margin: "4px 4px 4px 0px",
    border: "none",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 5px 0px rgba(0,0,0,0.02)",
  },
}));

export default function DraggableParticipantInfoCardComponent({
  index,
  lang,
  info,
  deleteInfo,
  formik,
  disabled,
}) {
  const classes = useStyles();

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense",
  };

  const textRef = useRef();

  return (
    <Box width="100%">
      <Box mb={1.5} width="100%" key={index} display="flex">
        <Box mt={0.5} mr={1}>
          <IconButton size="small">
            <DragIcon style={{ fontSize: 22 }} />
          </IconButton>
        </Box>
        <Box className={classes.content} p={2} flex={1}>
          <Box display="flex" width="100%" style={{ gap: 16 }}>
            <Box flex={2}>
              <Typography variant="body2">
                <b>
                  {getLang(lang, "label.QUESTION")} {index + 1}
                </b>
              </Typography>
              <TextField
                {...textFieldProps}
                {...formik.getFieldProps(`formFields.[${index}].variantName`)}
                type={"text"}
                placeholder={getLang(lang, "placeholder.ENTER_VALUE_HERE")}
                name={`formFields.[${index}].variantName`}
                value={
                  info?.fieldName.startsWith("custom_")
                    ? info.variantName
                    : getLang(lang, `db_label.${info.fieldName}`)
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `formFields.[${index}].variantName`,
                    e.target.value
                  );
                  if (!formik.values.isEditing) {
                    formik.setFieldValue("isEditing", true);
                  }
                }}
                error={
                  formik.touched.formFields?.[index]?.variantName &&
                  formik.errors.formFields?.[index]?.variantName
                }
                helperText={ErrorMessage({
                  name: `formFields.[${index}].variantName`,
                })}
                disabled={
                  disabled ||
                  !formik.values.formFields[index]?.fieldName.startsWith(
                    "custom_"
                  )
                }
                inputProps={{
                  style: { color: "black" },
                }}
              />
            </Box>
            <Box flex={1}>
              <Typography variant="body2">
                <b>{getLang(lang, "label.FIELD_TYPE")}</b>
              </Typography>
              {info?.fieldName.startsWith("custom_") ? (
                <Box mt={1}>
                  <Typography variant="body2">
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        {...formik.getFieldProps(`formFields.[${index}].type`)}
                        name={`formFields.[${index}].type`}
                        value={info.type}
                        disabled={disabled}
                        onChange={(e) => {
                          let selectedField = participantFieldTypeChoice.find(
                            (f) => f.value === e.target.value
                          );

                          if (selectedField) {
                            let idx = participantFieldTypeChoice.findIndex(
                              (f) => f.value === e.target.value
                            );
                            let fieldName = info.fieldName.startsWith("custom_")
                              ? `custom_${idx}`
                              : info.fieldName;
                            let temp = {
                              ...info,
                              fieldName: fieldName,
                              type: selectedField.value,
                              labelType: "",
                              fieldType: selectedField.fieldType,
                            };
                            formik.setFieldValue(`formFields.[${index}]`, temp);
                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }
                        }}
                        style={{
                          color: "black",
                        }}
                        classes={{ root: classes.selectRoot }}
                        displayEmpty
                      >
                        {participantFieldTypeChoice.map((field, indexadd) => (
                          <MenuItem key={indexadd} value={field.value}>
                            {getLang(lang, field.labelType)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Typography>
                </Box>
              ) : (
                <Box mt={2}>
                  <Typography variant="body2">
                    {additionalParticipantFormFields.map((field) =>
                      field.fieldName ===
                      formik.values.formFields[index]?.fieldName
                        ? `${getLang(lang, field.description)} (${getLang(
                            lang,
                            "label.STANDARD_INFO"
                          )})`
                        : null
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box mt={1}>
            <Typography variant="body2">
              <b>{getLang(lang, "label.TOOLTIP")}</b>
            </Typography>
            <TextField
              {...textFieldProps}
              {...formik.getFieldProps(`formFields.[${index}].tooltip`)}
              type={"text"}
              placeholder={getLang(lang, "placeholder.TOOLTIP")}
              name={`formFields.[${index}].tooltip`}
              value={info.tooltip}
              onChange={(e) => {
                formik.setFieldValue(
                  `formFields.[${index}].tooltip`,
                  e.target.value
                );
                if (!formik.values.isEditing) {
                  formik.setFieldValue("isEditing", true);
                }
              }}
              disabled={disabled}
              inputProps={{
                style: { color: "black" },
              }}
            />
          </Box>
          {(info.type === "multipleChoice" ||
            info.type === "multipleChoiceMultiSelect" ||
            info.type === "dropdownlist") && (
            <Box mt={1} mb={1}>
              <Typography variant="body2">
                <b>{getLang(lang, "label.OPTIONS")}</b>
              </Typography>
              <Box>
                {!!info.choice?.length && (
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    {info.choice?.map((choice, choiceIndex) => (
                      <Chip
                        key={choiceIndex}
                        variant="outlined"
                        index={choiceIndex}
                        label={
                          info.fieldName.startsWith("custom_")
                            ? choice
                            : getLang(lang, `db_label.${choice}`)
                        }
                        size="small"
                        className={classes.chip}
                        onDelete={
                          !disabled && info.fieldName.startsWith("custom_")
                            ? () => {
                                let choices = [...info.choice];
                                choices.splice(choiceIndex, 1);
                                formik.setFieldValue(
                                  `formFields.[${index}].choice`,
                                  choices
                                );
                                if (!formik.values.isEditing) {
                                  formik.setFieldValue("isEditing", true);
                                }
                              }
                            : null
                        }
                      />
                    ))}
                  </Box>
                )}
                {info.fieldName.startsWith("custom_") && (
                  <>
                    <TextField
                      {...textFieldProps}
                      inputRef={textRef}
                      type={"text"}
                      placeholder={getLang(
                        lang,
                        "placeholder.ENTER_VALUE_HERE"
                      )}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && textRef.current.value !== "") {
                          e.preventDefault();
                          e.stopPropagation();
                          let choices = [...info.choice];
                          choices.push(textRef.current.value);
                          formik.setFieldValue(
                            `formFields.[${index}].choice`,
                            choices
                          );
                          textRef.current.value = "";

                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }
                      }}
                      disabled={disabled}
                      inputProps={{
                        style: { color: "black" },
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{ fontSize: 10, color: "#8B8B8B" }}
                    >
                      {getLang(lang, "message.info.PRESS_ENTER_ADD_NEW_OPTION")}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}
          <Box style={{ gap: 20 }} display="flex" pl={1.5} mt={2}>
            <Box>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={info.mandatory}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `formFields[${index}].mandatory`,
                        e.target.checked
                      );
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={disabled}
                  />
                }
                label={
                  <Typography variant="body2" style={{ marginLeft: 6 }}>
                    {getLang(lang, "label.MANDATORY")}
                  </Typography>
                }
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <AntSwitch
                    checked={info.visible}
                    onChange={(e) => {
                      let newVisibility = e.target.checked;
                      formik.setFieldValue(
                        `formFields[${index}].visible`,
                        newVisibility
                      );
                      if (!newVisibility) {
                        formik.setFieldValue(
                          `formFields[${index}].mandatory`,
                          false
                        );
                      }

                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={disabled}
                  />
                }
                label={
                  <Typography variant="body2" style={{ marginLeft: 6 }}>
                    {getLang(lang, "label.VISIBLE")}
                  </Typography>
                }
              />
            </Box>
            {!["uploadImage", "uploadFile"].includes(info.type) &&
              formik.values.formFields[index]?.fieldName.startsWith(
                "custom_"
              ) && (
                <Box>
                  <FormControlLabel
                    control={
                      <AntSwitch
                        checked={info.autoFill}
                        onChange={(e) => {
                          let newAutoFill = e.target.checked;
                          formik.setFieldValue(
                            `formFields[${index}].autoFill`,
                            newAutoFill
                          );
                          if (!newAutoFill) {
                            formik.setFieldValue(
                              `formFields[${index}].autoFill`,
                              false
                            );
                          }

                          if (!formik.values.isEditing) {
                            formik.setFieldValue("isEditing", true);
                          }
                        }}
                        disabled={disabled}
                      />
                    }
                    label={
                      <Box display="flex" style={{ gap: 4 }} ml={0.75}>
                        <Typography variant="body2">
                          {getLang(lang, "label.AUTO_FILL")}
                        </Typography>
                        <Tooltip
                          title={
                            <Typography variant="body2">
                              {getLang(lang, "tooltips.AUTOFILL_TOOLTIP")}
                            </Typography>
                          }
                        >
                          <HelpIcon
                            style={{ fontSize: 16, color: "#acacac" }}
                          />
                        </Tooltip>
                      </Box>
                    }
                  />
                </Box>
              )}
          </Box>
        </Box>
        <Box ml={1}>
          <Box mt={0.5}>
            <IconButton
              size="small"
              onClick={() => deleteInfo(info)}
              disabled={disabled}
            >
              <DustBinSVG />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
