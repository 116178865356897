import React from "react";
import DealerProfilePanelComponent from "./dealerProfilePanel.component";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { MY_DEALER_LIST_PAGE } from "modules/stockflow/config/stockflow.route";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { setDealerFields, updateDealerDetails } from "modules/stockflow/redux";
import UpdateDealerUplineDialog from '../../dialog/updateDealerUplineDialog';

export default function DealerProfilePanelContainer({
  dealer,
  lang,
  language,
  isLoading,
  isError,
  handleReload,
  hasStockflowDealerEditAccessRight,
}) {
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [openUpdateDealerUplineDialog, setOpenUpdateDealerUplineDialog] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateProfile = async (values) => {
    setIsSaving(true);
    const data = {
      ...values,
      uuid: dealer.uuid,
      phoneCode: values.phoneCode.phone,
    };
    await dispatch(updateDealerDetails(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(setDealerFields(data));
        setIsEdit(false)
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleEditUplineButtonClick = () => {
    setOpenUpdateDealerUplineDialog(true);
  }

  return (
    <>
    <DealerProfilePanelComponent
      dealer={dealer}
      isLoading={isLoading}
      isError={isError}
      handleReload={handleReload}
      handleEditUplineButtonClick={handleEditUplineButtonClick}
      handleViewDetails={id => history.push(`${MY_DEALER_LIST_PAGE}/${id}`)}
      language={language}
      lang={lang}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      isSaving={isSaving}
      handleUpdateProfile={handleUpdateProfile}
      hasStockflowDealerEditAccessRight={hasStockflowDealerEditAccessRight}
      isDatePickerOpen={isDatePickerOpen}
      setIsDatePickerOpen={setIsDatePickerOpen}
    />
    <UpdateDealerUplineDialog
      isOpen={openUpdateDealerUplineDialog}
      setOpen={setOpenUpdateDealerUplineDialog}
      dealerDetails={dealer}
      handleReload={handleReload}
    />
    </>
  );
}
