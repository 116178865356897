import {
  getAllDealerListing,
  updateDealerStatus,
  updateDealerDetailsStatus,
  updateDealerDetails,
  getDealerDetails,
  getBranchDropdown,
  getAdminBranchDropdown,
  getDealerDropdown,
  getDealerIncentivePoint,
  updateDealerIncentivePoint,
  bulkUpdateDealerStatus,
  resendVerificationEmail,
  getDealerRecallList,
  getDealerStockoutList,
  getDealerReceiveList,
  getDealerRequestList,
  getDealerOrderList,
  getDealerInventoryProductListing,
  getDealerInventoryPackageListing,
  getDealerInventoryBoxListing,
  getDealerPointHistoryList,
  getDealerRewardList,
  getDealerPointExpiringList,
  getDealerInventoryLooseItemListing,
  getDealerInvitedTrending,
  getDealerInvitedList,
  getDealerInvitedSummary,
  getTotalStockoutTrending,
  getTotalRecallTrending,
  getStockoutByProduct,
  getBranchDropdownSpecial,
  updateDealerUpline,
} from "../action/stockflowDealer.action";
import { setLoading, addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

const getAllDealerListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getAllDealerListing.pending.type:
        dispatch(
          setLoading({ id: getAllDealerListing.typePrefix, state: true })
        );
        break;
      case getAllDealerListing.fulfilled.type:
        dispatch(
          setLoading({ id: getAllDealerListing.typePrefix, state: false })
        );
        break;
      case getAllDealerListing.rejected.type:
        dispatch(
          setLoading({ id: getAllDealerListing.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateDealerStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case updateDealerStatus.pending.type:
        dispatch(
          setLoading({ id: updateDealerStatus.typePrefix, state: true })
        );
        break;
      case updateDealerStatus.fulfilled.type:
        dispatch(
          setLoading({ id: updateDealerStatus.typePrefix, state: false })
        );
        dispatch(addAlert({ severity: "success", message: payload.code }));
        break;
      case updateDealerStatus.rejected.type:
        dispatch(
          setLoading({ id: updateDealerStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_DEALER_STATUS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerDetailsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getDealerDetails.pending.type:
        dispatch(setLoading({ id: getDealerDetails.typePrefix, state: true }));
        break;
      case getDealerDetails.fulfilled.type:
        dispatch(setLoading({ id: getDealerDetails.typePrefix, state: false }));
        break;
      case getDealerDetails.rejected.type:
        dispatch(setLoading({ id: getDealerDetails.typePrefix, state: false }));
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload?.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_DETAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateDealerDetailsStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case updateDealerDetailsStatus.pending.type:
        dispatch(
          setLoading({ id: updateDealerDetailsStatus.typePrefix, state: true })
        );
        break;
      case updateDealerDetailsStatus.fulfilled.type:
        dispatch(
          setLoading({ id: updateDealerDetailsStatus.typePrefix, state: false })
        );
        dispatch(addAlert({ severity: "success", message: payload.code }));
        break;
      case updateDealerDetailsStatus.rejected.type:
        dispatch(
          setLoading({ id: updateDealerDetailsStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_DEALER_STATUS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateDealeUplineMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case updateDealerUpline.pending.type:
        dispatch(
          setLoading({ id: updateDealerUpline.typePrefix, state: true })
        );
        break;
      case updateDealerUpline.fulfilled.type:
        dispatch(
          setLoading({ id: updateDealerUpline.typePrefix, state: false })
        );
        dispatch(addAlert({
          severity: "success",
          message: getApiLang(
            lang,
            payload.code
          )
        }));
        break;
      case updateDealerUpline.rejected.type:
        dispatch(
          setLoading({ id: updateDealerUpline.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateDealerDetailsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case updateDealerDetails.pending.type:
        dispatch(
          setLoading({ id: updateDealerDetails.typePrefix, state: true })
        );
        break;
      case updateDealerDetails.fulfilled.type:
        dispatch(
          setLoading({ id: updateDealerDetails.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_DEALER_DETAILS"),
          })
        );
        break;
      case updateDealerDetails.rejected.type:
        dispatch(
          setLoading({ id: updateDealerDetails.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_DEALER_DETAILS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getDealerDropdown.pending.type:
        dispatch(setLoading({ id: getDealerDropdown.typePrefix, state: true }));
        break;
      case getDealerDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerDropdown.typePrefix, state: false })
        );
        break;
      case getDealerDropdown.rejected.type:
        dispatch(
          setLoading({ id: getDealerDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_DROPDOWN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getBranchDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getBranchDropdown.pending.type:
        dispatch(setLoading({ id: getBranchDropdown.typePrefix, state: true }));
        break;
      case getBranchDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getBranchDropdown.typePrefix, state: false })
        );
        break;
      case getBranchDropdown.rejected.type:
        dispatch(
          setLoading({ id: getBranchDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BRANCH_DROPDOWN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getBranchDropdownSpecialMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getBranchDropdownSpecial.pending.type:
        dispatch(
          setLoading({ id: getBranchDropdownSpecial.typePrefix, state: true })
        );
        break;
      case getBranchDropdownSpecial.fulfilled.type:
        dispatch(
          setLoading({ id: getBranchDropdownSpecial.typePrefix, state: false })
        );
        break;
      case getBranchDropdownSpecial.rejected.type:
        dispatch(
          setLoading({ id: getBranchDropdownSpecial.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BRANCH_DROPDOWN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getAdminBranchDropdownMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getAdminBranchDropdown.pending.type:
        dispatch(
          setLoading({ id: getAdminBranchDropdown.typePrefix, state: true })
        );
        break;
      case getAdminBranchDropdown.fulfilled.type:
        dispatch(
          setLoading({ id: getAdminBranchDropdown.typePrefix, state: false })
        );
        break;
      case getAdminBranchDropdown.rejected.type:
        dispatch(
          setLoading({ id: getAdminBranchDropdown.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BRANCH_DROPDOWN_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerIncentivePointMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case getDealerIncentivePoint.pending.type:
        dispatch(
          setLoading({ id: getDealerIncentivePoint.typePrefix, state: true })
        );
        break;
      case getDealerIncentivePoint.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerIncentivePoint.typePrefix, state: false })
        );
        break;
      case getDealerIncentivePoint.rejected.type:
        dispatch(
          setLoading({ id: getDealerIncentivePoint.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_INCENTIVE_POINT"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const updateDealerIncentivePointMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case updateDealerIncentivePoint.pending.type:
        dispatch(
          setLoading({ id: updateDealerIncentivePoint.typePrefix, state: true })
        );
        break;
      case updateDealerIncentivePoint.fulfilled.type:
        dispatch(
          setLoading({
            id: updateDealerIncentivePoint.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({ severity: "success", message: payload.data.message })
        );
        break;
      case updateDealerIncentivePoint.rejected.type:
        dispatch(
          setLoading({
            id: updateDealerIncentivePoint.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_DEALER_INCENTIVE_POINT"
            ),
          })
        );
        break;
      default:
        break;
    }
  };
const bulkUpdateDealerStatusMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;
    switch (type) {
      case bulkUpdateDealerStatus.pending.type:
        dispatch(
          setLoading({ id: bulkUpdateDealerStatus.typePrefix, state: true })
        );
        break;
      case bulkUpdateDealerStatus.fulfilled.type:
        dispatch(
          setLoading({ id: bulkUpdateDealerStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(lang, "success.SUCCESS_UPDATE_DEALER_STATUS"),
          })
        );
        break;
      case bulkUpdateDealerStatus.rejected.type:
        dispatch(
          setLoading({ id: bulkUpdateDealerStatus.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_UPDATE_DEALER_STATUS"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const resendVerificationEmailMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case resendVerificationEmail.pending.type:
        dispatch(
          setLoading({ id: resendVerificationEmail.typePrefix, state: true })
        );
        break;
      case resendVerificationEmail.fulfilled.type:
        dispatch(
          setLoading({ id: resendVerificationEmail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "success",
            message: getApiLang(
              lang,
              "success.SUCCESS_RESEND_EMAIL_VERIFICATION"
            ),
          })
        );
        break;
      case resendVerificationEmail.rejected.type:
        dispatch(
          setLoading({ id: resendVerificationEmail.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              "success.ERROR_RESEND_VERIFICATION_EMAIL"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerStockoutListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerStockoutList.pending.type:
        dispatch(
          setLoading({ id: getDealerStockoutList.typePrefix, state: true })
        );
        break;
      case getDealerStockoutList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerStockoutList.typePrefix, state: false })
        );
        break;
      case getDealerStockoutList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerStockoutList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_STOCKOUT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerRecallListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerRecallList.pending.type:
        dispatch(
          setLoading({ id: getDealerRecallList.typePrefix, state: true })
        );
        break;
      case getDealerRecallList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerRecallList.typePrefix, state: false })
        );
        break;
      case getDealerRecallList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerRecallList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_RECALL_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerReceiveListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerReceiveList.pending.type:
        dispatch(
          setLoading({ id: getDealerReceiveList.typePrefix, state: true })
        );
        break;
      case getDealerReceiveList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerReceiveList.typePrefix, state: false })
        );
        break;
      case getDealerReceiveList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerReceiveList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_RECEIVE_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerOrderListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerOrderList.pending.type:
        dispatch(
          setLoading({ id: getDealerOrderList.typePrefix, state: true })
        );
        break;
      case getDealerOrderList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerOrderList.typePrefix, state: false })
        );
        break;
      case getDealerOrderList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerOrderList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_ORDER_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerRequestListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerRequestList.pending.type:
        dispatch(
          setLoading({ id: getDealerRequestList.typePrefix, state: true })
        );
        break;
      case getDealerRequestList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerRequestList.typePrefix, state: false })
        );
        break;
      case getDealerRequestList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerRequestList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_REQUEST_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInventoryProductListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getDealerInventoryProductListing.pending.type:
        dispatch(
          setLoading({
            id: getDealerInventoryProductListing.typePrefix,
            state: true,
          })
        );
        break;
      case getDealerInventoryProductListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getDealerInventoryProductListing.typePrefix,
            state: false,
          })
        );
        break;
      case getDealerInventoryProductListing.rejected.type:
        dispatch(
          setLoading({
            id: getDealerInventoryProductListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PRODUCT_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInventoryPackageListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getDealerInventoryPackageListing.pending.type:
        dispatch(
          setLoading({
            id: getDealerInventoryPackageListing.typePrefix,
            state: true,
          })
        );
        break;
      case getDealerInventoryPackageListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getDealerInventoryPackageListing.typePrefix,
            state: false,
          })
        );
        break;
      case getDealerInventoryPackageListing.rejected.type:
        dispatch(
          setLoading({
            id: getDealerInventoryPackageListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_PACKAGE_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInventoryBoxListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getDealerInventoryBoxListing.pending.type:
        dispatch(
          setLoading({
            id: getDealerInventoryBoxListing.typePrefix,
            state: true,
          })
        );
        break;
      case getDealerInventoryBoxListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getDealerInventoryBoxListing.typePrefix,
            state: false,
          })
        );
        break;
      case getDealerInventoryBoxListing.rejected.type:
        dispatch(
          setLoading({
            id: getDealerInventoryBoxListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_LOOSE_ITEM_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInventoryLooseItemListingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;
    const lang = getState().constant.languages;

    switch (type) {
      case getDealerInventoryLooseItemListing.pending.type:
        dispatch(
          setLoading({
            id: getDealerInventoryLooseItemListing.typePrefix,
            state: true,
          })
        );
        break;
      case getDealerInventoryLooseItemListing.fulfilled.type:
        dispatch(
          setLoading({
            id: getDealerInventoryLooseItemListing.typePrefix,
            state: false,
          })
        );
        break;
      case getDealerInventoryLooseItemListing.rejected.type:
        dispatch(
          setLoading({
            id: getDealerInventoryLooseItemListing.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_BOX_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerRewardListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerRewardList.pending.type:
        dispatch(
          setLoading({ id: getDealerRewardList.typePrefix, state: true })
        );
        break;
      case getDealerRewardList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerRewardList.typePrefix, state: false })
        );
        break;
      case getDealerRewardList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerRewardList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_REWARD_LIST"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerPointHistoryListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerPointHistoryList.pending.type:
        dispatch(
          setLoading({ id: getDealerPointHistoryList.typePrefix, state: true })
        );
        break;
      case getDealerPointHistoryList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerPointHistoryList.typePrefix, state: false })
        );
        break;
      case getDealerPointHistoryList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerPointHistoryList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_INCENTIVE_POINT"
            ),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerPointExpiringListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerPointExpiringList.pending.type:
        dispatch(
          setLoading({ id: getDealerPointExpiringList.typePrefix, state: true })
        );
        break;
      case getDealerPointExpiringList.fulfilled.type:
        dispatch(
          setLoading({
            id: getDealerPointExpiringList.typePrefix,
            state: false,
          })
        );
        break;
      case getDealerPointExpiringList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({
            id: getDealerPointExpiringList.typePrefix,
            state: false,
          })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(
              lang,
              payload.code === "error.ERROR_ACCESS_DENIED"
                ? payload.code
                : "error.ERROR_GET_DEALER_INCENTIVE_POINT"
            ),
          })
        );

        break;
      default:
        break;
    }
  };

const getDealerInvitedTrendingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerInvitedTrending.pending.type:
        dispatch(
          setLoading({ id: getDealerInvitedTrending.typePrefix, state: true })
        );
        break;
      case getDealerInvitedTrending.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerInvitedTrending.typePrefix, state: false })
        );
        break;
      case getDealerInvitedTrending.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerInvitedTrending.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInvitedSummaryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerInvitedSummary.pending.type:
        dispatch(
          setLoading({ id: getDealerInvitedSummary.typePrefix, state: true })
        );
        break;
      case getDealerInvitedSummary.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerInvitedSummary.typePrefix, state: false })
        );
        break;
      case getDealerInvitedSummary.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerInvitedSummary.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getDealerInvitedListMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type, payload } = action;

    switch (type) {
      case getDealerInvitedList.pending.type:
        dispatch(
          setLoading({ id: getDealerInvitedList.typePrefix, state: true })
        );
        break;
      case getDealerInvitedList.fulfilled.type:
        dispatch(
          setLoading({ id: getDealerInvitedList.typePrefix, state: false })
        );
        break;
      case getDealerInvitedList.rejected.type:
        const lang = getState().constant.languages;
        dispatch(
          setLoading({ id: getDealerInvitedList.typePrefix, state: false })
        );
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, payload.code),
          })
        );
        break;
      default:
        break;
    }
  };

const getTotalStockoutTrendingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getTotalStockoutTrending.pending.type:
        dispatch(
          setLoading({ id: getTotalStockoutTrending.typePrefix, state: true })
        );
        break;
      case getTotalStockoutTrending.fulfilled.type:
        dispatch(
          setLoading({ id: getTotalStockoutTrending.typePrefix, state: false })
        );
        break;
      case getTotalStockoutTrending.rejected.type:
        dispatch(
          setLoading({ id: getTotalStockoutTrending.typePrefix, state: false })
        );
        dispatch(
          addAlert({ severity: "error", message: action.payload.message })
        );
        break;
      default:
        break;
    }
  };

const getTotalRecallTrendingMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getTotalRecallTrending.pending.type:
        dispatch(
          setLoading({ id: getTotalRecallTrending.typePrefix, state: true })
        );
        break;
      case getTotalRecallTrending.fulfilled.type:
        dispatch(
          setLoading({ id: getTotalRecallTrending.typePrefix, state: false })
        );
        break;
      case getTotalRecallTrending.rejected.type:
        dispatch(
          setLoading({ id: getTotalRecallTrending.typePrefix, state: false })
        );
        dispatch(
          addAlert({ severity: "error", message: action.payload.message })
        );
        break;
      default:
        break;
    }
  };

const getStockoutByProductMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    const { type } = action;

    switch (type) {
      case getStockoutByProduct.pending.type:
        dispatch(
          setLoading({ id: getStockoutByProduct.typePrefix, state: true })
        );
        break;
      case getStockoutByProduct.fulfilled.type:
        dispatch(
          setLoading({ id: getStockoutByProduct.typePrefix, state: false })
        );
        break;
      case getStockoutByProduct.rejected.type:
        dispatch(
          setLoading({ id: getStockoutByProduct.typePrefix, state: false })
        );
        dispatch(
          addAlert({ severity: "error", message: action.payload.message })
        );
        break;
      default:
        break;
    }
  };

export default [
  getAllDealerListingMiddleware,
  updateDealerStatusMiddleware,
  getDealerDetailsMiddleware,
  updateDealerDetailsStatusMiddleware,
  updateDealeUplineMiddleware,
  updateDealerDetailsMiddleware,
  getDealerDropdownMiddleware,
  getBranchDropdownMiddleware,
  getBranchDropdownSpecialMiddleware,
  getAdminBranchDropdownMiddleware,
  getDealerIncentivePointMiddleware,
  updateDealerIncentivePointMiddleware,
  bulkUpdateDealerStatusMiddleware,
  resendVerificationEmailMiddleware,
  getDealerRecallListMiddleware,
  getDealerStockoutListMiddleware,
  getDealerReceiveListMiddleware,
  getDealerOrderListMiddleware,
  getDealerRequestListMiddleware,
  getDealerInventoryProductListingMiddleware,
  getDealerInventoryPackageListingMiddleware,
  getDealerInventoryBoxListingMiddleware,
  getDealerInventoryLooseItemListingMiddleware,
  getDealerRewardListMiddleware,
  getDealerPointHistoryListMiddleware,
  getDealerPointExpiringListMiddleware,
  getDealerInvitedTrendingMiddleware,
  getDealerInvitedSummaryMiddleware,
  getDealerInvitedListMiddleware,
  getTotalStockoutTrendingMiddleware,
  getTotalRecallTrendingMiddleware,
  getStockoutByProductMiddleware,
];
