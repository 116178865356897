import { createSlice } from "@reduxjs/toolkit";
import { SIGNUPSTEPS } from "../../constants";
import { setUserProfile, submitCompanyInfo } from "../action";
import { fetchCompanyInfo, fetchLabel } from "modules/admin/redux/action";
import { fetchAllCurrencyConfig } from "modules/public/redux/action";
import fallbackLogo from "assets/img/img-default.png";
import {
  LABEL_PROFILE,
  LABEL_COMPANY,
  LABEL_SUBSCRIPTION_PAYMENT
} from "modules/admin/constants";
import { fetchProfile } from "modules/profile/redux";

const initialState = {
  isAccountCreated: false,
  email: "",
  id: "",
  customerTokenID: null,
  preselectPlan: null,
  currentRegistrationStep: 0,
  userInfo: {
    username: "",
    userType: 0,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneCountry: "Malaysia",
    contactCode: "",
    contactNumber: "",
    language: "EN",
    timezone: "Asia/Kuala_Lumpur",
    referralCode: "",
    privacyPolicySignature: "",
    endUserAgreementSignature: "",
    privacyPolicySignatureUrl: "",
    endUserAgreementSignatureUrl: "",
    newsletter: 1
  },
  companyInfo: {
    name: "",
    shortName: "",
    branchShortName: "",
    registrationNo: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
    contactCode: "",
    contactNumber: "",
    companyEmail: "",
    industry: 8,
    articleAssociation: null,
    directorIdentification: null,
    currencyCodes: [],
    companyLogo: null
  },
  selectedPlanGroup: "",
  selectedPlanGroupInterval: "",
  isStepSet: false,
  hasSubmittedCompany: false,
  isSubmit: false
};

const signupstepsSlice = createSlice({
  name: SIGNUPSTEPS,
  initialState,
  reducers: {
    goToRegistrationStep(state, action) {
      state.currentRegistrationStep = action.payload;
    },
    goPreviousRegistrationStep(state, action) {
      const currentRegistrationStep = state.currentRegistrationStep;
      if (currentRegistrationStep > 0) {
        state.currentRegistrationStep = currentRegistrationStep - 1;
      }
    },
    goNextRegistrationStep(state, action) {
      state.currentRegistrationStep = state.currentRegistrationStep + 1;
    },
    initiateWithPreselectPlan(state, action) {
      const { plan, type } = action.payload;
      state.preselectPlan = { name: plan, type: parseInt(type) };
    },
    setSelectedPlanGroup(state, action) {
      state.selectedPlanGroup = action.payload.group;
      state.selectedPlanGroupInterval = action.payload.interval;
    },
    setSelectedPlanGroupInterval(state, action) {
      state.selectedPlanGroupInterval = action.payload;
    },
    setAccountProfilePicture(state, action) {
      state.companyInfo.companyLogo = action.payload.companyLogo || fallbackLogo;
    },
    setIsSubmit(state, action) {
      state.isSubmit = action.payload;
    },
  },
  extraReducers: {
    [setUserProfile.fulfilled]: (state, action) => {
      const { userInfo } = state;
      const { arg } = action.meta;

      userInfo.firstName = arg.firstName;
      userInfo.lastName = arg.lastName;
      userInfo.contactCode = arg.number;
      userInfo.contactNumber = arg.phone;
      userInfo.language = arg.language;
      userInfo.timezone = arg.timezone;
      userInfo.endUserAgreementSignature = arg.endUserAgreementSignature;
      userInfo.endUserAgreementSignatureUrl = arg.endUserAgreementSignatureUrl;
      userInfo.privacyPolicySignature = arg.privacyPolicySignature;
      userInfo.privacyPolicySignatureUrl = arg.privacyPolicySignatureUrl;
      userInfo.newsletter = arg.newsletter;
      userInfo.phoneCountry = arg.country
    },
    [submitCompanyInfo.fulfilled]: (state, action) => {
      const { companyInfo } = state;
      const { arg } = action.meta;

      companyInfo.name = arg.name;
      companyInfo.shortName = arg.shortName
      companyInfo.registrationNo = arg.registrationNo;
      companyInfo.addressLine1 = arg.addressLine1;
      companyInfo.addressLine2 = arg.addressLine2;
      companyInfo.city = arg.city;
      companyInfo.country = arg.country;
      companyInfo.contactCode = arg.code;
      companyInfo.postalCode = arg.postalCode;
      companyInfo.state = arg.state;
      companyInfo.contactNumber = arg.contactNumber;
      companyInfo.companyEmail = arg.companyEmail;
      companyInfo.industry = arg.industry;
      companyInfo.articleAssociation = arg.articleAssociationUrl;
      companyInfo.directorIdentification = arg.directorIdentificationUrl;
      companyInfo.timezone = arg.timezone;
      companyInfo.currencyCode = arg.currencyCode;
      companyInfo.companyLogo = arg.companyLogoUrl;

      state.hasSubmittedCompany = true;
    },
    [fetchCompanyInfo.fulfilled]: (state, action) => {
      const { companyInfo } = state;
      const { payload } = action;

      if (payload != null) {
        companyInfo.name = payload.name;
        companyInfo.shortName = payload.short_name
        companyInfo.registrationNo = payload.reg_no;
        companyInfo.addressLine1 = payload.line1;
        companyInfo.addressLine2 = payload.line2;
        companyInfo.city = payload.city;
        companyInfo.country = payload.country;
        companyInfo.postalCode = payload.postal_code;
        companyInfo.state = payload.state;
        companyInfo.contactNumber = payload.contact;
        companyInfo.contactCode = payload.contact_code;
        companyInfo.companyEmail = payload.email;
        companyInfo.industry = payload.industry;
        companyInfo.timezone = payload.timezone;
        companyInfo.currencyCode = payload.currencyCode;

        try {
          companyInfo.articleAssociation = payload.media.find(
            ({ collection_name }) => collection_name === "article_assoclation"
          ).custom_properties.url;
          companyInfo.directorIdentification = payload.media.find(
            ({ collection_name }) =>
              collection_name === "director_identification"
          ).custom_properties.url;
        } catch (error) {
          console.error(error);
        }
      }
    },
    [fetchLabel.fulfilled]: (state, action) => {
      let step;
      const { payload } = action;

      if (!payload.some(label => label.key === LABEL_PROFILE)) {
        step = 0;
      } else if (!payload.some(label => label.key === LABEL_COMPANY)) {
        step = 1;
      } else if (
        !payload.some(label => label.key === LABEL_SUBSCRIPTION_PAYMENT)
      ) {
        step = 2;
      } else {
        step = 3;
      }

      state.currentRegistrationStep = step;
      state.isStepSet = true;
    },
    [fetchProfile.fulfilled]: (state, action) => {
      const { userInfo } = state;
      const { payload } = action;

      if (payload != null) {
        userInfo.firstName = payload.first_name;
        userInfo.lastName = payload.last_name;
        userInfo.phoneCountry = payload.phone_country;
        userInfo.contactNumber = payload.phone_number;
        userInfo.contactCode = payload.phone_code;
        userInfo.newsletter = payload.newsletter;
        userInfo.email = payload.email;
        userInfo.timezone = payload.timezone;
        userInfo.language = payload.locale;
      }
    },
    [fetchAllCurrencyConfig.fulfilled]: (state, action) => {
      const { payload } = action;

      const temp = []

      payload.data.forEach((item) => {
        temp.push({
          name: item.name,
          alpha3: item.alpha3
        });
      })
      state.companyInfo.currencyCodes = temp
    },
    [fetchAllCurrencyConfig.rejected]: (state) => {
      state.companyInfo.currencyCodes = []
    }
  }
});

export const {
  initiateWithPreselectPlan,
  selectPlan,
  goToRegistrationStep,
  goPreviousRegistrationStep,
  goNextRegistrationStep,
  setSelectedPlanGroup,
  setAccountProfilePicture,
  setSelectedPlanGroupInterval,
  setIsSubmit
} = signupstepsSlice.actions;

export default signupstepsSlice.reducer;
