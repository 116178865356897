import React from "react";
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop,
  Box,
} from "@material-ui/core";
import Dialog from "components/dialog/customDialog";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorSVG } from "../../../utils/static";
import SetWinnerTable from "../../table/setWinnerTable";
import { getLang } from "app/feature/constants";
import { PRIZE_TYPE_PRESET } from "modules/lucky-draw-v2/constants";
import ThankYouImage from "assets/img/thank_you.png";
import { Skeleton } from "@material-ui/lab";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import moment from "moment";
const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    width: 550,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  discardButton: {
    color: theme.palette.error.main,
  },
  publishButton: {
    color: theme.palette.common.white,
  },
  winnerContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: theme.spacing(1, 3, 2, 3),
  },
  viewPrizeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    padding: theme.spacing(0, 2),
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center",
  },
  form: {
    maxHeight: "65vh",
    overflow: "auto",
  },
});

const useStyles = makeStyles(style);

export default function WinnerDialogComponent({
  luckyDrawUuid,
  hasLuckyDrawEditAccessRight,
  handleClose,
  isOpen,
  prizeList,
  setPrizeList,
  handleSave,
  handleAddWinner,
  handleDelete,
  lang,
  excludeList,
  setExcludeList,
  isFetching,
  selectedLuckyDraw,
}) {
  const classes = useStyles();

  let isPublished = selectedLuckyDraw?.progress === progressStatus.published;

  let endAt = selectedLuckyDraw?.end_at
    ? moment.utc(selectedLuckyDraw.end_at).local()
    : null;

  let isEditable = !isPublished || !endAt || moment().isBefore(endAt);

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0} className={classes.root}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {isEditable && hasLuckyDrawEditAccessRight
              ? getLang(lang, "label.MANAGE_WINNER")
              : getLang(lang, "label.VIEW_WINNER")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <div className={classes.form}>
          <Divider />
          {isEditable && hasLuckyDrawEditAccessRight ? (
            <Box px={3} pt={2} display="flex">
              <div style={{ paddingTop: "3px" }}>
                <ErrorSVG />
              </div>
              <Typography
                variant="caption"
                color="primary"
                style={{
                  paddingLeft: "4px",
                }}
              >
                {getLang(lang, "paragraph.PRESS_ADD_WINNER_BUTTON")}
              </Typography>
            </Box>
          ) : null}
          {isFetching ? (
            <Box py={2} px={3}>
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
              <Skeleton variant="text" animation="wave" height={25} />
            </Box>
          ) : prizeList.length > 0 ? (
            prizeList.map((value, index) => (
              <React.Fragment key={index}>
                <Box className={classes.winnerContainer}>
                  <div className={classes.viewPrizeContainer}>
                    <Box className={classes.viewPrizePhoto}>
                      {value.is_default ? (
                        <img
                          src={ThankYouImage}
                          alt="example"
                          height="100%"
                          width="100%"
                          style={{ objectFit: "contain" }}
                        />
                      ) : (
                        <img
                          src={value.image_url}
                          alt="example"
                          height="100%"
                          width="100%"
                          style={{ objectFit: "contain" }}
                        />
                      )}
                    </Box>

                    <Box p={2}>
                      <Box display="flex" pt={1} pb={1}>
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {value.name}
                        </Typography>
                      </Box>

                      <Box display="flex">
                        <Typography
                          variant="body2"
                          style={{ color: " rgba(58, 77, 84, 0.33)" }}
                        >
                          {getLang(lang, "label.PRIZE_QUANTITY")}:&nbsp;
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {value.is_unlimited_qty
                            ? getLang(lang, "label.UNLIMITED")
                            : value.quantity.toLocaleString("en-US")}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <Divider />
                  <SetWinnerTable
                    luckyDrawUuid={luckyDrawUuid}
                    dialog="winner"
                    handleAddWinner={handleAddWinner}
                    handleDelete={handleDelete}
                    isUnlimited={!!Number(value.is_unlimited_qty)}
                    quantity={
                      value.winners.filter(
                        (v) => Number(v.prize_type) === PRIZE_TYPE_PRESET
                      ).length
                    }
                    winners={value.winners}
                    setPrizeList={setPrizeList}
                    prizeList={prizeList}
                    balance={value.balance}
                    prize={value}
                    index={index}
                    lang={lang}
                    excludeList={excludeList}
                    setExcludeList={setExcludeList}
                    isEditable={isEditable}
                  />
                </Box>
              </React.Fragment>
            ))
          ) : null}
        </div>
        {isEditable && hasLuckyDrawEditAccessRight ? (
          <Box mt="auto">
            <Divider />
            <Box display="flex" justifyContent="flex-end" px={2} pt={1} pb={1}>
              <Button
                variant="text"
                disableElevation
                onClick={handleClose}
                style={{ marginRight: "4px" }}
              >
                {getLang(lang, "label.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                classes={{ root: classes.publishButton }}
                disableElevation
                type="button"
                onClick={handleSave}
              >
                {getLang(lang, "label.SAVE")}
              </Button>
            </Box>
          </Box>
        ) : null}
      </Paper>
    </Dialog>
  );
}
