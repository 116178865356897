import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Paper,
  Typography,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import * as Yup from "yup";
import { DragDropContext } from "react-beautiful-dnd";
import { isFileExceedLimit, isImage } from "lib/helper";
import InstantWinPrizePanel from "../../panel/instantWinPrizePanel";
import TraditionalRafflePrizePanel from "../../panel/traditionalRafflePrizePanel";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";

export default function PrizeInfoFormComponent({
  uuid,
  initialValues,
  lang,
  handleSubmitForm,
  isSubmitting,
  formRef,
  handlePrevious,
  handleOnDragEnd,
  type,
  disbursementInfos,
  ldCurrentState,
  progress,
  nextStep,
}) {
  let initialPrizeList = initialValues
    ? JSON.parse(JSON.stringify(initialValues))
    : [];

  let prizeList =
    type === 1
      ? initialPrizeList
      : initialPrizeList?.filter((v) => !v.isDefault);

  const hasPrize =
    type === 1 ? prizeList.filter((i) => !!i.length).length : prizeList.length;

  let isPublished = progress && progress === progressStatus.published;
  let isEditable =
    !isPublished ||
    !ldCurrentState ||
    (ldCurrentState !== ldState.ended && ldCurrentState !== ldState.drawStart);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        prizes: prizeList.length
          ? prizeList
          : type === 1
          ? [[], [], [], [], [], []]
          : [
              {
                id: `item-${Date.now()}`,
                title: "",
                imgUrl: null,
                quantity: 1,
                prizeType: 1,
                disbursementCompanyId: null,
                creditAmount: 0,
                unlimitedQty: false,
                collapsed: false,
              },
            ],
        defaultPrize:
          type === 1
            ? null
            : initialPrizeList?.find((v) => !!v.isDefault) ?? null,
        enableLossState:
          type === 2 && initialPrizeList?.some((v) => !!v.isDefault),
        deletedPrizeUuids: [],
        showConfirmDialog: false,
        isEditing: false,
        goToPreview: false,
      }}
      onSubmit={handleSubmitForm}
      validationSchema={
        type === 1
          ? Yup.object().shape({
              prizes: Yup.array()
                .of(
                  Yup.lazy((value) => {
                    if (value && typeof value === "object") {
                      return Yup.object().shape(
                        Object.keys(value).reduce((acc, key) => {
                          acc[key] = Yup.object().shape({
                            title: Yup.string().required(
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_REQUIRED"
                              )
                            ),
                            quantity: Yup.number()
                              .moreThan(
                                0,
                                getLang(
                                  lang,
                                  "message.error.FORM_VALIDATE_MORETHAN",
                                  {
                                    value: 0,
                                  }
                                )
                              )
                              .required(
                                getLang(
                                  lang,
                                  "message.error.FORM_VALIDATE_REQUIRED"
                                )
                              ),
                            balance: Yup.number().min(
                              0,
                              getLang(
                                lang,
                                "message.error.FORM_VALIDATE_MIN_NUMBER",
                                {
                                  value: 0,
                                }
                              )
                            ),
                            imgUrl: Yup.lazy((img) => {
                              if (img instanceof File) {
                                return Yup.mixed()
                                  .test(
                                    "fileSize",
                                    getLang(
                                      lang,
                                      "message.error.FILE_TOO_LARGE"
                                    ),
                                    (v) => {
                                      return !isFileExceedLimit(
                                        v.size,
                                        10,
                                        "MB"
                                      );
                                    }
                                  )
                                  .test(
                                    "fileType",
                                    getLang(
                                      lang,
                                      "message.error.FILE_NOT_IMAGE"
                                    ),
                                    (v) => {
                                      return isImage(v);
                                    }
                                  );
                              } else {
                                return Yup.mixed().required(
                                  getLang(
                                    lang,
                                    "message.error.FORM_VALIDATE_REQUIRED"
                                  )
                                );
                              }
                            }),
                          });
                          return acc; // Return the accumulator for the next iteration
                        }, {})
                      );
                    }
                  })
                )
                .test(
                  "at-least-one-non-empty",
                  getLang(lang, "message.error.PRIZE_LIST_CANNOT_EMPTY"), // Custom message for validation failure
                  (value) => {
                    return value.some(
                      (childArray) => Object.keys(childArray).length > 0
                    );
                  } // Ensure at least one child array has content
                )
                .strict(),
            })
          : Yup.object().shape({
              prizes: Yup.array()
                .min(2, getLang(lang, "message.error.PRIZE_LIST_MIN_TWO"))
                .of(
                  Yup.object().shape({
                    title: Yup.string().required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
                    quantity: Yup.number()
                      .min(
                        0,
                        getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                          value: 0,
                        })
                      )
                      .required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      ),
                    prizeType: Yup.number(),
                    creditAmount: Yup.number().when("prizeType", {
                      is: (val) => val === 2,
                      then: Yup.number()
                        .moreThan(
                          0,
                          getLang(
                            lang,
                            "message.error.FORM_VALIDATE_MORETHAN",
                            {
                              value: 0,
                            }
                          )
                        )
                        .required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ),
                    }),
                    imgUrl: Yup.lazy((img) => {
                      if (img instanceof File) {
                        return Yup.mixed()
                          .test(
                            "fileSize",
                            getLang(lang, "message.error.FILE_TOO_LARGE"),
                            (v) => {
                              return !isFileExceedLimit(v.size, 10, "MB");
                            }
                          )
                          .test(
                            "fileType",
                            getLang(lang, "message.error.FILE_NOT_IMAGE"),
                            (v) => {
                              return isImage(v);
                            }
                          );
                      } else {
                        return Yup.mixed().required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        );
                      }
                    }),
                  })
                ),
              defaultPrize: Yup.object()
                .nullable()
                .when("enableLossState", {
                  is: true,
                  then: Yup.object().shape({
                    title: Yup.string().required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
                    description: Yup.string().required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
                    imgUrl: Yup.lazy((img) => {
                      if (img instanceof File) {
                        return Yup.mixed()
                          .test(
                            "fileSize",
                            getLang(lang, "message.error.FILE_TOO_LARGE"),
                            (v) => {
                              return !isFileExceedLimit(v.size, 10, "MB");
                            }
                          )
                          .test(
                            "fileType",
                            getLang(lang, "message.error.FILE_NOT_IMAGE"),
                            (v) => {
                              return isImage(v);
                            }
                          );
                      } else {
                        return Yup.mixed();
                      }
                    }),
                  }),
                }),
            })
      }
    >
      {(formik) => (
        <Box component={"form"} onSubmit={formik.handleSubmit} mt={2}>
          <Paper style={{ padding: "20px" }} elevation={0}>
            <Box
              mb={3.5}
              pr={2}
              pl={7}
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                <Typography variant="h6">
                  <b>{getLang(lang, "label.PRIZE_SETUP")}</b>
                </Typography>
                <Typography variant="body2" style={{ margin: "5px 10px 0 0" }}>
                  {getLang(lang, "label.PRIZE_SETUP_DESCRIPTION")}
                </Typography>
              </Box>
              {isEditable && (
                <Button
                  style={{
                    backgroundColor: "#ff1515",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    if (!isSubmitting) {
                      formik.setFieldValue("showConfirmDialog", true);
                    }
                  }}
                >
                  <Typography variant="body2" style={{ color: "white" }}>
                    <b>{getLang(lang, "label.CLEAR_ALL")}</b>
                  </Typography>
                </Button>
              )}
            </Box>
            <DragDropContext
              onDragEnd={({ source, destination }) =>
                handleOnDragEnd(source, destination, formik)
              }
            >
              <Box dispaly="flex" flexDirection="column">
                {type === 2 ? (
                  <InstantWinPrizePanel
                    formik={formik}
                    disbursementInfos={disbursementInfos}
                    isSubmitting={isSubmitting}
                    ldCurrentState={ldCurrentState}
                    isPublished={isPublished}
                  />
                ) : type === 1 ? (
                  <TraditionalRafflePrizePanel
                    formik={formik}
                    isSubmitting={isSubmitting}
                    ldCurrentState={ldCurrentState}
                    isPublished={isPublished}
                  />
                ) : null}
              </Box>
            </DragDropContext>
            <FormHelperText
              error={
                formik.touched.prizes &&
                formik.errors.prizes &&
                typeof formik.errors.prizes === "string"
              }
              style={{ marginTop: 28, marginLeft: 58 }}
            >
              {typeof formik.errors.prizes === "string"
                ? ErrorMessage({ name: "prizes" })
                : null}
            </FormHelperText>
          </Paper>
          <Box
            display="flex"
            alignItems="center"
            mt={2.5}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handlePrevious();
                  }}
                  disabled={isSubmitting}
                >
                  {getLang(lang, "label.PREVIOUS")}
                </Button>
              </Box>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#6AAF68" }}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting &&
                  !formik.values.goToPreview && (
                    <CircularProgress
                      style={{ width: 15, height: 15, color: "white" }}
                    />
                  )
                }
                onClick={() => {
                  formik.setFieldValue("goToPreview", false);
                  formik.handleSubmit();
                }}
              >
                {getLang(lang, "label.NEXT")}
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              {!hasPrize &&
              ![progressStatus.setProduct, progressStatus.published].includes(
                progress
              ) ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    formik.setFieldValue("goToPreview", false);
                    nextStep(false, true);
                  }}
                  disabled={isSubmitting}
                >
                  {getLang(lang, "label.SKIP")}
                </Button>
              ) : (
                ""
              )}
              {isPublished && isEditable && (
                <Box ml={1}>
                  <Button
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#6AAF68" }}
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting &&
                      !!formik.values.goToPreview && (
                        <CircularProgress
                          style={{ width: 15, height: 15, color: "white" }}
                        />
                      )
                    }
                    onClick={() => {
                      formik.setFieldValue("goToPreview", true);
                      formik.handleSubmit();
                    }}
                  >
                    {getLang(lang, "label.SAVE_AND_PREVIEW")}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <GeneralDialog
            isOpen={!!formik.values.showConfirmDialog}
            handleClose={() => formik.setFieldValue("showConfirmDialog", false)}
            handleProceed={() => {
              formik.resetForm();

              let hasDefaultPrize =
                type === 2 && initialValues.some((v) => !!v.isDefault);
              let initialPrizeList = JSON.parse(JSON.stringify(initialValues));
              let prizeList =
                type === 1
                  ? initialPrizeList
                  : initialPrizeList?.filter((v) => !v.isDefault);

              formik.setValues({
                ...formik.values,
                prizes:
                  type === 1
                    ? prizeList
                    : prizeList.length
                    ? prizeList
                    : [
                        {
                          id: `item-${Date.now()}`,
                          title: "",
                          imgUrl: null,
                          quantity: 1,
                          prizeType: 1,
                          disbursementCompanyId: null,
                          creditAmount: 0,
                          unlimitedQty: false,
                          collapsed: false,
                        },
                      ],
                defaultPrize:
                  type === 1
                    ? null
                    : initialPrizeList?.find((v) => !!v.isDefault) ?? null,
                enableLossState: hasDefaultPrize,
                deletedPrizeUuids: [],
                isEditing: false,
                showConfirmDialog: false,
              });
            }}
            title={getLang(lang, "label.CONFIRMATION")}
            description={getLang(lang, "paragraph.CLEAR_FORM_QUESTION")}
            type="danger"
            icon={<img src={AlertIcon} alt="alert" />}
          />
        </Box>
      )}
    </Formik>
  );
}
