import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addAlert, selectLoading } from 'modules/notification';
import { sendResetPasswordLink } from "modules/trackco/redux"
import ForgotPasswordPage from "./forgot-password.page";
import { getLang } from "app/feature/constants";

function ForgotPasswordContainer() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [counter, setCounter] = useState(60);
  const dispatch = useDispatch();
  const invitationCode = useSelector(state => state.trackco.profile.invitationCode)
  const lang = useSelector(state => state.constant.languages);
  const isSendingResetPasswordLink = useSelector(state => selectLoading(state, sendResetPasswordLink.typePrefix));

  useEffect(() => {
    if(counter > 0 && isEmailSent ) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer)
    }
  }, [counter, isEmailSent])

  const handleSentResetPasswordLink = (values) => {
    dispatch(sendResetPasswordLink({ email: values.email }))
      .then(() => {
        setIsEmailSent(true);
        setEmail(values.email)
      })
      .catch(() => {
        dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.FAIL_SENT_RESET_PASSWORD_LINK') }))
      })
  }

  const handleResendResentPasswordLink = () => {
    dispatch(sendResetPasswordLink({ email: email }))
      .then(() => {
        setCounter(60)
      })
      .catch(() => {
        dispatch(addAlert({ severity: "error", message: getLang(lang, 'message.error.FAIL_SENT_RESET_PASSWORD_LINK') }))
      })
  }
  
  return (
    <ForgotPasswordPage 
      handleSubmit={handleSentResetPasswordLink}
      isEmailSent={isEmailSent}
      isSendingResetPasswordLink={isSendingResetPasswordLink}
      email={email}
      counter={counter}
      enableResent={counter === 0}
      handleResendPassword={handleResendResentPasswordLink}
      invitationCode={invitationCode}
      lang={lang}
    />
  )
}

export default ForgotPasswordContainer