import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "components/tablePagination";
import DefaultImg from "assets/img/img-default.png";
import RefreshTable from "components/table/refreshTable";
import { ROOT as CONSUMER_ROOT } from "modules/consumer";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { KeyboardArrowDown } from "@material-ui/icons";
import moment from "moment";
import { getLang } from "app/feature/constants";
import DefaultProfile from "assets/img/defaultPhoto.png";
import clsx from "clsx";
import ReportWarningIcon from "assets/img/reportWarning.png";
import { hasFlag } from "country-flag-icons";
import { UnknownFlagIcon } from "modules/reporting/assets/svg";
import * as FlagIcons from "country-flag-icons/react/3x2";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: 4,
    overflow: "hidden",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "25px",
    minWidth: "25px",
    height: "25px",
    marginRight: 8,
    borderRadius: "50%",
    "& img": {
      height: "25px",
      width: "25px",
      borderRadius: "50%",
    },
  },
  chip: {
    padding: "2px 8px",
    borderRadius: 4,
    marginLeft: 5,
    color: "white",
    "& img": {
      height: 19,
      width: 18,
      objectFit: "contain",
      marginLeft: 4,
      marginTop: 2,
    },
  },
  valid: {
    backgroundColor: "#32D583",
  },
  suspend: {
    backgroundColor: "#F97066",
  },
  new: {
    padding: "2px 8px",
    borderRadius: 4,
    color: "white",
    backgroundColor: "#6AAF68",
    marginRight: 4,
  },
  flag: {
    height: 18,
    width: 24,
    marginRight: 5,
    marginTop: 3,
  },
  activeRow: {
    borderLeft: "4px solid #32D583",
  },
  suspendRow: {
    borderLeft: "4px solid #F97066",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ScanRecordTableComponent({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang,
  handleMarkerClick,
}) {
  const classes = useStyle();

  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }
  
  const getFlagIcon = (countryCode) => {
    if (!hasFlag(countryCode)) {
      return (
        <Box className={classes.flag}>
          <UnknownFlagIcon />
        </Box>
      );
    }

    const FlagIcon = FlagIcons[countryCode];
    return (
      <Box className={classes.flag}>
        <FlagIcon title={countryCode} />
      </Box>
    );
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="scan table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.column !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.PRODUCT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CONSUMER")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SCAN_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "location"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "location";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("location", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SCAN_LOCATION")}
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={5}
                      />
                    ))
                  : rows.map((row, index) => (
                      <StyledTableRow
                        key={index}
                        hover
                        tabIndex={-1}
                        style={{
                          backgroundColor:
                            row.status === "suspend" ? "#f970662b" : "white",
                        }}
                      >
                        <StyledTableCell
                          className={clsx({
                            [classes.activeRow]: row.status === "active",
                            [classes.suspendRow]: row.status !== "active",
                          })}
                        >
                          <Typography variant="caption">
                            {moment(
                              row.scan_date,
                            ).format("lll")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ maxWidth: "40vw", whiteSpace: "normal" }}
                        >
                          <Box display="flex" alignItems="center" >
                            <Box className={classes.productPhoto} style={{ flex: "0 0 30px" }}>
                              {row.product?.picture ? (
                                <img
                                  src={row.product.picture}
                                  alt={row.product.name}
                                />
                              ) : (
                                <img src={DefaultImg} alt="emptyProduct" />
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="body2"
                                style={{ fontSize: 12, wordBreak: "break-word" }}
                              >
                                {row.product?.name || "-"}
                              </Typography>
                              {!!row.product?.attributes &&
                                !!row.product.attributes.length && (
                                  <Typography
                                    className={classes.attributeText}
                                    variant="body2"
                                  >
                                    {row.product.attributes.join(", ")}
                                  </Typography>
                                )}
                              <Link
                                color="secondary"
                                component="button"
                                onClick={() => {
                                  handleMarkerClick(row.id);
                                }}
                                style={{ marginTop: 3 }}
                              >
                                <Typography variant="caption">
                                  {row.serial_number}
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ maxWidth: "40vw", whiteSpace: "normal" }}
                        >
                          <Box display="flex" alignItems="center">
                            <Box className={classes.profilePhoto}>
                              {!row.user || !row.user.picture ? (
                                <img
                                  src={DefaultProfile}
                                  alt="default-profile"
                                />
                              ) : (
                                <img
                                  src={row.user.picture}
                                  alt={row.user.name}
                                />
                              )}
                            </Box>
                            {row.user ? (
                              <Link
                                color="secondary"
                                component="button"
                                onClick={() => {
                                  window.open(
                                    `${CONSUMER_ROOT}/${row.user.uuid}`
                                  );
                                }}
                              >
                                <Typography variant="caption">
                                  {row.user.name}
                                </Typography>
                              </Link>
                            ) : (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {getLang(lang, "label.UNKNOWN")}
                              </Typography>
                            )}
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" alignItems="center">
                            <Box
                              className={clsx(classes.chip, {
                                [classes.valid]: row.status === "active",
                                [classes.suspend]: row.status !== "active",
                              })}
                            >
                              <Typography variant="caption">
                                {row.status === "active"
                                  ? getLang(lang, "label.VALID")
                                  : getLang(lang, "label.SUSPENDED")}
                              </Typography>
                              {!!row.counterfeit_report && (
                                <img src={ReportWarningIcon} alt="warning" />
                              )}
                            </Box>
                            <Typography
                              variant="caption"
                              style={{ marginLeft: 5 }}
                            >
                              {row.scan_count}/{row.scan_limit || "No Limit"}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.location_country &&
                          row.location_country !== "Unknown" ? (
                            <Box display="flex" alignItems="center">
                              {getFlagIcon(row.location_country_code)}
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography variant="caption">
                                  {row.location_country && row.location_state
                                    ? `${row.location_country}, ${row.location_state}`
                                    : row.location_country ||
                                      row.location_state}
                                </Typography>
                                <Link
                                  color="secondary"
                                  component="button"
                                  onClick={() => {
                                    window.open(
                                      `https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`
                                    );
                                  }}
                                >
                                  <Typography variant="caption">
                                    ({row.latitude}°
                                    {row.latitude < 0 ? "S" : "N"},{" "}
                                    {row.longitude}°
                                    {row.longitude < 0 ? "W" : "E"})
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          ) : (
                            <Typography
                              display="inline"
                              color="textSecondary"
                              variant="body2"
                            >
                              {getLang(lang, "label.UNKNOWN")}
                            </Typography>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Campaign Type</StyledTableCell>
                <StyledTableCell style={{ minWidth: "10rem" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell>Product(s)</StyledTableCell>
                <StyledTableCell>Serial Number</StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="caption"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              No redemption history
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
