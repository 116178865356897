import React, { useState } from "react";
import SetWinnerSelectComponent from "./setWinnerSelect.component";
import SetWinnerPanelContainer from "./setWinnerPanel.container";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { resetSerialNumberSearch } from "modules/instant-lucky-draw/redux";
import { getLang } from "app/feature/constants";
import { useSelector } from "react-redux";

function SetWinnerSelectContainer({
  currentWinnerList,
  placeholder,
  handleChange,
  value,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector((state) => state.constant.languages);
  const open = Boolean(anchorEl);

  function handleListKeyDown(value, enc) {
    handleChange(value, enc);
    setAnchorEl(null);
  }

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(resetSerialNumberSearch());
  };

  return (
    <>
      <SetWinnerSelectComponent
        handleClick={handleOpen}
        value={value}
        placeholder={getLang(lang, "placeholder.SELECT_SERIAL_NUMBER")}
      />
      <SetWinnerPanelContainer
        currentWinnerList={currentWinnerList}
        value={value}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleListKeyDown={handleListKeyDown}
      />
    </>
  );
}

SetWinnerSelectContainer.propTypes = {
  placeholder: PropTypes.string,
};

export default SetWinnerSelectContainer;
