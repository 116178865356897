import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Paper
} from "@material-ui/core";
import { UploadImg } from "../../../utils/static";
import Dropzone from "react-dropzone";
import classnames from "classnames";
import DefaultPicture from "assets/img/img-default.png";
import { getLang } from "app/feature/constants";

const style = theme => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3)
  },
  imgWrapper: {
    height: 172,
    width: 172,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  logo: {
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    flexShrink: 0
  },
  label: {
    textTransform: "none",
    display: "flex",
    flexFlow: "column"
  },
  input: {
    display: "none"
  },
  mr1: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#415D69"
    },
    color: "#ffffff",
    display: "grid",
    textAlign: "-webkit-center"
  },
  text: {
    color: theme.palette.secondary.main
  },
  dragging: {
    border: "dashed 2px",
    backgroundColor: "#415D69"
  },
  dropzoneContainer: {
    backgroundColor: "#4D626A",
    padding: theme.spacing(2)
  }
});

const useStyles = makeStyles(style);

export default function CompanyLogoPanelComponent({
  hasCompanyEditAccessRight,
  logo,
  inputImgRef,
  handleImgChange,
  handleDrop,
  dragging,
  handleDragEnter,
  handleDragLeave,
  lang
}) {
  const classes = useStyles();

  return (
    <Box>
      <Paper elevation={0} className={classes.paper}>
        <Box style={{ display: "flex" }}>
          <Typography variant="body1" style={{ color: "#ffffff" }}>
            {getLang(lang, "label.COMPANY_LOGO")}
          </Typography>
        </Box>

        <Box style={{ display: "flex", placeContent: "center" }}>
          <Box mt={2} mb={2} className={classes.imgWrapper}>
            <img
              src={logo || DefaultPicture}
              alt="logo"
              className={classes.logo}
            />
          </Box>
        </Box>
        {
          hasCompanyEditAccessRight
            ?
            <Box>
              <Box mb={1}>
                <Typography
                  variant="body1"
                  style={{ color: "#ffffff", textAlign: "center" }}
                >
                  {getLang(lang, "label.UPLOAD_COMPANY_LOGO")}
                </Typography>
              </Box>

              <Box className={classes.dropzoneContainer}>
                <Dropzone
                  onDrop={handleDrop}
                  noClick
                  noKeyboard
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  {({ getRootProps }) => (
                    <div {...getRootProps()}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        ref={inputImgRef}
                        onChange={handleImgChange}
                        onClick={(e) => {
                          e.target.value = null
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          size="small"
                          component="span"
                          className={classnames(classes.mr1, {
                            [classes.dragging]: dragging
                          })}
                          classes={{ label: classes.label }}
                          disableElevation
                        >
                          <UploadImg />
                          {dragging ? (
                            <Box mt={1}>{getLang(lang, "label.DROP_HERE")}</Box>
                          ) : (
                            <Box
                              mt={1}
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center"
                              }}
                            >
                              {getLang(lang, "paragraph.DROP_FILES")}
                              <span className={classes.text}>&nbsp;{getLang(lang, "paragraph.BROWSE")}</span>
                            </Box>
                          )}
                        </Button>
                      </label>
                    </div>
                  )}
                </Dropzone>
              </Box>

              <Box
                mt={2}
                style={{
                  display: "flex",
                  placeContent: "center",
                  textAlign: "center"
                }}
              >
                <Typography variant="caption" style={{ color: "#ffffff" }}>
                  {getLang(lang, "paragraph.RECOMMENDED_FILE_SIZE_LOGO_6")}
                </Typography>
              </Box>
            </Box>
            : null
        }
      </Paper>
    </Box>
  );
}
