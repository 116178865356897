import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  // MenuItem,
  Radio,
  // Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { AddImage, ErrorSVG } from "modules/lucky-draw/utils/static";
import { ErrorMessage } from "formik";
import { getLang } from "app/feature/constants";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Add as AddIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DragIndicator as DragIndicatorIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  ExpandLessRounded as ExpandLessRoundedIcon,
} from "@material-ui/icons";
import PrizeTypeSelect from "../../select/prizeTypeSelect";
import { currencies } from "lib/constants/currency";
import ThankYouImage from "assets/img/thank_you.png";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import PrizeImgInput from "../../prize-img-input";

const useStyles = makeStyles((theme) => ({
  required: {
    "&::after": {
      content: "' *'",
      color: "#E21B1B",
    },
  },
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  input: {
    "&::placeholder": {
      color: "#3A4D5480",
    },
  },
  prizeImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
  },
  smallPrizeImage: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
  },
  dividerContainer: {
    backgroundColor: "#fff",
    display: "flex",
    padding: "0px 20px",
  },
}));

export default function InstantWinPrizePanelComponent({
  formik,
  lang,
  disbursementInfos,
  isSubmitting,
  ldCurrentState,
  isPublished,
}) {
  const classes = useStyles();

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
    size: "small",
    margin: "dense",
  };

  let isEnded =
    isPublished && ldCurrentState && ldCurrentState === ldState.ended;
  let isStarted =
    isPublished &&
    ldCurrentState &&
    [ldState.joinStart, ldState.joinEnd, ldState.ended].includes(
      ldCurrentState
    );

  return (
    <Box>
      <Droppable droppableId="prizeList">
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ margin: "0 8px", minHeight: 10 }}
          >
            {formik.values.prizes.map((item, index) => {
              let disbursement = null;
              if (item.prizeType === 2 && item.disbursementCompanyId) {
                disbursement = disbursementInfos.find(
                  (d) => d.id === item.disbursementCompanyId
                );
              }
              return (
                <Draggable
                  key={item.id?.toString()}
                  draggableId={item.id?.toString()}
                  index={index}
                  isDragDisabled={isEnded || isSubmitting}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        display: "flex",
                        alignItems: "start",
                        userSelect: "none",
                        padding: "16px 0",
                        minHeight: "50px",
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Box p={1.5}>
                        <DragIndicatorIcon style={{ color: "#3A4D54" }} />
                      </Box>
                      <Box
                        p={2.5}
                        borderRadius={4}
                        flex={1}
                        border="1px solid #DBDBDB"
                      >
                        <Box
                          display="flex"
                          alignItems={item.collapsed ? "center" : "flex-start"}
                          style={{
                            gap: "20px",
                          }}
                        >
                          {!Boolean(item.collapsed) ? (
                            <Box>
                              {Boolean(item.imgUrl) ? (
                                <img
                                  src={
                                    typeof item.imgUrl === "string"
                                      ? item.imgUrl
                                      : URL.createObjectURL(item.imgUrl)
                                  }
                                  alt="prize"
                                  className={classes.prizeImage}
                                  onClick={() => {
                                    const input = document.getElementById(
                                      `input_${item.id}`
                                    );
                                    input.click();
                                  }}
                                />
                              ) : (
                                <Box
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#F7F7F7",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const input = document.getElementById(
                                      `input_${item.id}`
                                    );
                                    input.click();
                                  }}
                                >
                                  <AddImage />
                                </Box>
                              )}
                              <PrizeImgInput
                                lang={lang}
                                handleOnResult={(file) => {
                                  const newPrizes = Array.from(
                                    formik.values.prizes
                                  );
                                  newPrizes[index].imgUrl = file;
                                  formik.setFieldValue("prizes", newPrizes);
                                  if (!formik.values.isEditing) {
                                    formik.setFieldValue("isEditing", true);
                                  }
                                }}
                              >
                                {(handleImgChange) => (
                                  <input
                                    id={`input_${item.id}`}
                                    type="file"
                                    name="imgUrl"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleImgChange}
                                    disabled={isEnded || isSubmitting}
                                  />
                                )}
                              </PrizeImgInput>
                              <FormHelperText
                                error={
                                  formik.touched.prizes?.[index]?.imgUrl &&
                                  formik.errors.prizes?.[index]?.imgUrl
                                }
                              >
                                {ErrorMessage({
                                  name: `prizes[${index}].imgUrl`,
                                })}
                              </FormHelperText>
                            </Box>
                          ) : (
                            <Box>
                              {Boolean(item.imgUrl) ? (
                                <img
                                  src={
                                    typeof item.imgUrl === "string"
                                      ? item.imgUrl
                                      : URL.createObjectURL(item.imgUrl)
                                  }
                                  alt="prize"
                                  className={classes.smallPrizeImage}
                                />
                              ) : (
                                <Box
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: "#F7F7F7",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <AddImage />
                                </Box>
                              )}
                            </Box>
                          )}
                          {!Boolean(item.collapsed) ? (
                            <Box style={{ flex: 1 }}>
                              <Box>
                                <Typography
                                  variant="body2"
                                  className={classes.fieldLabel}
                                >
                                  {getLang(lang, "label.PRIZE_NAME")}
                                </Typography>
                                <TextField
                                  {...textFieldProps}
                                  {...formik.getFieldProps(
                                    `prizes[${index}].title`
                                  )}
                                  variant="outlined"
                                  name="title"
                                  value={item.title}
                                  onChange={(e) => {
                                    const newPrizes = Array.from(
                                      formik.values.prizes
                                    );
                                    newPrizes[index].title = e.target.value;
                                    formik.setFieldValue("prizes", newPrizes);
                                    if (!formik.values.isEditing) {
                                      formik.setFieldValue("isEditing", true);
                                    }
                                  }}
                                  inputProps={{
                                    style: { color: "black" },
                                  }}
                                  disabled={isEnded || isSubmitting}
                                  error={
                                    formik.touched.prizes?.[index]?.title &&
                                    formik.errors.prizes?.[index]?.title
                                  }
                                  helperText={ErrorMessage({
                                    name: `prizes[${index}].title`,
                                  })}
                                />
                              </Box>
                              <Box mt={1}>
                                <Typography
                                  variant="body2"
                                  className={classes.fieldLabel}
                                  style={{ marginBottom: 8 }}
                                >
                                  {getLang(lang, "label.PRIZE_TYPE")}
                                </Typography>
                                <PrizeTypeSelect
                                  handleChange={(prizeType) => {
                                    let prizeItem = { ...item };

                                    if (prizeType.type === "item") {
                                      prizeItem.prizeType = 1;
                                    }
                                    if (prizeType.type === "redemption") {
                                      prizeItem.prizeType = 3;
                                    }
                                    if (prizeType.type === "credit") {
                                      prizeItem.prizeType = 2;
                                      prizeItem.disbursementCompanyId =
                                        prizeType.disbursement_id;
                                      prizeItem.provider = prizeType;

                                      if (item.unlimitedQty) {
                                        prizeItem.unlimitedQty = false;
                                        if (isStarted) {
                                          prizeItem.balance = Number(
                                            prizeItem.balance
                                          );
                                        } else {
                                          prizeItem.quantity =
                                            prizeItem.quantity
                                              ? Number(prizeItem.quantity) || 1
                                              : 1;
                                        }
                                      }
                                    }

                                    formik.setFieldValue(
                                      `prizes[${index}]`,
                                      prizeItem
                                    );

                                    if (!formik.values.isEditing) {
                                      formik.setFieldValue("isEditing", true);
                                    }
                                  }}
                                  value={item}
                                  disabled={isSubmitting || isStarted}
                                />
                              </Box>
                              <Box mt={2}>
                                <Box display="flex">
                                  <Box flex={1}>
                                    <Typography
                                      variant="body2"
                                      className={classes.fieldLabel}
                                    >
                                      {getLang(
                                        lang,
                                        isStarted
                                          ? "label.AVAILABLE_QUANTITY"
                                          : "label.QUANTITY"
                                      )}
                                    </Typography>
                                    <TextField
                                      {...textFieldProps}
                                      {...formik.getFieldProps(
                                        isStarted
                                          ? `prizes[${index}].balance`
                                          : `prizes[${index}].quantity`
                                      )}
                                      fullWidth={false}
                                      type="number"
                                      name="prizeQuantity"
                                      disabled={
                                        isSubmitting || item.unlimitedQty
                                      }
                                      value={
                                        item.unlimitedQty
                                          ? ""
                                          : isStarted
                                          ? item.balance
                                          : item.quantity
                                      }
                                      onChange={(e) => {
                                        const newPrizes = Array.from(
                                          formik.values.prizes
                                        );
                                        if (isStarted) {
                                          newPrizes[index].balance =
                                            e.target.value === ""
                                              ? ""
                                              : Number(e.target.value);
                                        } else {
                                          newPrizes[index].quantity =
                                            e.target.value === ""
                                              ? ""
                                              : Number(e.target.value);
                                        }

                                        formik.setFieldValue(
                                          "prizes",
                                          newPrizes
                                        );
                                        if (!formik.values.isEditing) {
                                          formik.setFieldValue(
                                            "isEditing",
                                            true
                                          );
                                        }
                                      }}
                                      style={{ width: "100%" }}
                                      inputProps={{
                                        style: {
                                          backgroundColor: item.unlimitedQty
                                            ? "#F4F4F4"
                                            : "white",
                                          borderRadius: 4,
                                          color: "black",
                                        },
                                      }}
                                      error={
                                        isStarted
                                          ? formik.touched.prizes?.[index]
                                              ?.balance &&
                                            formik.errors.prizes?.[index]
                                              ?.balance
                                          : formik.touched.prizes?.[index]
                                              ?.quantity &&
                                            formik.errors.prizes?.[index]
                                              ?.quantity
                                      }
                                      helperText={ErrorMessage({
                                        name: isStarted
                                          ? `prizes[${index}].balance`
                                          : `prizes[${index}].quantity`,
                                      })}
                                    />
                                  </Box>
                                  <Box display="flex" flex={1}>
                                    {isStarted && (
                                      <Box className={classes.dividerContainer}>
                                        <Divider orientation="vertical" />
                                      </Box>
                                    )}
                                    <Box flex={1}>
                                      {isStarted && (
                                        <>
                                          <Typography
                                            variant="body2"
                                            className={classes.fieldLabel}
                                          >
                                            {getLang(
                                              lang,
                                              "label.WON_QUANTITY"
                                            )}
                                          </Typography>
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            height={54}
                                          >
                                            <Typography variant="body1">
                                              {formik.values.prizes[
                                                index
                                              ].winQuantity.toLocaleString(
                                                "en-US"
                                              )}
                                            </Typography>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                                {item.prizeType !== 2 && (
                                  <Box>
                                    <FormControlLabel
                                      disabled={isSubmitting}
                                      control={
                                        <Checkbox
                                          name="unlimitedQty"
                                          color="secondary"
                                          {...formik.getFieldProps(
                                            `prizes[${index}].unlimitedQty`
                                          )}
                                          checked={item.unlimitedQty}
                                          onClick={(e) => {
                                            formik.setFieldValue(
                                              `prizes[${index}].unlimitedQty`,
                                              e.target.checked
                                            );

                                            if (!formik.values.isEditing) {
                                              formik.setFieldValue(
                                                "isEditing",
                                                true
                                              );
                                            }
                                          }}
                                        />
                                      }
                                      label={getLang(
                                        lang,
                                        "label.UNLIMITED_QUANTITY"
                                      )}
                                    />
                                  </Box>
                                )}
                              </Box>
                              {item.prizeType === 2 && (
                                <Box mt={2}>
                                  <Typography
                                    variant="body2"
                                    className={classes.fieldLabel}
                                  >
                                    {getLang(
                                      lang,
                                      "label.INSTANT_WIN_CREDIT_AMOUNT_PER_WINNER"
                                    )}
                                  </Typography>
                                  <TextField
                                    {...textFieldProps}
                                    {...formik.getFieldProps(
                                      `prizes[${index}].creditAmount`
                                    )}
                                    fullWidth={false}
                                    type="number"
                                    name="creditAmount"
                                    value={item.creditAmount}
                                    onChange={(e) => {
                                      const newPrizes = Array.from(
                                        formik.values.prizes
                                      );
                                      newPrizes[index].creditAmount =
                                        e.target.value;
                                      formik.setFieldValue("prizes", newPrizes);
                                      if (!formik.values.isEditing) {
                                        formik.setFieldValue("isEditing", true);
                                      }
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment>
                                          {item.provider
                                            ? `${item.provider.currency} `
                                            : null}
                                        </InputAdornment>
                                      ),
                                    }}
                                    disabled={isSubmitting}
                                    inputProps={{
                                      style: {
                                        paddingLeft: 8,
                                        color: "black",
                                      },
                                    }}
                                    style={{ width: "50%" }}
                                    error={
                                      formik.touched.prizes?.[index]
                                        ?.creditAmount &&
                                      formik.errors.prizes?.[index]
                                        ?.creditAmount
                                    }
                                    helperText={ErrorMessage({
                                      name: `prizes[${index}].creditAmount`,
                                    })}
                                  />
                                  {!item.unlimitedQty && (
                                    <Box>
                                      <Typography>
                                        {getLang(
                                          lang,
                                          "label.TOTAL_CREDIT_USE"
                                        )}
                                        :{" "}
                                        <b>
                                          {item.provider
                                            ? `${item.provider.currency} `
                                            : null}
                                          {(
                                            Number(item.quantity || 0) *
                                            Number(item.creditAmount || 0)
                                          ).toFixed(
                                            item.provider?.currency
                                              ? currencies.find(
                                                  (c) =>
                                                    c.alpha3 ===
                                                    item.provider?.currency
                                                )?.exp || 2
                                              : 2
                                          )}
                                        </b>
                                      </Typography>
                                      <Typography>
                                        {getLang(
                                          lang,
                                          "label.TOTAL_TRANSACTION_USE"
                                        )}
                                        : <b>{Number(item.quantity || 0)}</b>
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Box flex={1}>
                              {[
                                item.title,
                                `${item.quantity} pc(s)`,
                                item.prizeType === 1
                                  ? getLang(lang, "label.ITEM")
                                  : item.prizeType === 3
                                  ? getLang(lang, "label.REDEMPTION_CODE")
                                  : disbursement
                                  ? disbursement.provider_name || ""
                                  : getLang(lang, "label.CREDIT_VALUE"),
                              ].join(" • ")}
                            </Box>
                          )}
                          <IconButton
                            aria-label="expand"
                            onClick={(e) => {
                              const newPrizes = Array.from(
                                formik.values.prizes
                              );
                              newPrizes[index].collapsed = !Boolean(
                                item.collapsed
                              );
                              formik.setFieldValue("prizes", newPrizes);
                            }}
                          >
                            {Boolean(item.collapsed) ? (
                              <ExpandMoreRoundedIcon />
                            ) : (
                              <ExpandLessRoundedIcon />
                            )}
                          </IconButton>
                        </Box>
                        {!Boolean(item.collapsed) && (
                          <Box mt={2}>
                            <Divider />
                            <Box pt={2} display="flex" alignItems="center">
                              <ErrorSVG />
                              <Typography
                                variant="caption"
                                style={{
                                  paddingLeft: "4px",
                                  color: "rgba(58, 77, 84, 0.33)",
                                }}
                              >
                                {getLang(
                                  lang,
                                  "paragraph.RECOMMENDED_IMAGE_SIZE_3"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {!isEnded && (
                        <IconButton
                          aria-label="delete"
                          disabled={isSubmitting || !!item.winQuantity}
                          onClick={() => {
                            const newPrizes = Array.from(
                              formik.values.prizes
                            ).filter((p) => p.id !== item.id);
                            const deletedUuids = Array.from(
                              formik.values.deletedPrizeUuids
                            );

                            if (Boolean(item.uuid)) {
                              deletedUuids.push(item.uuid);
                            }

                            formik.setFieldValue(
                              "deletedPrizeUuids",
                              deletedUuids
                            );

                            formik.setFieldValue("prizes", newPrizes);

                            if (!formik.values.isEditing) {
                              formik.setFieldValue("isEditing", true);
                            }
                          }}
                        >
                          <DeleteOutlinedIcon style={{ color: "#ff1515" }} />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </Box>
        )}
      </Droppable>
      {formik.values.prizes.length < 8 && !isEnded && (
        <Box ml={7} mb={2.5}>
          <Button
            variant="text"
            startIcon={<AddIcon color="secondary" />}
            onClick={() => {
              const newPrizes = [...formik.values.prizes];
              newPrizes.push({
                id: `item-${Date.now()}`,
                title: "",
                imgUrl: null,
                quantity: 1,
                balance: 1,
                winQuantity: 0,
                prizeType: 1,
                disbursementCompanyId: null,
                creditAmount: 0,
                unlimitedQty: false,
                collapsed: false,
                isDefault: 0,
              });
              formik.setFieldValue("prizes", newPrizes);
              if (!formik.values.isEditing) {
                formik.setFieldValue("isEditing", true);
              }
            }}
            style={{
              textTransform: "none",
            }}
            disabled={isEnded || isSubmitting}
          >
            <Typography color="secondary" variant="body2">
              <b>{getLang(lang, "label.NEW_PRIZE")}</b>
            </Typography>
          </Button>
        </Box>
      )}

      <Box mx={7} my={3}>
        <Typography variant="body2" className={classes.fieldLabel}>
          {getLang(lang, "label.DISPLAY_LOSS_SLOT_INSTANT_WIN")}
        </Typography>
        <FormControl>
          <Box style={{ display: "flex", gap: "10px", marginTop: 8 }}>
            <Button
              variant="outlined"
              style={{
                borderColor: !formik.values.enableLossState
                  ? "#6AAF68"
                  : "#A0A0A080",
                borderWidth: 2,
                color: !formik.values.enableLossState ? "black" : "#A0A0A0",
              }}
              className={classes.radioButton}
              startIcon={
                <Radio size="small" checked={!formik.values.enableLossState} />
              }
              onClick={() => {
                formik.setFieldValue("enableLossState", false);

                if (formik.values.defaultPrize?.uuid) {
                  const deletedUuids = Array.from(
                    formik.values.deletedPrizeUuids
                  );
                  deletedUuids.push(formik.values.defaultPrize?.uuid);
                  formik.setFieldValue("deletedPrizeUuids", deletedUuids);
                }

                if (!formik.values.isEditing) {
                  formik.setFieldValue("isEditing", true);
                }
              }}
              disabled={isEnded || isSubmitting}
            >
              {getLang(lang, "label.NO")}
            </Button>
            <Button
              variant="outlined"
              style={{
                borderColor: !!formik.values.enableLossState
                  ? "#6AAF68"
                  : "#A0A0A080",
                borderWidth: 2,
                color: !!formik.values.enableLossState ? "black" : "#A0A0A0",
              }}
              className={classes.radioButton}
              startIcon={
                <Radio size="small" checked={!!formik.values.enableLossState} />
              }
              onClick={() => {
                formik.setFieldValue("enableLossState", true);
                if (!formik.values.defaultPrize) {
                  formik.setFieldValue("defaultPrize", {
                    id: `item-${Date.now()}-default`,
                    title: "Thank you!",
                    description:
                      "Thank you for participating! The wheel has spun, but luck wasn't on your side this time. Better luck next time!",
                    imgUrl: null,
                    quantity: 1,
                    isDefault: 1,
                    prizeType: 1,
                    disbursementCompanyId: null,
                    creditAmount: 0,
                    unlimitedQty: false,
                  });
                }
                if (!formik.values.isEditing) {
                  formik.setFieldValue("isEditing", true);
                }
              }}
              disabled={isEnded || isSubmitting}
            >
              {getLang(lang, "label.YES")}
            </Button>
          </Box>
        </FormControl>
        {formik.values.enableLossState && (
          <Box mt={2}>
            <Box borderRadius={4} p={2.5} border="1px solid #DBDBDB">
              <Box
                display="flex"
                style={{
                  gap: "20px",
                }}
              >
                <Box>
                  {Boolean(formik.values.defaultPrize?.imgUrl) ? (
                    <img
                      src={
                        typeof formik.values.defaultPrize.imgUrl === "string"
                          ? formik.values.defaultPrize.imgUrl
                          : URL.createObjectURL(
                              formik.values.defaultPrize.imgUrl
                            )
                      }
                      alt="prize"
                      className={classes.prizeImage}
                      onClick={() => {
                        const input = document.getElementById(`input_default`);
                        input.click();
                      }}
                    />
                  ) : (
                    <Box
                      style={{
                        width: "150px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#F7F7F7",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const input = document.getElementById(`input_default`);
                        input.click();
                      }}
                    >
                      <img
                        src={ThankYouImage}
                        alt="prize"
                        className={classes.prizeImage}
                      />
                    </Box>
                  )}
                  <PrizeImgInput
                    lang={lang}
                    handleOnResult={(file) => {
                      formik.setFieldValue("defaultPrize.imgUrl", file);
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                  >
                    {(handleImgChange) => (
                      <input
                        id={`input_default`}
                        type="file"
                        name="defaultPrize.imgUrl"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImgChange}
                        disabled={isEnded || isSubmitting}
                      />
                    )}
                  </PrizeImgInput>
                  <FormHelperText
                    error={
                      formik.touched.defaultPrize?.imgUrl &&
                      formik.errors.defaultPrize?.imgUrl
                    }
                  >
                    {ErrorMessage({
                      name: `defaultPrize.imgUrl`,
                    })}
                  </FormHelperText>
                </Box>
                <Box style={{ flex: 1 }}>
                  <Box>
                    <Typography variant="body2" className={classes.fieldLabel}>
                      {getLang(lang, "label.LOSS_SLOT_TITLE")}
                    </Typography>
                    <TextField
                      {...textFieldProps}
                      {...formik.getFieldProps(`defaultPrize.title`)}
                      variant="outlined"
                      name="title"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "defaultPrize.title",
                          e.target.value
                        );

                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      error={
                        formik.touched.defaultPrize?.title &&
                        formik.errors.defaultPrize?.title
                      }
                      helperText={ErrorMessage({
                        name: `defaultPrize.title`,
                      })}
                      disabled={isEnded || isSubmitting}
                      inputProps={{
                        style: { color: "black" },
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <Typography variant="body2" className={classes.fieldLabel}>
                      {getLang(lang, "label.LOSS_SLOT_MESSAGE")}
                    </Typography>
                    <TextField
                      {...textFieldProps}
                      {...formik.getFieldProps(`defaultPrize.description`)}
                      variant="outlined"
                      name="title"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "defaultPrize.description",
                          e.target.value
                        );

                        if (!formik.values.isEditing) {
                          formik.setFieldValue("isEditing", true);
                        }
                      }}
                      error={
                        formik.touched.defaultPrize?.description &&
                        formik.errors.defaultPrize?.description
                      }
                      helperText={ErrorMessage({
                        name: `defaultPrize.description`,
                      })}
                      disabled={isEnded || isSubmitting}
                      inputProps={{
                        style: { color: "black" },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mt={2}>
                <Divider />
                <Box pt={2} display="flex" alignItems="center">
                  <ErrorSVG />
                  <Typography
                    variant="caption"
                    style={{
                      paddingLeft: "4px",
                      color: "rgba(58, 77, 84, 0.33)",
                    }}
                  >
                    {getLang(lang, "paragraph.RECOMMENDED_IMAGE_SIZE_1")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
