import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import * as Yup from "yup";
import ThankYouImage from "assets/img/thank_you.png";
import { ldState } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    color: "#3A4D54",
    fontWeight: 600,
  },
  table: {
    "& .MuiTableRow-root:hover": {
      backgroundColor: "white",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 12px",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#F8F8F8",
    },
  },
  smallPrizeImage: {
    width: "80px",
    height: "70px",
    objectFit: "contain",
    borderRadius: "4px",
  },
  prizeImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    borderRadius: "4px",
    display: "block",
    cursor: "pointer",
  },
}));

export default function WinRateInfoFormComponent({
  uuid,
  initialValues,
  lang,
  handleSubmitForm,
  isSubmitting,
  formRef,
  handlePrevious,
  handleOnDragEnd,
  type,
  disbursementInfos,
  ldCurrentState,
  progress,
}) {
  const classes = useStyles();

  let prizeList = initialValues?.prizes ?? [];

  let isPublished = progress && progress === progressStatus.published;
  let isEditable =
    !isPublished || !ldCurrentState || ldCurrentState !== ldState.ended;

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        prizes: initialValues?.prizes ?? [],
        enableLossState: initialValues?.prizes?.some((v) => !!v.isDefault),
        hasWinRate: Number(initialValues?.hasWinRate || 0),
        showConfirmDialog: false,
        isEditing: false,
        remainingWinRate: prizeList.length
          ? prizeList.reduce((total, prize) => {
              if (!prize.isDefault && prize.winRate) {
                total -= Number(prize.winRate);
              }
              return total;
            }, 100)
          : 100,
        totalWinRate: prizeList.length
          ? prizeList.reduce((total, prize) => {
              if (prize.winRate && !Number(prize.isDefault)) {
                total += Number(prize.winRate);
              }
              return total;
            }, 0)
          : 0,
        goToPreview: false,
      }}
      onSubmit={handleSubmitForm}
      validationSchema={Yup.object().shape({
        prizes: Yup.array(
          Yup.object().shape({
            winRate: Yup.number()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .max(100, getLang(lang, "message.error.WIN_RATE_MAX_ERROR")),
          })
        ),
        totalWinRate: Yup.number().when("hasWinRate", {
          is: (hasWinRate) => !!hasWinRate,
          then: Yup.number().when("enableLossState", {
            is: true,
            then: Yup.number().max(
              100,
              getLang(lang, "message.error.TOTAL_WIN_RATE_ERROR")
            ),
            otherwise: Yup.number().oneOf(
              [100],
              getLang(lang, "message.error.TOTAL_WIN_RATE_ERROR")
            ),
          }),
        }),
      })}
    >
      {(formik) => (
        <Box component={"form"} onSubmit={formik.handleSubmit} mt={2}>
          <Paper style={{ padding: "20px" }} elevation={0}>
            <Box
              mb={3.5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                <b>{getLang(lang, "label.WIN_RATE_DETAILS")}</b>
              </Typography>
              {isEditable && (
                <Typography
                  variant="body2"
                  style={{ color: "#E21B1B", cursor: "pointer" }}
                  onClick={() => {
                    formik.setFieldValue("showConfirmDialog", true);
                  }}
                >
                  <b>{getLang(lang, "label.CLEAR_ALL")}</b>
                </Typography>
              )}
            </Box>
            <Box mt={3}>
              <Typography variant="body2" className={classes.fieldLabel}>
                {getLang(lang, "label.HOW_TO_DETERMINE_WINNER")}
              </Typography>
              <FormControl>
                <Box style={{ display: "flex", gap: "10px", marginTop: 8 }}>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !formik.values.hasWinRate
                        ? "#6AAF68"
                        : "#A0A0A080",
                      color: !formik.values.hasWinRate ? "black" : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio size="small" checked={!formik.values.hasWinRate} />
                    }
                    onClick={() => {
                      formik.setFieldValue("hasWinRate", 0);
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={!isEditable}
                  >
                    {getLang(lang, "label.RANDOMIZE")}
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: !!formik.values.hasWinRate
                        ? "#6AAF68"
                        : "#A0A0A080",
                      borderWidth: 2,
                      color: !!formik.values.hasWinRate ? "black" : "#A0A0A0",
                    }}
                    className={classes.radioButton}
                    startIcon={
                      <Radio
                        size="small"
                        checked={!!formik.values.hasWinRate}
                      />
                    }
                    onClick={() => {
                      formik.setFieldValue("hasWinRate", 1);
                      if (!formik.values.isEditing) {
                        formik.setFieldValue("isEditing", true);
                      }
                    }}
                    disabled={!isEditable}
                  >
                    {getLang(lang, "label.CUSTOM_WIN_RATE")}
                  </Button>
                </Box>
              </FormControl>
            </Box>
            {!!formik.values.hasWinRate && (
              <Box mt={2}>
                <Box mb={1}>
                  <Typography variant="body2" style={{ color: "#66747A" }}>
                    <b>{getLang(lang, "label.NOTE")}:</b>{" "}
                    {getLang(lang, "message.info.INSTANT_WIN_WIN_RATE_NOTE")}
                  </Typography>
                </Box>
                <TableContainer>
                  <Table
                    aria-label="sn table"
                    size="small"
                    className={classes.table}
                  >
                    <TableHead style={{ height: "45px", background: "white" }}>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" align="center">
                            <b>{getLang(lang, "label.NUM")}</b>
                          </Typography>
                        </TableCell>
                        <TableCell width="30%">
                          <Typography variant="body2">
                            <b>{getLang(lang, "label.PRIZE_NAME")}</b>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" align="center">
                            <b>{getLang(lang, "label.QUANTITY")}</b>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            align="center"
                            style={{ textTransform: "capitalize" }}
                          >
                            <b>{getLang(lang, "label.PRIZE_TYPE")}</b>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" align="center">
                            <b>{getLang(lang, "label.WIN_RATE")}</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.prizes.map((prize, index) => {
                        return !prize.isDefault ? (
                          <TableRow
                            key={index}
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell>
                              <Typography variant="body2" align="center">
                                {index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell width="30%">
                              <Box
                                display="flex"
                                alignItems="center"
                                style={{ gap: 12 }}
                              >
                                <img
                                  src={prize.imgUrl}
                                  alt="prize"
                                  className={classes.smallPrizeImage}
                                />
                                <Typography variant="body2">
                                  {prize.title}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" align="center">
                                {prize.unlimitedQty
                                  ? getLang(lang, "label.UNLIMITED")
                                  : prize.quantity}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" align="center">
                                {Number(prize.type) === 1
                                  ? getLang(lang, "label.ITEM")
                                  : Number(prize.type) === 3
                                  ? getLang(lang, "label.REDEMPTION_CODE")
                                  : Number(prize.type) === 2
                                  ? disbursementInfos.find(
                                      (d) =>
                                        Number(d.id) ===
                                        Number(prize.disbursementCompanyId)
                                    )?.provider_name || ""
                                  : getLang(lang, "label.ITEM")}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" justifyContent="center">
                                <TextField
                                  {...formik.getFieldProps(
                                    `prizes[${index}].winRate`
                                  )}
                                  variant="outlined"
                                  size="small"
                                  margin="dense"
                                  type="number"
                                  placeholder={getLang(
                                    lang,
                                    "placeholder.ENTER_WIN_RATE"
                                  )}
                                  name="name"
                                  value={prize.winRate}
                                  onChange={(e) => {
                                    if (e.target.value) {
                                      const regex = /^[0-9]+(\.[0-9]{1,3})?$/;
                                      const isValid = regex.test(
                                        e.target.value
                                      );
                                      if (isValid) {
                                        let val = e.target.value;
                                        let count = 0;
                                        let remaining =
                                          formik.values.prizes.reduce(
                                            (total, prize) => {
                                              if (!prize.isDefault) {
                                                if (count === index) {
                                                  total -= val;
                                                } else if (prize.winRate) {
                                                  total -= prize.winRate;
                                                }
                                              }
                                              count++;
                                              return total;
                                            },
                                            100
                                          );
                                        formik.setFieldValue(
                                          "remainingWinRate",
                                          parseFloat(remaining.toFixed(3))
                                        );
                                        formik.setFieldTouched(
                                          "remainingWinRate",
                                          true
                                        );

                                        count = 0;
                                        let totalWinRate = formik.values.prizes
                                          .length
                                          ? formik.values.prizes.reduce(
                                              (total, prize) => {
                                                if (
                                                  prize.winRate &&
                                                  !Number(prize.isDefault)
                                                ) {
                                                  if (count === index) {
                                                    total += Number(val);
                                                  } else if (prize.winRate) {
                                                    total += Number(
                                                      prize.winRate
                                                    );
                                                  }
                                                }
                                                count++;
                                                return total;
                                              },
                                              0
                                            )
                                          : 0;

                                        formik.setFieldValue(
                                          `prizes[${index}].winRate`,
                                          val
                                        );
                                        formik.setFieldValue(
                                          "totalWinRate",
                                          parseFloat(totalWinRate?.toFixed(3))
                                        );
                                        if (!formik.values.isEditing) {
                                          formik.setFieldValue(
                                            "isEditing",
                                            true
                                          );
                                        }
                                      }
                                    } else {
                                      formik.setFieldValue(
                                        `prizes[${index}].winRate`,
                                        ""
                                      );
                                      if (!formik.values.isEditing) {
                                        formik.setFieldValue("isEditing", true);
                                      }
                                    }
                                  }}
                                  error={
                                    formik.touched.prizes?.[index]?.winRate &&
                                    formik.errors.prizes?.[index]?.winRate
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({
                                    name: `prizes.[${index}].winRate`,
                                  })}
                                  disabled={isSubmitting || !isEditable}
                                  inputProps={{
                                    style: { color: "black" },
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography variant="body2">
                                          %
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        ) : null;
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <FormHelperText
                  error={
                    formik.touched.totalWinRate && formik.errors.totalWinRate
                  }
                >
                  {ErrorMessage({ name: "totalWinRate" })}
                </FormHelperText>
              </Box>
            )}
          </Paper>
          {!!formik.values.hasWinRate && !!formik.values.enableLossState && (
            <Paper style={{ padding: "20px", marginTop: 16 }} elevation={0}>
              <Box
                mb={3.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">
                  <b>{getLang(lang, "label.LOSS_STATE_DETAILS")}</b>
                </Typography>
              </Box>
              <Box mt={2}>
                <Box mb={1}>
                  <Typography variant="body2" style={{ color: "#66747A" }}>
                    <b>{getLang(lang, "label.NOTE")}:</b>{" "}
                    {getLang(lang, "message.info.INSTANT_WIN_LOSS_RATE_NOTE")}
                  </Typography>
                </Box>
                {formik.values.prizes.map((prize, index) => {
                  return prize.isDefault ? (
                    <Box mt={2} key={index}>
                      <Box borderRadius={4} p={2.5} border="1px solid #DBDBDB">
                        <Box
                          display="flex"
                          style={{
                            gap: "20px",
                          }}
                        >
                          <Box>
                            <img
                              src={prize.imgUrl || ThankYouImage}
                              alt="prize"
                              className={classes.prizeImage}
                            />
                          </Box>
                          <Box style={{ flex: 1 }}>
                            <Box>
                              <Typography variant="body2">
                                <b>{getLang(lang, "label.LOSS_SLOT_TITLE")}</b>
                              </Typography>
                              <Typography>{prize.title}</Typography>
                            </Box>
                            <Box mt={3}>
                              <Typography variant="body2">
                                <b>
                                  {getLang(lang, "label.LOSS_SLOT_MESSAGE")}
                                </b>
                              </Typography>
                              <Typography>{prize.description}</Typography>
                            </Box>
                            <Box mt={3}>
                              <Typography variant="body2">
                                <b>{getLang(lang, "label.LOSS_RATE")}</b>
                              </Typography>
                              <TextField
                                variant="outlined"
                                size="small"
                                margin="dense"
                                type="number"
                                value={
                                  formik.values.remainingWinRate?.toFixed(3) ||
                                  0
                                }
                                disabled={true}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Typography variant="body2">%</Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  style: { color: "black" },
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : null;
                })}
              </Box>
            </Paper>
          )}
          <Box
            display="flex"
            alignItems="center"
            mt={2.5}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handlePrevious();
                  }}
                  disabled={isSubmitting}
                >
                  {getLang(lang, "label.PREVIOUS")}
                </Button>
              </Box>
              <Button
                variant="contained"
                style={{ color: "white", backgroundColor: "#6AAF68" }}
                disabled={isSubmitting}
                startIcon={
                  isSubmitting && (
                    <CircularProgress
                      style={{ width: 15, height: 15, color: "white" }}
                    />
                  )
                }
                onClick={() => {
                  formik.setFieldValue("goToPreview", false);
                  formik.handleSubmit();
                }}
              >
                {getLang(lang, "label.NEXT")}
              </Button>
            </Box>
            {isPublished && isEditable && (
              <Box>
                <Button
                  variant="contained"
                  style={{ color: "white", backgroundColor: "#6AAF68" }}
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting &&
                    !!formik.values.goToPreview && (
                      <CircularProgress
                        style={{ width: 15, height: 15, color: "white" }}
                      />
                    )
                  }
                  onClick={() => {
                    formik.setFieldValue("goToPreview", true);
                    formik.handleSubmit();
                  }}
                >
                  {getLang(lang, "label.SAVE_AND_PREVIEW")}
                </Button>
              </Box>
            )}
          </Box>
          <GeneralDialog
            isOpen={!!formik.values.showConfirmDialog}
            handleClose={() => formik.setFieldValue("showConfirmDialog", false)}
            handleProceed={() => {
              formik.resetForm();
              if (!formik.values.isEditing) {
                formik.setFieldValue("isEditing", true);
              }
            }}
            title={getLang(lang, "label.CONFIRMATION")}
            description={getLang(lang, "paragraph.CLEAR_FORM_QUESTION")}
            type="danger"
            icon={<img src={AlertIcon} alt="alert" />}
          />
        </Box>
      )}
    </Formik>
  );
}
