import axios from "axios";

const checkFacebookEmbed = ({
  url,
  id,
  token,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/serialnumber/api/v1/product/preview-page/check-facebook-embed?id=${id}&url=${url}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default checkFacebookEmbed;
