import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    overflow: "hidden",
    minHeight: "fit-content"
  },
  restricted: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    width: '100%',
    borderRadius: '10px',
    padding: '20px',
  },
}));

export default function FacebookComponent({ lang, path, isTabExist, isEventOnly, isRestricted }) {
  const classes = useStyle();

  const height = isTabExist ? (isEventOnly ? "244" : "500") : "150";

  return (
    <div className={classes.root} style={{ height: height }}>
      {
        isRestricted ?
        <div className={classes.restricted}>
          <Typography variant="body2">
            {getLang(lang, 'message.warning.FACEBOOK_EMBED_RESTRICTED')}
          </Typography>
        </div>  :
        <iframe
          title="facebook-page-plugin-iframe"
          src={path}
          width="343"
          height={height}
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameBorder="0"
          //allowTransparency="true"
          allow="encrypted-media"
        ></iframe>
      }
    </div>
  );
}
