import React, { useState } from "react";
import SetWinnerPanelComponent from "./setWinnerPanel.component";
import { selectLoading } from "modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { searchSerialNumber } from "modules/instant-lucky-draw/redux";

function SetWinnerPanelContainer({
  value,
  anchorEl,
  open,
  isOpenList,
  handleClose,
  handleListKeyDown,
  currentWinnerList,
}) {
  const dispatch = useDispatch();

  const [hasSearched, setHasSearched] = useState(false);

  const id = useSelector(
    (state) => state.instantLuckyDraw.InstantLuckyDrawDialogInfo.info.id
  );

  // const hasMoretoLoad = useSelector(
  //   // (state) => state.instantLuckyDraw.serialNumberList.hasMoretoLoad
  // );
  // const excludeList = useSelector(
  //   // (state) => state.instantLuckyDraw.excludeWinnerList
  // );

  const serialNumberData = useSelector(
    (state) => state.instantLuckyDraw.serialNumberList.data
  );

  const prizeList = useSelector(
    (state) => state.instantLuckyDraw.prizeDialogInfo.prizeList
  );

  const isFetching = useSelector((state) =>
    selectLoading(state, searchSerialNumber.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);

  // const fetchMoreData = useCallback(() => {
  //   if (!isFetching && hasMoretoLoad) {
  //     dispatch(fetchMoreSerialNumber());
  //   }
  // }, [isFetching, hasMoretoLoad, dispatch]);

  const resetSearchState = () => {
    setHasSearched(false); // Reset hasSearched when closing
  };

  const handleSearch = (value) => {
    const searchInfo = {
      id,
      search: value,
    };
    setHasSearched(true);
    dispatch(searchSerialNumber(searchInfo));
  };

  // Extract serial numbers of all winners in the prize list
  const winnerSerialNumbers = prizeList
    .flatMap((prize) => prize.winners)
    .map((winner) => winner.serialNumber);

  // Determine the serial number status
  let serialNumberStatus = "initial";
  if (hasSearched) {
    if (serialNumberData === null) {
      serialNumberStatus = "notFound";
    } else if (winnerSerialNumbers.includes(serialNumberData.serial_number)) {
      serialNumberStatus = "duplicate";
    } else {
      serialNumberStatus = "found";
    }
  }

  // Filter out serialNumberData if it matches any winner's serialNumber
  const filteredSerialNumberData =
    serialNumberStatus === "duplicate" ? null : serialNumberData;

  return (
    <SetWinnerPanelComponent
      // excludeList={currentWinnerList.concat(excludeList)}
      value={value}
      isOpen={open}
      anchorEl={anchorEl}
      isOpenList={isOpenList}
      handleClose={() => {
        handleClose();
        resetSearchState();
      }}
      isFetching={isFetching}
      handleListKeyDown={handleListKeyDown}
      handleSearch={handleSearch}
      serialNumberData={filteredSerialNumberData}
      // fetchMoreData={fetchMoreData}
      // hasMoretoLoad={hasMoretoLoad}
      lang={lang}
      serialNumberStatus={serialNumberStatus}
    />
  );
}

export default SetWinnerPanelContainer;
