import React from "react"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Formik } from "formik"
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants"
import MyDealerListTable from "../../table/myDealerListTable";

const useStyles = makeStyles((theme) => ({
  indicator: {
    border: "0.5px solid",
    borderColor: theme.palette.primary.main,
  },
  scroll: {
    width: "min-content",
  },
  container: {
    background: "white",
    borderRadius: 12,
    marginTop: 16,
    border: "1px solid #D0D5DD",
    padding: "20px"
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "10fr 1fr 2fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  inputRootID: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  },
  filterMainContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "20px",
    paddingLeft: "20px",
    marginBottom: "12px",
  },
  filterFieldContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  paperRoot: {
    width: "217px",
  },
  emptyField: {
    "& .MuiInputBase-root": {
      color: "#A4A6A8",
    },
  },
}))

export default function DealerDownlinePanelComponent({
  hasStockflowDealerViewAccessRight,
  id,
  downlines,
  isFetching,
  isError,
  handleChangePage,
  language,
  handleChangeRowsPerPage,
  totalFiltered,
  page,
  rowsPerPage,
  handleSearch,
  clearSearch,
  handleReload,
  isSearchResult,
  paginationOptions,
  tableFilter,
  lang,
  handleViewDetail
}) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Formik
        initialValues={{
          search: tableFilter.search,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSearch(values)
          actions.setSubmitting(false)
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box mb={2} className={classes.searchContainer}>
              <Input
                hiddenLabel
                variant="filled"
                size="small"
                placeholder={getLang(lang, "placeholder.SEARCH")}
                margin="none"
                InputProps={{
                  disableUnderline: true,
                  margin: "none",
                  style: {
                    borderRadius: "4px",
                    backgroundColor: "#eceff0",
                    minWidth: "50px",
                    height: "100%"
                  },
                  classes: { input: classes.inputRootID }
                }}
                {...formik.getFieldProps("search")}
              />
              <Button
                variant='text'
                disableElevation
                color="secondary"
                size='small'
                onClick={() => {
                  clearSearch()
                }}
              >
                {getLang(lang, "label.CLEAR")}
              </Button>
              <Button
                disabled={isFetching}
                variant="contained"
                disableElevation
                color='primary'
                size='small'
                type='submit'
              >
                {getLang(lang, "label.SEARCH")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={3}>
        <MyDealerListTable
          hasStockflowDealerViewAccessRight={hasStockflowDealerViewAccessRight}
          isDealerPage={true}
          rows={downlines}
          isFetching={isFetching}
          isError={isError}
          handleReload={handleReload}
          language={language}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowPerPage={handleChangeRowsPerPage}
          isSearchResult={isSearchResult}
          totalFiltered={totalFiltered}
          paginationOptions={paginationOptions}
          tableFilter={tableFilter}
          lang={lang}
          handleViewDetail={handleViewDetail}
        />
      </Box>
    </Box>
  )
}
