import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Paper,
  TableSortLabel,
  Link,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { TablePaginationActions } from "components/tablePagination";
import DefaultImg from "assets/img/img-default.png";
import AndroidIcon from "assets/img/androidIcon.png";
import IosIcon from "assets/img/iosIcon.png";
import WindowsIcon from "assets/img/windowIcon.png";
import Safari from "assets/img/safariIcon.png";
import Chrome from "assets/img/chromeIcon.png";
import Firefox from "assets/img/firefoxIcon.png";
import InternetExplorer from "assets/img/internetExplorerIcon.png";
import MicrosoftEdge from "assets/img/microsoftEdgeIcon.png";
import Opera from "assets/img/operaIcon.png";
import Netscape from "assets/img/netscapeIcon.png";
import RefreshTable from "components/table/refreshTable";
import * as FlagIcons from "country-flag-icons/react/3x2";
import { hasFlag } from "country-flag-icons";
import { UnknownFlagIcon } from "modules/reporting/assets/svg";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRowStickyLeftRight } from "components/skeleton/skeleton.component";
import { KeyboardArrowDown } from "@material-ui/icons";
import { ROOT as SERIAL_NUMBER_ROOT } from "modules/serial-number";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "30px",
    height: "30px",
    marginRight: "10px",
    "& img": {
      height: "30px",
      width: "30px",
    },
  },
  chip: {
    padding: "4px 8px",
    borderRadius: 4,
    marginLeft: 5,
    color: "white",
  },
  valid: {
    backgroundColor: "#32D583",
  },
  suspend: {
    backgroundColor: "#F97066",
  },
  flag: {
    height: 18,
    width: 24,
    marginRight: 5,
    marginTop: 3,
  },
  deviceIcon: {
    height: 23,
    width: 23,
    objectFit: "contain",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ScanHistoryTableComponent({
  rows,
  isFetching,
  isError,
  page,
  tableFilter,
  rowsPerPage,
  totalFiltered,
  handleChangePage,
  handleChangeRowsPerPage,
  handleReload,
  paginationOptions,
  changeOrder,
  lang,
  language,
  handleViewDetail,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const browserIcon = {
    Safari: Safari,
    Edge: MicrosoftEdge,
    MSIE: InternetExplorer,
    Opera: Opera,
    Chrome: Chrome,
    Firefox: Firefox,
    Netscape: Netscape,
  };

  const getFlagIcon = (countryCode) => {
    if (!hasFlag(countryCode)) {
      return (
        <Box className={classes.flag}>
          <UnknownFlagIcon />
        </Box>
      );
    }

    const FlagIcon = FlagIcons[countryCode];
    return (
      <Box className={classes.flag}>
        <FlagIcon title={countryCode} />
      </Box>
    );
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {isFetching || rows.length > 0 ? (
        <Paper id="test" style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product table"
              size="small"
              stickyHeader
            >
              <TableHead style={{ height: "45px" }}>
                <TableRow>
                  <StyledTableCell style={{ minWidth: "10rem" }}>
                    <TableSortLabel
                      active={tableFilter.column === "product_name"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.column !== "product_name";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("product_name", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.PRODUCT")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "serial_number"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.column !== "serial_number";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("serial_number", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SERIAL_NUMBER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.SCAN_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "location"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "location";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("location", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.SCAN_LOCATION")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "created_at"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.column !== "created_at";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("created_at", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.DATE")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "scan_device"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab =
                          tableFilter.column !== "scan_device";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("scan_device", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.PLATFORM")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={tableFilter.column === "browser"}
                      direction={tableFilter.order}
                      onClick={() => {
                        const differentTab = tableFilter.column !== "browser";
                        const order = differentTab
                          ? tableFilter.order
                          : tableFilter.order === "asc"
                          ? "desc"
                          : "asc";
                        changeOrder("browser", order);
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      {getLang(lang, "label.BROWSER")}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.DEVICE")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRowStickyLeftRight
                        key={index}
                        columnCount={8}
                      />
                    ))
                  : rows.map((row, index) => (
                      <StyledTableRow key={index} hover tabIndex={-1}>
                        <StyledTableCell style={{ minWidth: "30rem" }}>
                          <Box display="flex" alignItems="center">
                            <Box className={classes.productPhoto}>
                              {!row.product_image_url ? (
                                <img src={DefaultImg} alt="emptyProduct" />
                              ) : (
                                <img
                                  src={row.product_image_url}
                                  alt={row.name}
                                />
                              )}
                            </Box>
                            {/* <Typography variant="body2" style={{ wordBreak: "break-word"}}>{row.product_name}</Typography> */}
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="body2"
                                style={{ wordBreak: "break-word", whiteSpace: "break-spaces" }}
                              >
                                {row.product_name}
                              </Typography>
                              {!!row.attributes && !!row.attributes.length && (
                                <Typography
                                  className={classes.attributeText}
                                  variant="body2"
                                >
                                  {row.attributes.join(", ")}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            href={`${SERIAL_NUMBER_ROOT}/details/${row.serial_number_enc}`}
                            color="secondary"
                            onClick={(e) => {
                              handleViewDetail(
                                `${SERIAL_NUMBER_ROOT}/details/${row.serial_number_enc}`
                              );
                              e.preventDefault();
                              return false;
                            }}
                          >
                            <Typography variant="body2">
                              {row.serial_number}
                            </Typography>
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="body2"
                              style={{ minWidth: 30 }}
                            >
                              {row.scan_count}/{row.scan_limit || "No Limit"}
                            </Typography>
                            <Box
                              className={clsx(classes.chip, {
                                [classes.valid]: row.status === "active",
                                [classes.suspend]: row.status !== "active",
                              })}
                            >
                              <Typography variant="body2">
                                {row.status === "active"
                                  ? getLang(lang, "label.VALID")
                                  : getLang(lang, "label.SUSPENDED")}
                              </Typography>
                            </Box>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.location_country &&
                          row.location_country !== "Unknown" ? (
                            <Box display="flex" alignItems="center">
                              {getFlagIcon(row.location_country_code)}
                              <Typography variant="body2">
                                {row.location_country && row.location_state
                                  ? `${row.location_country}, ${row.location_state}`
                                  : row.location_country || row.location_state}
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              display="inline"
                              color="textSecondary"
                              variant="body2"
                            >
                              Unknown
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography variant="body2">
                            {moment(row.scan_date).format("lll")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.scan_device ? (
                            <img
                              className={classes.deviceIcon}
                              src={
                                row.device === "mac"
                                  ? IosIcon
                                  : row.device === "windows"
                                  ? WindowsIcon
                                  : AndroidIcon
                              }
                              alt="device"
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, "label.UNKNOWN")}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.browser ? (
                            <img
                              className={classes.deviceIcon}
                              src={browserIcon[row.browser]}
                              alt="browser"
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              {getLang(lang, "label.UNKNOWN")}
                            </Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            variant="body2"
                            color={
                              row.device === "Unknown"
                                ? "textSecondary"
                                : "textPrimary"
                            }
                          >
                            {getLang(lang, `label.${row.device.toUpperCase()}`)}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
            }
          />
        </Paper>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <Table
            className={classes.table}
            aria-label="product table"
            size="small"
            stickyHeader
          >
            <TableHead style={{ height: "45px" }}>
              <TableRow>
                <StyledTableCell style={{ minWidth: "10rem" }}>
                  {getLang(lang, "label.PRODUCT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SERIAL_NUMBER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SCAN_COUNT")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.SCAN_LOCATION")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PLATFORM")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.BROWSER")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DEVICE")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body2"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              No scan history
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
