import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { connect, useSelector, useDispatch } from "react-redux";
import ListingComponent from "./listing.page";
import { NEW_PRODUCT_PAGE, GENERATE_PRODUCT } from "../../config/product-mgmt.route";
import { PRODUCT_CATEGORY_ADD, PRODUCT_BRAND_MODEL_ADD, PRODUCT_ATTRIBUTE_ADD, PRODUCT_TEMPLATE_VIEW } from "lib/constants/accessRights"
import { resetProductPage } from "../../redux/slice/productPage.slice";
import {
  PRODUCT_PRODUCT_LIST_VIEW,
  PRODUCT_PRODUCT_LIST_ADD,
  PRODUCT_CATEGORY_VIEW,
  PRODUCT_BRAND_MODEL_VIEW,
  PRODUCT_ATTRIBUTE_VIEW,
} from "lib/constants/accessRights";
import { ROOT } from "modules/product-mgmt";
import { getLang } from "app/feature/constants";
import LoadingComponent from "components/loading";
import qs from "query-string";
import { openCreationDialog, closeCreationDialog, disableCategoryQuickEdit } from "modules/product-mgmt/redux/slice/products.slice.js";

function ListingContainer({
  history,
  resetProductPage
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isCategoryEdit = false;
  const hasProductViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_VIEW));
  const hasProductCategorytViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_CATEGORY_VIEW));
  const hasProductBrandModelViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_VIEW));
  const hasProductAttributeViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_VIEW));
  const hasProductAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_PRODUCT_LIST_ADD));
  const hasProductCategoryAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_CATEGORY_ADD));
  const hasProductAttributeAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_ATTRIBUTE_ADD));
  const hasProductBrandModelAddAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_BRAND_MODEL_ADD));
  const hasProductTemplateViewAccessRight = useSelector(state => state.profile.accessRights.includes(PRODUCT_TEMPLATE_VIEW));
  const lang = useSelector(state => state.constant.languages);
  const [activeIndex, setActiveIndex] = useState(0);
  const qsParams = qs.parse(location.search);
  const currentSearch = qsParams.tab || '';

  useEffect(()=>{
    if(!hasProductViewAccessRight){
      history.push('/admin/dashboard')
    }
  }, [history, hasProductViewAccessRight])

  useEffect(() => {
    let tempIndex = 0;
    switch (currentSearch) {
      case "productList":
        tempIndex = 0;
        break;
      case "category":
        tempIndex = 1;
        break;
      case "brandModel":
        tempIndex = 2;
        break;
      case "attribute":
        tempIndex = 3;
        break;
      case "landingPageTemplate":
        tempIndex = 4;
        break
      default:
        tempIndex = 0;
    }
    setActiveIndex(tempIndex);
  }, [currentSearch]);

  if (!hasProductViewAccessRight) return <LoadingComponent />;

  let tabLabels = [];
  if (hasProductViewAccessRight) {
    tabLabels.push(getLang(lang, "label.PRODUCT_LIST"));
    if (hasProductCategorytViewAccessRight) tabLabels.push(getLang(lang, "label.CATEGORY_LIST"));
    if (hasProductBrandModelViewAccessRight) tabLabels.push(getLang(lang, "label.BRAND_MODEL_LIST"));
    if (hasProductAttributeViewAccessRight) tabLabels.push(getLang(lang, "label.ATTRIBUTE_LIST"));
    if (hasProductTemplateViewAccessRight) tabLabels.push(getLang(lang, "label.LANDING_PAGE_TEMPLATES"))
  }

  const handleChange = (event, index) => {
    setActiveIndex(index);
    history.replace({
      pathname: ROOT,
      search: `?tab=${
        index === 0 ? 'productList' 
        : index === 1 ? 'category' 
        : index === 2 ? 'brandModel'
        : index === 3 ? 'attribute'
        : 'landingPageTemplate'
      }`
    })
  };

  const handleCreate = () => {
    resetProductPage();
    history.push(NEW_PRODUCT_PAGE);
  };

  const handleGenerateProduct = () => {
    history.push(GENERATE_PRODUCT)
  }

  const disableCategoryEdit = () => {
    dispatch(disableCategoryQuickEdit())
  }
  const handleAdd = (value) => {
    if (value) {
      dispatch(openCreationDialog());
    } else {
      dispatch(closeCreationDialog());
    }
  };

  return (
    <>
      <ListingComponent
        disableCategoryEdit={disableCategoryEdit}
        hasProductAddAccessRight={hasProductAddAccessRight}
        handleCreate={handleCreate}
        handleGenerateProduct={handleGenerateProduct}
        handleAdd={handleAdd}
        hasProductCategoryAddAccessRight={hasProductCategoryAddAccessRight}
        isCategoryEdit={isCategoryEdit}
        hasProductAttributeAddAccessRight={hasProductAttributeAddAccessRight}
        hasProductBrandModelAddAccessRight={hasProductBrandModelAddAccessRight}
        handleChange={handleChange}
        activeIndex={tabLabels[activeIndex] ? activeIndex : 0}
        tabLabels={tabLabels}
        lang={lang}
      />
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  resetProductPage: () => dispatch(resetProductPage())
});

export default connect(null, mapDispatchToProps)(ListingContainer);
