import { createAsyncThunk } from '@reduxjs/toolkit';
import accountApi from 'app/api/account';
import stockflowApi from 'app/api/stockflow';

export const STOCKFLOWDEALER = "stockflowDealer";

export const getAllDealerListing = createAsyncThunk(
  `${STOCKFLOWDEALER}/getAllDealerListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.GetDealerListing({
      length: payload.length,
      start: payload.start,
      order: payload.order,
      search: payload.search,
      search_by: payload.searchBy,
      dealer: payload.dealer
    })
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const updateDealerStatus = createAsyncThunk(
  `${STOCKFLOWDEALER}/updateDealerStatus`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.SetDealerUpdateStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)

export const updateDealerDetailsStatus = createAsyncThunk(
  `${STOCKFLOWDEALER}/updateDealerDetailsStatus`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.SetDealerUpdateStatus({
      id: payload.id,
      status: payload.status,
    })
      .then(response => response)
      .catch(error => rejectWithValue(error))
  }
)


export const getDealerDetails = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.dealerDetails.get(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const updateDealerDetails = createAsyncThunk(
  `${STOCKFLOWDEALER}/updateDealerDetails`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.dealerDetails.postUpdate(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const getBranchDropdown = createAsyncThunk(
  `${STOCKFLOWDEALER}/getBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.branchDropdown()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
)

export const getBranchDropdownSpecial = createAsyncThunk(
  `${STOCKFLOWDEALER}/getBranchDropdownSpecial`,
  async (payload, { rejectWithValue }) => {
    return accountApi.BranchDropdownSpecial()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
)

export const getAdminBranchDropdown = createAsyncThunk(
  `${STOCKFLOWDEALER}/getAdminBranchDropdown`,
  async (payload, { rejectWithValue }) => {
    return accountApi.BranchAdminDropdown()
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
)

export const getDealerDropdown = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerDropdown`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.GetDealerListDropdown(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error));
  }
)

export const getDealerIncentivePoint = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerIncentivePoint`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.incentivePoint.get(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const updateDealerIncentivePoint = createAsyncThunk(
  `${STOCKFLOWDEALER}/updateDealerIncentivePoint`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.incentivePoint.post(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const bulkUpdateDealerStatus = createAsyncThunk(
  `${STOCKFLOWDEALER}/bulkUpdateDealerStatus`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.updateBulkDealerStatus(payload)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const resendVerificationEmail = createAsyncThunk(
  `${STOCKFLOWDEALER}/resendVerificationEmail`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.resendVerificationEmail({ email: payload })
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response));
  }
)

export const getDealerStockoutList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerStockoutList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerStockoutList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerRecallList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerRecallList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerRecallList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerReceiveList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerReceiveList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerReceiveList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerOrderList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerOrderList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerOrderList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerRequestList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerRequestList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerRequestList(payload)
      .then(response => response.data)
      .catch(error => rejectWithValue(error))
  }
)

export const getDealerInventoryProductListing = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInventoryProductListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllDealerProductsListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInventoryPackageListing = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInventoryPackageListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllDealerPackagesListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInventoryBoxListing = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInventoryBoxListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllDealerBoxesListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInventoryLooseItemListing = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInventoryLooseItemListing`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getAllDealerLooseItemListing(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerRewardList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerRewardList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerRewardList(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerPointHistoryList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerPointHistoryList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerPointHistoryList(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerPointExpiringList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerPointExpiringList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerPointExpiringList(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInvitedTrending = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInvitedTrending`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerInvitedTrending(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInvitedList = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInvitedList`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerInvitedList(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getDealerInvitedSummary = createAsyncThunk(
  `${STOCKFLOWDEALER}/getDealerInvitedSummary`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getDealerInvitedSummary(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getTotalStockoutTrending = createAsyncThunk(
  `${STOCKFLOWDEALER}/getTotalStockoutTrending`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getTotalStockoutTrending(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getTotalRecallTrending = createAsyncThunk(
  `${STOCKFLOWDEALER}/getTotalRecallTrending`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getTotalRecallTrending(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const getStockoutByProduct = createAsyncThunk(
  `${STOCKFLOWDEALER}/getStockoutByProduct`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.getStockoutByProduct(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error))
  }  
)

export const updateDealerUpline = createAsyncThunk(
  `${STOCKFLOWDEALER}/updateDealerUpline`,
  async (payload, { rejectWithValue }) => {
    return stockflowApi.updateDealerUpline(payload)
    .then(response => response.data)
    .catch(error => rejectWithValue(error.data))
  }  
)
