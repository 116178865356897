import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { SIGNUPSTEPS } from "../../constants";
import accountApi from "app/api/account";
import paymentApi from "app/api/payment";
import { LABEL_COMPANY } from "modules/admin/constants";

export const submitSignupProfile = createAction(
  `${SIGNUPSTEPS}/submitSignupProfile`
);

//To deprecate submitSignupProfile to avoid submitting signatures for agreeing privacy policy and user agreement
export const submitSignupProfileNoSignature = createAction(
  `${SIGNUPSTEPS}/submitSignupProfileNoSignature`
);
export const addPaymentMethod = createAction(`${SIGNUPSTEPS}/addPaymentMethod`);
export const submitSignupCompany = createAction(
  `${SIGNUPSTEPS}/submitSignupCompany`
);
export const fetchSignupData = createAction(`${SIGNUPSTEPS}/fetchSignupData`);

export const setUserProfile = createAsyncThunk(
  `${SIGNUPSTEPS}/setUserProfile`,
  async (payload, { rejectWithValue, dispatch }) => {
    return accountApi.profile
      .put({
        first_name: payload.firstName,
        last_name: payload.lastName,
        phone_country: payload.country,
        phone_number: payload.phone,
        phone_code: payload.number,
        newsletter: payload.newsletter,
        image_policy_url: payload.privacyPolicySignatureUrl,
        image_agreement_url: payload.endUserAgreementSignatureUrl,
        locale: payload.language,
        timezone: payload.timezone
      })
      .then(response => response)
      .catch(error => rejectWithValue(error));
  }
);

export const submitCompanyInfo = createAsyncThunk(
  `${SIGNUPSTEPS}/submitCompanyInfo`,
  async (payload, { rejectWithValue, getState }) => {
    const labels = getState().session.labels;
    const hasSubmitted = getState().signupsteps.hasSubmittedCompany;
    const companyExist =
      labels.some(label => label.key === LABEL_COMPANY) || hasSubmitted;
    if (companyExist) {
      return accountApi.company
        .put(payload)
        .then(response => response)
        .catch(error => rejectWithValue(error));
    } else {
      return accountApi.company
        .post(payload)
        .then(response => response)
        .catch(error => rejectWithValue(error));
    }
  }
);

export const setSignupPaymentMethod = createAsyncThunk(
  `${SIGNUPSTEPS}/setSignupPaymentMethod`,
  async (payload, { rejectWithValue }) => {
    const addPayment = await paymentApi.paymentMethod
      .post({
        payment_method: payload.pmTokenID,
        isDefault: true
      })
      .then(response => response)
      .catch(error => ({ error }));

    if (addPayment.error) {
      return rejectWithValue(addPayment.error);
    }

    const addPresubscription = await paymentApi.presubscription
      .post({
        group: payload.group,
        interval: payload.interval
      })
      .then(response => response)
      .catch(error => ({ error }));

    if (addPresubscription.error) {
      return rejectWithValue(addPresubscription.error);
    }

    return { addPresubscription, addPayment };
  }
);
