import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Button,
  Divider,
  Typography,
  IconButton,
  Backdrop,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { AddSVG, WarningSVG } from "../../../utils/static";
import { EditPrizeCardV2, BlankPrizeCardV2, ViewPrizeCardV2 } from "../../card";
import Dialog from "components/dialog/customDialog";
import AlertIcon from "assets/svg/alert.svg";
import { getLang } from "app/feature/constants";
import AntSwitch from "components/switch/switch";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { isFileExceedLimit, isImage } from "lib/helper";
import GeneralDialog from "components/dialog/generalDialog";
import { Skeleton } from "@material-ui/lab";

const style = (theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    width: 650,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 8, 4),
    textAlign: "center",
  },
  infoText: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
  },
  toggleControl: {
    margin: "7px 0px 7px 0px",
  },
});

const useStyles = makeStyles(style);

export default function InstantPrizeDialogComponent({
  hasIldEditAccessRight,
  hasIldPrizeDeleteAccessRight,
  type,
  prizeList,
  handleClose,
  isOpen,
  defaultPrizeData,
  isEditing,
  lang,
  handleSubmit,
  setIsEditing,
  isSubmitting,
  hasWinRate,
  selectedInstantLuckyDraw,
  showConfirmDialog,
  setShowConfirmDialog,
  disbursementInfos,
  isFetching,
}) {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Paper elevation={0}>
        <Box className={classes.root}>
          <Box className={classes.header}>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {type === "create"
                ? getLang(lang, "label.ADD_INSTANT_LUCKY_DRAW_PRIZE")
                : defaultPrizeData.instantLuckyDraw
                ? defaultPrizeData.instantLuckyDraw.title
                : getLang(lang, "label.INSTANT_LUCKY_DRAW_PRIZE")}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Divider />

          <Formik
            initialValues={{
              prizeList: prizeList,
              defaultPrizeData: defaultPrizeData,
              enableWinRate: hasWinRate,
              deletedUuids: [],
              remainingWinRate: prizeList.length
                ? prizeList.reduce((total, prize) => {
                    if (prize.winRate) {
                      total -= prize.winRate;
                    }
                    return total;
                  }, 100)
                : 100,
              ild: selectedInstantLuckyDraw,
            }}
            validationSchema={Yup.object({
              remainingWinRate: Yup.number().min(
                0,
                getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
                  value: 0,
                })
              ),
              prizeList: Yup.array().of(
                Yup.object({
                  prizeName: Yup.string()
                    .trim()
                    .required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
                  quantity: Yup.number()
                    .min(
                      0,
                      getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
                        value: 0,
                      })
                    )
                    // .moreThan(
                    //   0,
                    //   getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                    //     value: 0,
                    //   })
                    // )
                    .required(
                      getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                    ),
                  balance: Yup.number()
                    .min(
                      0,
                      getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                        value: 0,
                      })
                    ),
                  type: Yup.mixed().required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  ),
                  prizeImage: Yup.lazy((value) => {
                    if (value instanceof File) {
                      return Yup.mixed()
                        .test(
                          "fileSize",
                          getLang(lang, "message.error.FILE_TOO_LARGE"),
                          (v) => {
                            return !isFileExceedLimit(v.size, 2, "MB");
                          }
                        )
                        .test(
                          "fileType",
                          getLang(lang, "message.error.FILE_NOT_IMAGE"),
                          (v) => {
                            return isImage(v);
                          }
                        );
                    } else {
                      return Yup.string().required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      );
                    }
                  }),
                  credit: Yup.mixed().when("type", {
                    is: (type) => type > 0,
                    then: Yup.number()
                      .required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      )
                      .moreThan(
                        0,
                        getLang(lang, "message.error.FORM_VALIDATE_MORETHAN", {
                          value: 0,
                        })
                      ),
                  }),
                })
              ),
              defaultPrizeData: Yup.object({
                prizeName: Yup.string()
                  .trim()
                  .required(
                    getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                  ),
                prizeImage: Yup.lazy((value) => {
                  if (value instanceof File) {
                    return Yup.mixed()
                      .test(
                        "fileSize",
                        getLang(lang, "message.error.FILE_TOO_LARGE"),
                        (v) => {
                          return !isFileExceedLimit(v.size, 2, "MB");
                        }
                      )
                      .test(
                        "fileType",
                        getLang(lang, "message.error.FILE_NOT_IMAGE"),
                        (v) => {
                          return isImage(v);
                        }
                      );
                  } else {
                    return Yup.mixed();
                  }
                }),
              }),
            })}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Box>
                <Box
                  pt={1}
                  pb={1}
                  px={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ fontWeight: "bold" }}
                  >
                    {hasIldEditAccessRight
                      ? getLang(lang, "label.MANAGE_PRIZE")
                      : getLang(lang, "label.VIEW_PRIZE")}
                  </Typography>
                  <FormControlLabel
                    classes={{
                      root: classes.toggleControl,
                    }}
                    control={
                      <AntSwitch
                        checked={formik.values.enableWinRate}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "enableWinRate",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    disabled={
                      !isEditing ||
                      (selectedInstantLuckyDraw &&
                        selectedInstantLuckyDraw.progress === "published")
                    }
                    label={
                      <Typography variant="body2" color="primary">
                        &ensp;
                        {getLang(lang, "label.ENABLE_WINNING_RATE_ALL_PRIZES")}
                      </Typography>
                    }
                  />
                </Box>

                {isFetching ? (
                  <Box px={2}>
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                    <Skeleton variant="text" animation="wave" height={25} />
                  </Box>
                ) : formik.values.prizeList.length === 0 && !isEditing ? (
                  <Box className={classes.content}>
                    <WarningSVG />
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.infoText}
                    >
                      {getLang(
                        lang,
                        "paragraph.NO_INSTANT_LUCKY_DRAW_PRIZE_ADDED"
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <FieldArray
                    name="prizeList"
                    render={(arrayHelpers) => (
                      <Box maxHeight="70dvh" overflow="auto">
                        {formik.values.prizeList.length > 0 ? (
                          <Box
                          // maxHeight={isEditing ? "auto" : "58dvh"}
                          // overflow="auto"
                          >
                            {formik.values.prizeList.map((prize, index) =>
                              !isEditing ? (
                                <ViewPrizeCardV2
                                  key={index}
                                  prizeDetail={prize}
                                  enableWinRate={formik.values.enableWinRate}
                                  disbursementInfo={
                                    prize.type > 0
                                      ? disbursementInfos.find(
                                          (info) =>
                                            Number(info.id) ===
                                            Number(prize.type)
                                        )
                                      : null
                                  }
                                />
                              ) : (
                                <EditPrizeCardV2
                                  key={index}
                                  instantLuckyDraw={selectedInstantLuckyDraw}
                                  isPublished={
                                    selectedInstantLuckyDraw &&
                                    selectedInstantLuckyDraw.progress ===
                                      "published"
                                  }
                                  disabled={isSubmitting}
                                  index={index}
                                  prizeDetail={prize}
                                  handleDelete={() => {
                                    if (prize.uuid) {
                                      let temp = [
                                        ...formik.values.deletedUuids,
                                      ];
                                      temp.push(prize.uuid);
                                      formik.setFieldValue(
                                        "deletedUuids",
                                        temp
                                      );
                                    }

                                    arrayHelpers.remove(index);

                                    let remainingWinRate = prizeList.reduce(
                                      (total, prize) => {
                                        if (prize.winRate) {
                                          total -= prize.winRate;
                                        }
                                        return total;
                                      },
                                      100
                                    );
                                    formik.setFieldValue(
                                      "remainingWinRate",
                                      remainingWinRate
                                    );
                                  }}
                                  enableWinRate={formik.values.enableWinRate}
                                  formik={formik}
                                  updateDetail={(key, val) => {
                                    formik.setFieldValue(
                                      `prizeList.[${index}].[${key}]`,
                                      val
                                    );

                                    if (key === "winRate" && !isNaN(val)) {
                                      let count = 0;
                                      let remaining =
                                        formik.values.prizeList.reduce(
                                          (total, prize) => {
                                            if (count === index) {
                                              total -= val;
                                            } else if (prize.winRate) {
                                              total -= prize.winRate;
                                            }
                                            count++;
                                            return total;
                                          },
                                          100
                                        );
                                      formik.setFieldValue(
                                        "remainingWinRate",
                                        parseFloat(remaining.toFixed(3))
                                      );
                                      formik.setFieldValue(
                                        "defaultPrizeData.winRate",
                                        remaining
                                      );
                                      formik.setFieldTouched(
                                        "remainingWinRate",
                                        true
                                      );
                                    }
                                  }}
                                  hasIldPrizeDeleteAccessRight={
                                    hasIldPrizeDeleteAccessRight
                                  }
                                  disbursementInfos={disbursementInfos}
                                />
                              )
                            )}
                          </Box>
                        ) : null}

                        <BlankPrizeCardV2
                          prizeDetail={formik.values.defaultPrizeData}
                          isEditing={isEditing}
                          formik={formik}
                          updateDetail={(key, val) => {
                            formik.setFieldValue(
                              `defaultPrizeData.[${key}]`,
                              val
                            );
                          }}
                          disabled={isSubmitting}
                          enableWinRate={formik.values.enableWinRate}
                          remainingWinRate={formik.values.remainingWinRate}
                        />
                      </Box>
                    )}
                  />
                )}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {hasIldEditAccessRight && !isEditing && (
                    <Box p={2} pt={2} pl={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => setIsEditing(true)}
                      >
                        {getLang(lang, "label.EDIT")}
                      </Button>
                    </Box>
                  )}

                  {formik.values.prizeList.length < 7 &&
                  hasIldEditAccessRight &&
                  isEditing ? (
                    <Box p={2} pt={2} pl={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => {
                          let tempPrizes = [...formik.values.prizeList];
                          tempPrizes.push({
                            id: "",
                            description: "",
                            prizeName: "",
                            quantity: "",
                            prizeImage: "",
                            prizeType: 1,
                            type: 0,
                            credit: 0,
                            winRate: 0,
                            provider: null,
                          });
                          formik.setFieldValue("prizeList", tempPrizes);
                        }}
                        disabled={
                          isSubmitting ||
                          selectedInstantLuckyDraw.progress === "published"
                        }
                      >
                        <Box pr={0.5} display="flex">
                          <AddSVG />
                        </Box>
                        {getLang(lang, "label.ADD_PRIZE")}
                      </Button>
                    </Box>
                  ) : null}
                  {isEditing ? (
                    <Box p={2} pt={2} pl={3}>
                      <Button
                        color="primary"
                        disableElevation
                        onClick={() => {
                          setIsEditing(false);
                          formik.resetForm();
                        }}
                        disabled={isSubmitting}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        // type="submit"
                        disabled={isSubmitting || !formik.isValid}
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress
                              style={{ width: 10, height: 10 }}
                            />
                          ) : null
                        }
                        onClick={() => {
                          formik.validateForm();

                          formik.values.prizeList.forEach((p, i) => {
                            formik.setFieldTouched(
                              `prizeList.[${i}].prizeName`,
                              true
                            );
                            formik.setFieldTouched(
                              `prizeList.[${i}].quantity`,
                              true
                            );
                            formik.setFieldTouched(
                              `prizeList.[${i}].prizeImage`,
                              true
                            );
                            formik.setFieldTouched(
                              `prizeList.[${i}].type`,
                              true
                            );
                            formik.setFieldTouched(
                              `prizeList.[${i}].credit`,
                              true
                            );
                          });

                          if (formik.isValid) {
                            setShowConfirmDialog(true);
                          }
                        }}
                      >
                        {getLang(lang, "label.SUBMIT")}
                      </Button>
                    </Box>
                  ) : null}
                </Box>
                <GeneralDialog
                  isOpen={showConfirmDialog}
                  handleClose={() => setShowConfirmDialog(false)}
                  handleProceed={() => formik.handleSubmit()}
                  title={getLang(lang, "label.CONFIRMATION")}
                  description={getLang(
                    lang,
                    "paragraph.UPDATE_ILD_PRIZE_CONFIRMATION"
                  )}
                  type="danger"
                  icon={<img src={AlertIcon} alt="alert" />}
                />
              </Box>
            )}
          </Formik>
        </Box>
      </Paper>
    </Dialog>
  );
}
