import {
  Backdrop,
  Box,
  Dialog,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { getLang } from "../../../../../app/feature/constants";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { AccessTime as AccessTimeIcon } from "@material-ui/icons";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import DefaultImg from "assets/img/img-default.png";
import DefaultProfile from "../../../../../assets/img/defaultPhoto.png";
import LoadingComponent from "../../../../../components/loading";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#F9FAFB",
    overflowY: "auto",
    padding: 16,
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "50px",
    height: "50px",
    marginRight: "10px",
    borderRadius: 4,
    overflow: "hidden",
    "& img": {
      height: "50px",
      width: "50px",
    },
    flex: "0 0 50px",
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "18px",
    minWidth: "18px",
    height: "18px",
    marginRight: 8,
    borderRadius: "50%",
    "& img": {
      height: "18px",
      width: "18px",
      borderRadius: "50%",
    },
  },
}));

export default function SerialNumberFirstScanPopUpComponent({
  isOpen,
  handleClose,
  rows,
  lang,
  haveNext,
  handleLoadingMore,
  handleSerialNumberClick,
  handleConsumerNameClick,
}) {
  const classes = useStyle();

  const ListTile = ({
    productImageUrl,
    productName,
    serialNumber,
    enc,
    scanDate,
    user,
    userName,
    userId,
    userImageUrl,
  }) => (
    <Box pb="16px">
      <Paper>
        <Box p={2} style={{ display: "flex", alignItems: "center" }}>
          <Box className={classes.productPhoto}>
            <img
              src={
                !productImageUrl || productImageUrl === ""
                  ? DefaultImg
                  : productImageUrl
              }
              alt={
                !productImageUrl || productImageUrl === ""
                  ? "emptyProduct"
                  : "product"
              }
            />
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Typography variant="body1" style={{ wordBreak: "break-word" }}>
                {productName} (
                <Link
                  variant="body1"
                  color="secondary"
                  href=""
                  onClick={() => handleSerialNumberClick(enc)}
                >
                  {serialNumber}
                </Link>
                )
              </Typography>
            </Box>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box pr={1} style={{ display: "flex", alignItems: "center" }}>
                <AccessTimeIcon fontSize="small" />
              </Box>
              <Typography variant="caption">
                {moment(scanDate).format("DD MMM YYYY, h:mm a")}
              </Typography>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "2px",
                paddingTop: "2px",
              }}
            >
              {userId ? (
                <>
                  <Box className={classes.profilePhoto}>
                    <img
                      src={!userImageUrl ? DefaultProfile : userImageUrl}
                      alt={!userImageUrl ? "default-profile" : "profile"}
                    />
                  </Box>
                  <Box>
                    <Link
                      variant="caption"
                      color="secondary"
                      href=""
                      onClick={() => handleConsumerNameClick(userId)}
                    >
                      {userName}
                    </Link>
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.profilePhoto}>
                    <img src={DefaultProfile} alt={"default-profile"} />
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      {getLang(lang, "label.ANONYMOUS")}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );

  const Loading = () => (
    <Box py={3} style={{ display: "flex", justifyContent: "center" }}>
      <LoadingComponent />
    </Box>
  );

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.FIRST_SCAN_SN")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box id="scrollContainer" style={{ overflowY: "auto" }}>
        <InfiniteScroll
          style={{ padding: "16px" }}
          scrollableTarget="scrollContainer"
          dataLength={rows.length}
          next={handleLoadingMore}
          hasMore={haveNext}
          loader={<Loading />}
        >
          {rows.map((row, i) => (
            <ListTile
              key={i}
              productImageUrl={row.product_image_url}
              productName={row.product_name}
              serialNumber={row.serial_number}
              enc={row.enc}
              scanDate={row.created_at}
              user={row.user}
              userName={row.name}
              userId={row.user_id}
              userImageUrl={row.image_url}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
