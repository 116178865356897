import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { TablePaginationActions } from "components/tablePagination";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import moment from "moment";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    border: "1px solid #dddddd",
  },
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
      backgroundColor: "white",
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
      "& td": {
        backgroundColor: "#f5f5f5",
      },
    },
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
  },
  button: {
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
  statusChip: {
    borderRadius: "16px",
    padding: "4px 16px",
    boxShadow: "0px 2px 4px 0px #00000045",
    color: "#ffffff",
  },
}));

export default function CampaignTngDisbursementTableComponent({
  rows,
  isFetching,
  isError,
  handleReload,
  handleChangePage,
  handleChangeRowsPerPage,
  totalFiltered,
  paginationOptions,
  tableFilter,
  lang,
  handleExport,
  language,
  disbursementInfo,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const statusColor = {
    Published: "#00DB5F",
    "Not Published": "#FF9933",
    Ended: "#8F8F8F",
  };

  return (
    <>
      {!isFetching && isError && <RefreshTable handleReload={handleReload} />}
      {rows.length > 0 || isFetching ? (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              size="small"
              aria-label="assign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>{getLang(lang, "label.CAMPAIGN_NAME")}</Box>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTIVE_PERIOD")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BUDGET")}{" "}
                    {disbursementInfo ? `(${disbursementInfo.currency})` : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CREDIT_USED")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.WIN_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CLAIMED_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StickyRightTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>
                      {getLang(lang, "label.DOWNLOAD_REPORT_TITLE")}
                    </Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? [...new Array(5)].map((v, index) => (
                      <SkeletonTableRow key={index} columnCount={7} />
                    ))
                  : rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StickyLeftTableCell style={{ textAlign: "center" }}>
                            <Box
                              px={1}
                              display="flex"
                              justifyContent="flex-start"
                            >
                              {row.campaign_name}
                            </Box>
                          </StickyLeftTableCell>
                          <StyledTableCell>
                            <Box>
                              <Typography variant="caption" display="inline">
                                {row.start_at}
                              </Typography>
                              <Typography variant="caption" display="inline">
                                &nbsp;-&nbsp;
                              </Typography>
                              <Typography variant="caption" d display="inline">
                                {row.end_at}
                              </Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" justifyContent="flex-end">
                              {parseFloat(row.budget)
                                .toFixed(2)
                                .toLocaleString("en-US")}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" justifyContent="flex-end">
                              {parseFloat(row.credit_used)
                                .toFixed(2)
                                .toLocaleString("en-US")}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" justifyContent="flex-end">
                              {row.win_count}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" justifyContent="flex-end">
                              {row.claimed_count}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box display="flex" justifyContent="center">
                              <Box
                                className={classes.statusChip}
                                style={{
                                  backgroundColor: statusColor[row.status],
                                }}
                              >
                                {row.status === "Ended"
                                  ? getLang(lang, "label.ENDED")
                                  : row.status === "Published"
                                  ? getLang(lang, "label.PUBLISHED")
                                  : getLang(lang, "label.NOT_PUBLISHED")}
                              </Box>
                            </Box>
                          </StyledTableCell>
                          <StickyRightTableCell style={{ textAlign: "center" }}>
                            <Box px={1} display="flex" justifyContent="center">
                              {row.status !== "Not Published" ? (
                                <IconButton
                                  size="small"
                                  onClick={() => handleExport(row)}
                                >
                                  <DownloadIcon />
                                </IconButton>
                              ) : (
                                "-"
                              )}
                            </Box>
                          </StickyRightTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{
              backgroundColor: "#fff",
              boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
            }}
            ActionsComponent={TablePaginationActions}
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={totalFiltered}
            rowsPerPage={tableFilter.rowsPerPage}
            page={tableFilter.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              classes: classes.toolbar,
            }}
            labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${getLang(
                lang,
                "label.OF"
              )} ${count.toLocaleString("en-US")}`
            }
          />
        </Box>
      ) : null}
      {!isFetching && !isError && rows.length === 0 && (
        <Box>
          <TableContainer className={classes.tableContainer}>
            <Table
              className={classes.table}
              size="small"
              aria-label="assign table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StickyLeftTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>{getLang(lang, "label.CAMPAIGN_NAME")}</Box>
                  </StickyLeftTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.ACTIVE_PERIOD")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.BUDGET")}{" "}
                    {disbursementInfo ? `(${disbursementInfo.currency})` : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CREDIT_USED")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.WIN_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.CLAIMED_COUNT")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getLang(lang, "label.STATUS")}
                  </StyledTableCell>
                  <StickyRightTableCell style={{ textAlign: "center" }}>
                    <Box px={1}>
                      {getLang(lang, "label.DOWNLOAD_REPORT_TITLE")}
                    </Box>
                  </StickyRightTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box py={9} className={classes.emptyContainer}>
            <Typography
              variant="body1"
              style={{ paddingBottom: "16px", fontWeight: "bold" }}
            >
              {getLang(lang, "paragraph.NO_CAMPAIGN_ASSOCIATED")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
