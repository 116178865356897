import React, { useEffect, useState, useRef } from "react";
import ListingComponent from "./listing.page";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchTableLength, getListingSerialNumber } from "../../redux";
import { selectLoading } from "modules/notification";
import {
  SERIAL_NUMBER_GENERATE,
  SERIAL_NUMBER_EDIT,
} from "lib/constants/accessRights";
import { getSnSummaryCount, updateSNStatus } from "../../redux/action";
import { unwrapResult } from "@reduxjs/toolkit";
import ConfirmationDialog from "components/dialog/confirmation";
import { generatePaginationOptions, updateUrlQueryParam } from "lib/helper";
import { fetchMeAndBranchDropdownSpecial, getAllProducts } from "modules/product-mgmt/redux/action/products.action";
import { getLang } from "app/feature/constants";
import { useHistory } from "react-router-dom";

function ListingContainer({
  fetchTableLength,
  listingSerialNumberData,
  length,
  filteredlength,
  getListingSerialNumber,
  updateSNStatus,
  isUpdatingSNStatus,
  isSnLoading,
  summaryCount,
  isSnCountLoading,
  isError,
}) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.profile.language);
  const history = useHistory();
  const hasSerialNumberGenerateAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_GENERATE)
  );
  const hasSerialNumberEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(SERIAL_NUMBER_EDIT)
  );
  const productDropdown = useSelector((state) => state.products.allProducts);
  const isProductDropdownLoading = useSelector((state) => 
    selectLoading(state, getAllProducts.typePrefix)
  );
  const lang = useSelector((state) => state.constant.languages);
  const branchDropdown = useSelector((state) => state.products.branchDropdown);
  const isBranchDropdownLoading = useSelector((state) =>
    selectLoading(state, fetchMeAndBranchDropdownSpecial.typePrefix)
  );
  const isSearchResult = filteredlength < length;
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
  const [isActivateDialogOpen, setIsActivateDialogOpen] = useState(false);
  const [changeStatusTargetEnc, setChangeStatusTargetEnc] = useState("");
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [snTableFilter, setSnTableFilter] = useState({
    length: 25,
    start: 0,
    search: "",
    status: "",
    searchColumn: "serial_number",
    node_uuids: "",
    product_uuids: "",
    products: [],
    productNotAssigned: false,
    branch: [],
  });

  const query = new URLSearchParams(window.location.search);
  const tableFilterInitialRef = useRef(true);
  const [urlLoaded, setUrlLoaded] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [shouldFetchTableLength, setShouldFetchTableLength] = useState(true);

  useEffect(() => {
    const isFilter = query.has("is_filter");
    let filter = sessionStorage.getItem("qr_filter");

    let temp = { ...snTableFilter };
    if (isFilter && filter) {
      temp = JSON.parse(filter);
      setIsFilterUpdated(true);
    }

    if (isFilter) {
      updateUrlQueryParam(null, ["is_filter"]);
    }
    sessionStorage.removeItem("qr_filter");

    setSnTableFilter(temp);

    setUrlLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBranchDropdownLoading || isProductDropdownLoading) return;
    const url = new URL(window.location.href);
    const tableFilter = {
      length: parseInt(url.searchParams.get("length")) || 25,
      start: parseInt(url.searchParams.get("start")) || 0,
      search: url.searchParams.get("q") || "",
      status: url.searchParams.get("status") || "",
      searchColumn: url.searchParams.get("stype") || "serial_number",
      node_uuids: url.searchParams.get("br") === "all" ? "" : url.searchParams.get("br"),
      product_uuids: (["all", "na"].includes(url.searchParams.get("pd"))) ? "" : url.searchParams.get("pd"),
      productNotAssigned: url.searchParams.get("pd") === "na",
    };
    const branches = getAllBranch(branchDropdown, []);
    tableFilter.branch = (
      url.searchParams.get("br") ?
        url.searchParams.get("br") === "all" ?
          branches :
          url.searchParams.get("br").split(",").map(id => branches.find(i => i.node_id === id) || {}) : 
        []
    );
    tableFilter.products = (
      url.searchParams.get("pd") ?
        url.searchParams.get("pd") === "na" ? [] : (
          url.searchParams.get("pd") === "all" ?
            productDropdown :
            url.searchParams.get("pd").split(",").map(id => productDropdown.find(i => i.id === id))
        ) :
        []
    );
    setSnTableFilter(tableFilter);

    // eslint-disable-next-line
  }, [isBranchDropdownLoading, isProductDropdownLoading])

  useEffect(() => {
    dispatch(fetchMeAndBranchDropdownSpecial({ search: "" }));
    dispatch(getAllProducts());
    dispatch(getSnSummaryCount());
  }, [dispatch]);

  useEffect(() => {
    if (!tableFilterInitialRef.current && urlLoaded && !isBranchDropdownLoading && !isProductDropdownLoading) {
      fetchData();

      if (shouldFetchTableLength) {
        fetchTableLength({
          search: snTableFilter.search,
          searchColumn: snTableFilter.searchColumn,
          nodes: snTableFilter.node_uuids,
          products: snTableFilter.product_uuids,
          status: snTableFilter.status,
          productNotAssigned: snTableFilter.productNotAssigned,
        });
      }

      // Reset shouldFetchTableLength to true after use
      setShouldFetchTableLength(true);
    }
    tableFilterInitialRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snTableFilter]);

  useEffect(() => {
    setPaginationOptions(generatePaginationOptions(length));
  }, [length]);

  const fetchData = () => {
    getListingSerialNumber({
      length: snTableFilter.length,
      start: snTableFilter.length * snTableFilter.start,
      search: snTableFilter.search,
      searchColumn: snTableFilter.searchColumn,
      node_uuids: snTableFilter.node_uuids,
      product_uuids: snTableFilter.product_uuids,
      status: snTableFilter.status,
      productNotAssigned: snTableFilter.productNotAssigned,
    });
  };

  const getAllBranch = (branchDropdown, target) => {
    for (const branch of branchDropdown) {
      target.push({
        name: branch.name,
        node_id: branch.node_id,
        parent_node_id: branch.parent_node_id,
        remarks: branch.remarks,
      })

      if (branch.child !== undefined && branch.child.length > 0){
          target = getAllBranch(branch.child, target);
      }
    }
    return target;
  };

  const handleChangePage = (event, newPage) => {
    let newData = {
      ...snTableFilter,
      start: newPage,
    };
    setQueryParam("start", newPage);
    setIsFilterUpdated(true);
    // Disable fetchTableLength for this update
    setShouldFetchTableLength(false);
    setSnTableFilter(newData);
  };

  const handleChangeRowsPerPage = (event) => {
    let newData = {
      ...snTableFilter,
      start: 0,
      length: event.target.value,
    };
    setQueryParam("start", "");
    setQueryParam("length", event.target.value);
    setIsFilterUpdated(true);
    setSnTableFilter(newData);
  };

  const handleSearch = (values) => {
    let tempItem = values.products.map((item) => item.id);
    let tempBranch = values.branch.map((branch) => branch.node_id);

    let newData = {
      ...snTableFilter,
      start: 0,
      search: values.search,
      searchColumn: values.searchColumn,
      node_uuids: tempBranch.join(","),
      product_uuids: tempItem.join(","),
      products: values.products,
      branch: values.branch,
      status: values.status ? values.status.value : "",
      productNotAssigned: values.productNotAssigned,
    };

    setQueryParam("stype", newData.searchColumn);
    setQueryParam("q", newData.search);
    setQueryParam("status", newData.status);
    setQueryParam("br", branchDropdown.every(i => tempBranch.includes(i.node_id)) ? "all" : newData.node_uuids);
    setQueryParam(
      "pd",
      newData.productNotAssigned ? "na" : (
        productDropdown.every(i => tempItem.includes(i.id)) ? "all" : newData.product_uuids
      )
    );
    
    setIsFilterUpdated(true);
    setSnTableFilter(newData);
  };

  const setQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.replaceState(null, null, url.href);
  }

  const clearSearch = () => {
    let newData = {
      ...snTableFilter,
      start: 0,
      search: "",
      searchColumn: "serial_number",
      status: "",
      node_uuids: [],
      product_uuids: [],
      products: [],
      branch: [],
      productNotAssigned: false,
    };

    setQueryParam("start", "");
    setQueryParam("stype", "");
    setQueryParam("q", "");
    setQueryParam("status", "");
    setQueryParam("br", "");
    setQueryParam("pd", "");

    setIsFilterUpdated(false);
    setSnTableFilter(newData);
  };

  const handleStatusChange = ({ currentStatus, enc }) => {
    //add access right
    setChangeStatusTargetEnc(enc);
    if (currentStatus === "active") {
      setIsDeactivateDialogOpen(true);
    } else {
      setIsActivateDialogOpen(true);
    }
  };

  const handleStatusSubmit = (status) => {
    //add access right
    updateSNStatus({
      enc: changeStatusTargetEnc,
      status: status ? "active" : "suspend",
    })
      .then(unwrapResult)
      .then(() => {
        setIsDeactivateDialogOpen(false);
        setIsActivateDialogOpen(false);
        dispatch(getSnSummaryCount());
      });
  };

  const handleViewDetail = (url) => {
    if (isFilterUpdated) {
      updateUrlQueryParam({ is_filter: true });
      sessionStorage.setItem("qr_filter", JSON.stringify(snTableFilter));
    }
    history.push(url);
  };

  return (
    <>
      <ListingComponent
        hasSerialNumberEditAccessRight={hasSerialNumberEditAccessRight}
        hasSerialNumberGenerateAccessRight={hasSerialNumberGenerateAccessRight}
        listingSerialNumberData={listingSerialNumberData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={snTableFilter.start}
        search={snTableFilter.search}
        rowsPerPage={snTableFilter.length}
        isFetching={isSnLoading}
        length={filteredlength}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
        searchColumn={snTableFilter.searchColumn}
        // setSearchColumn={setSearchColumn}
        language={language}
        handleReload={fetchData}
        handleStatusChange={handleStatusChange}
        paginationOptions={paginationOptions}
        productDropdown={productDropdown}
        lang={lang}
        tableFilter={snTableFilter}
        handleViewDetail={handleViewDetail}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        isSearchResult={isSearchResult}
        summaryCount={summaryCount}
        isSnCountLoading={isSnCountLoading}
        isError={isError}
        setQueryParam={setQueryParam}
        branchDropdown={branchDropdown}
        isBranchDropdownLoading={isBranchDropdownLoading}
        isProductDropdownLoading={isProductDropdownLoading}
      />
      <ConfirmationDialog
        isOpen={isActivateDialogOpen}
        handleClose={() => setIsActivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(true)}
        isLoading={isUpdatingSNStatus}
        type={"success"}
        description={getLang(lang, "paragraph.ACTIVATE_SN_QUESTION")}
        title={getLang(lang, "label.REACTIVATE_SERIAL_NUMBER")}
      />
      <ConfirmationDialog
        isOpen={isDeactivateDialogOpen}
        handleClose={() => setIsDeactivateDialogOpen(false)}
        handleProceed={() => handleStatusSubmit(false)}
        isLoading={isUpdatingSNStatus}
        type={"danger"}
        description={getLang(lang, "paragraph.SUSPEND_SN_QUESTION")}
        title={getLang(lang, "label.SUSPEND_SERIAL_NUMBER")}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  listingSerialNumberData: state.serialNumber.SerialNumberTable.data,
  length: state.serialNumber.SerialNumberTable.totalRecords,
  filteredlength: state.serialNumber.SerialNumberTable.filteredTotalRecords,
  isUpdatingSNStatus: selectLoading(state, updateSNStatus.typePrefix),
  isSnLoading: selectLoading(state, getListingSerialNumber.typePrefix),
  summaryCount: state.serialNumber.summaryCount,
  isSnCountLoading: selectLoading(state, getSnSummaryCount.typePrefix),
  isError: state.serialNumber.isLoadingSerialNumberError,
});

const mapDispatchToProps = (dispatch) => ({
  getListingSerialNumber: (pageSetting) =>
    dispatch(getListingSerialNumber(pageSetting)),
  updateSNStatus: (values) => dispatch(updateSNStatus(values)),
  fetchTableLength: (values) => dispatch(fetchTableLength(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingContainer);
