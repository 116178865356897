import React, { useState } from "react";
import MarketplaceFormComponent from "./marketplaceForm.component";
import PropTypes from "prop-types";
import {
  generateId,
  createMarketplaceContentModel,
  isUrlValid
} from "../../../utils/productPage.util";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getLang } from "app/feature/constants";

const TYPES = ["new", "edit", "readonly"];
const MAX_MARKETPLACE_COUNT = 10;

function getInitialOrder(order) {
  const length = order.length;

  if (!length || length < MAX_MARKETPLACE_COUNT)
    return [...order, "placeholder"];
  else return order;
}

const getIconSrc = marketplace =>
  `${process.env.REACT_APP_S3_BUCKET_URL}/default/marketplace-icon/${marketplace.toLowerCase()}-icon.png`;

function MarketplaceFormContainer({
  type,
  handleResult,
  initialValues,
  handleDelete,
  handleClose
}) {
  useEffect(() => {
    handleEnableEditing()
  })

  const lang = useSelector(state => state.constant.languages)
  const MARKETPLACES = [
    {label: getLang(lang,"label.SHOPEE"), value: "Shopee"},
    {label: getLang(lang,"label.LAZADA"), value: "Lazada"},
    {label: getLang(lang,"label.TOKOPEDIA"), value: "Tokopedia"},
    {label: getLang(lang,"label.BUKALAPAK"), value: "Bukalapak"},
    {label: getLang(lang,"label.ZILINGO"), value: "Zilingo"},
    {label: getLang(lang,"label.ZALORA"), value: "Zalora"},
    {label: getLang(lang,"label.FACEBOOK"), value: "Facebook"},
    {label: getLang(lang,"label.WHATSAPP_APP"), value: "whatsapp-app"},
    {label: getLang(lang,"label.LINE_APP"), value: "line-app"},
    {label: getLang(lang,"label.LINKEDIN_APP"), value: "linkedin-app"},
    {label: getLang(lang,"label.YOUTUBE_APP"), value: "youtube-app"},
    {label: getLang(lang,"label.TELEGRAM_APP"), value: "telegram-app"},
    {label: getLang(lang,"label.INSTAGRAM_APP"), value: "instagram-app"}, 
    {label: getLang(lang,"label.TIKTOK_APP"), value: "tiktok-app"}, 
    {label: getLang(lang,"label.TWITTER_APP"), value: "twitter-app"}, 
    {label: getLang(lang,"label.ZALO_APP"), value: "zalo-app"}, 
    {label: getLang(lang,"label.VIBER_APP"), value: "viber-app"}, 
    {label: getLang(lang,"label.WECHAT_APP"), value: "wechat-app"}, 
    {label: getLang(lang,"label.XIAOHONGSHU_APP"), value: "xiaohongshu-app"},
    {label: getLang(lang, "label.EBAY"), value: "eBay"},
    {label: getLang(lang, "label.TIKI"), value: "tiki"},
    {label: getLang(lang,"label.OTHERS"), value: "Others"} 
  ];
  //To let 'Others' at bottom, arrange the sequence alphabetically
  MARKETPLACES.sort((a, b) => {
    if (a.label === "Others") {
      return 1;
    } else if (b.label === "Others") {
      return -1;
    } else {
      return a.label.localeCompare(b.label);
    }
  });
  const [marketplaces, setMarketplaces] = useState(initialValues.marketplaces);
  const [order, setOrder] = useState(getInitialOrder(initialValues.order));
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(true);
  const [marketplaceLabel, setMarketplaceLabel] = useState(MARKETPLACES[0].label);
  const [marketplaceUrl, setMarketplaceUrl] = useState("");
  const [selectedMarketplace, setselectedMarketplace] = useState(
    MARKETPLACES[0].value
  );
  const [isMarketplaceUrlValid, setIsMarketplaceUrlValid] = useState(true);

  const handleChangeMarketplace = (e, index) => {
    if (activeIndex !== order.length - 1 && order.length !== 1) {
      const oldIndex = activeIndex;
      const newOrder = Array.from(order);

      /**
       * swapping old item in array with the new one while keeping the order
       */
      const oldId = newOrder.splice(oldIndex, 1);
      const newId = generateId(e.target.value.value, newOrder);
      newOrder.splice(oldIndex, 0, newId);

      let newMarketplaces = { ...marketplaces };
      delete newMarketplaces[oldId];

      newMarketplaces = {
        ...newMarketplaces,
        [newId]: {
          marketplace: e.target.value.value,
          label: e.target.value.label,
          destinationURL: marketplaceUrl,
          iconSrc: getIconSrc(e.target.value.value)
        }
      };

      setMarketplaces(newMarketplaces);
      setOrder(newOrder);
      setIsEditing(false);
    }
    setselectedMarketplace(e.target.value);
    setMarketplaceLabel(MARKETPLACES.find(({value}) => value === e.target.value).label);
  };

  const handleChangeMarketplaceLabel = e => {
    if (activeIndex !== order.length - 1 && order.length !== 1) {
      const oldIndex = activeIndex;
      const newOrder = Array.from(order);

      /**
       * swapping old item in array with the new one while keeping the order
       */
      const oldId = newOrder.splice(oldIndex, 1);
      const newId = generateId(selectedMarketplace, newOrder);
      newOrder.splice(oldIndex, 0, newId);

      let newMarketplaces = { ...marketplaces };
      delete newMarketplaces[oldId];

      newMarketplaces = {
        ...newMarketplaces,
        [newId]: {
          marketplace: selectedMarketplace,
          label: e.target.value,
          destinationURL: marketplaceUrl,
          iconSrc: getIconSrc(selectedMarketplace)
        }
      };

      setMarketplaces(newMarketplaces);
      setOrder(newOrder);
      setIsEditing(false);
    }
    setMarketplaceLabel(e.target.value);
  };

  const handleChangeMarketplaceUrl = e => {
    if (activeIndex !== order.length - 1 && order.length !== 1) {
      const oldIndex = activeIndex;
      const newOrder = Array.from(order);

      /**
       * swapping old item in array with the new one while keeping the order
       */
      const oldId = newOrder.splice(oldIndex, 1);
      const newId = generateId(selectedMarketplace, newOrder);
      newOrder.splice(oldIndex, 0, newId);

      let newMarketplaces = { ...marketplaces };
      delete newMarketplaces[oldId];

      newMarketplaces = {
        ...newMarketplaces,
        [newId]: {
          marketplace: selectedMarketplace,
          label: marketplaceLabel,
          destinationURL: e.target.value,
          iconSrc: getIconSrc(selectedMarketplace)
        }
      };

      setMarketplaces(newMarketplaces);
      setOrder(newOrder);
      setIsEditing(false);
    }

    const value = e.target.value;
    setMarketplaceUrl(value);

    if (isUrlValid(value) || value === "") {
      setIsMarketplaceUrlValid(true);
    } else {
      setIsMarketplaceUrlValid(false);
    }
  };

  const handleChangeTab = index => {
    setIsEditing(true)
    if (activeIndex === order.length - 1) {
      handleResetInput()
    }
    if (isEditing && index === activeIndex) {
      handleEnableEditing();
    }
    setActiveIndex(index);
  };

  const handleResetInput = () => {
    setselectedMarketplace(MARKETPLACES[0].value);
    setMarketplaceLabel(MARKETPLACES[0].label);
    setMarketplaceUrl("");
  };

  const handleEnableEditing = () => {
    const isLastItem = order.length === 1 || activeIndex === order.length - 1;
    const isMax = !order.includes("placeholder");

    if (isMax || (!isLastItem && activeIndex < order.length)) {
      const id = order[activeIndex];
      const editingMarketplace = marketplaces[id];
      const { destinationURL, label, marketplace } = editingMarketplace;
      setselectedMarketplace(MARKETPLACES.find(({value}) => value === marketplace).value);
      setMarketplaceLabel(label);
      setMarketplaceUrl(destinationURL);
    }

    setIsEditing(true);
  };

  const handleSubmit = () => {
    const marketplaceContent = {
      order: order.filter(id => id !== "placeholder"),
      marketplaces
    };
    handleResult(marketplaceContent);
  };

  const handleAddMarketplace = () => {
    const newId = generateId(selectedMarketplace, order);
    const newMarketplace = {
      marketplace: selectedMarketplace,
      label: marketplaceLabel,
      destinationURL: marketplaceUrl,
      iconSrc: getIconSrc(selectedMarketplace)
    };

    const newMarketplaces = {
      ...marketplaces,
      [newId]: newMarketplace
    };

    const newOrder = Array.from(order);
    const placeholder = newOrder.pop();
    newOrder.push(newId);

    if (newOrder.length < MAX_MARKETPLACE_COUNT) newOrder.push(placeholder);

    setOrder(newOrder);
    setMarketplaces(newMarketplaces);
    setIsEditing(false);
    handleResetInput();
    setActiveIndex(newOrder.length - 1);
  };

  const handleDeleteMarketplaceItem = id => {
    const isMax = !order.includes("placeholder");
    const newOrder = order.filter(c => c !== id);
    if (isMax) newOrder.push("placeholder");

    const newMarketplaces = { ...marketplaces };
    delete newMarketplaces[id];

    if (isEditing) {
      setIsEditing(false);
      handleResetInput();
    }

    setOrder(newOrder);
    setMarketplaces(newMarketplaces);
  };

  return (
    <MarketplaceFormComponent
      type={type}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      handleAddMarketplace={handleAddMarketplace}
      handleDeleteMarketplaceItem={handleDeleteMarketplaceItem}
      handleDelete={handleDelete}
      order={type === TYPES[2] ? initialValues.order : order}
      activeIndex={activeIndex}
      handleChangeTab={handleChangeTab}
      marketplaces={marketplaces}
      isEditing={isEditing}
      marketplaceLabel={marketplaceLabel}
      marketplaceUrl={marketplaceUrl}
      handleChangeMarketplaceLabel={handleChangeMarketplaceLabel}
      handleChangeMarketplaceUrl={handleChangeMarketplaceUrl}
      handleChangeMarketplace={handleChangeMarketplace}
      selectedMarketplace={selectedMarketplace}
      MARKETPLACES={MARKETPLACES}
      isMarketplaceUrlValid={isMarketplaceUrlValid}
      handleClose={handleClose}
      lang={lang}
    />
  );
}

MarketplaceFormContainer.defaultProps = {
  type: TYPES[0],
  initialValues: createMarketplaceContentModel()
};

MarketplaceFormContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default MarketplaceFormContainer;
