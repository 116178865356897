import React from "react";
import {
  Box,
  Button,
  Divider,
  Backdrop,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  Tabs,
} from "@material-ui/core";
import CustomTab from "components/tab";
import { Close as CloseIcon } from "@material-ui/icons";
import Dialog from "components/dialog/customDialog";
import {
  InstantLuckyDrawForm,
  InstantLuckyDrawRegForm,
  InstantLuckyDrawTemplateForm,
} from "../../form";
import PropTypes from "prop-types";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "4px 0px",
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  discardButton: {
    color: theme.palette.error.main,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  publishButton: {
    color: theme.palette.common.white,
  },
}));

function TabPanel({ children, value, index, ...other }) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ViewEditInstantLuckyDrawDialogComponent({
  hasInstantLuckyDrawEditAccessRight,
  handleClose,
  isOpen,
  handleChange,
  activeIndex,
  tabLabels,
  settingType,
  registerType,
  templateType,
  changeToUpdate,
  changeBackView,
  handleUpdate,
  luckyDrawImageFile,
  setLuckyDrawImageFile,
  instantLuckyDrawDetail,
  formRef,
  lang,
}) {
  const classes = useStyle();

  function a11yProps(index, label = "label") {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: <Typography variant="button" children={label} />,
    };
  }

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      disableEnforceFocus={true}
      disableAutoFocus={true}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      maxWidth="1100px"
    >
      <Paper elevation={0}>
        <Box className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {instantLuckyDrawDetail.name}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
      </Paper>

      <Box>
        <Box px={3}>
          <Tabs
            value={activeIndex}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {tabLabels.map((item, index) => {
              return (
                <CustomTab
                  key={index}
                  disabled={
                    (settingType !== "view" ||
                      registerType !== "view" ||
                      templateType !== "view") &&
                    activeIndex !== index
                  }
                  {...a11yProps(index, item)}
                />
              );
            })}
          </Tabs>
        </Box>
        <Divider />

        <Box>
          <TabPanel value={activeIndex} index={0}>
            <InstantLuckyDrawForm
              type={settingType}
              changeBackView={changeBackView}
              luckyDrawImageFile={luckyDrawImageFile}
              setLuckyDrawImageFile={setLuckyDrawImageFile}
            >
              {() =>
                hasInstantLuckyDrawEditAccessRight ? (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {settingType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            changeBackView(e);
                          }}
                          style={{ marginRight: "4px" }}
                          type="button"
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {settingType === "edit" ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"submit"}
                        >
                          {getLang(lang, "label.SAVE")}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"button"}
                          onClick={(e) => {
                            e.preventDefault();
                            changeToUpdate();
                          }}
                        >
                          {getLang(lang, "label.EDIT")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : null
              }
            </InstantLuckyDrawForm>
          </TabPanel>
          <TabPanel value={activeIndex} index={1}>
            <InstantLuckyDrawTemplateForm
              type={templateType}
              changeBackView={changeBackView}
            >
              {() =>
                hasInstantLuckyDrawEditAccessRight ? (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {templateType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            changeBackView(e);
                          }}
                          type="button"
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      {templateType === "edit" ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"submit"}
                        >
                          {getLang(lang, "label.SAVE")}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          classes={{ root: classes.publishButton }}
                          disableElevation
                          type={"button"}
                          onClick={(e) => {
                            e.preventDefault();
                            changeToUpdate();
                          }}
                        >
                          {getLang(lang, "label.CHANGE_TEMPLATE")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : null
              }
            </InstantLuckyDrawTemplateForm>
          </TabPanel>
          <TabPanel value={activeIndex} index={2}>
            <InstantLuckyDrawRegForm type={registerType} formRef={formRef}>
              {() =>
                hasInstantLuckyDrawEditAccessRight ? (
                  <Box mt="auto">
                    <Divider />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      px={2}
                      pt={1}
                      pb={1}
                    >
                      {registerType === "edit" ? (
                        <Button
                          onClick={(e) => {
                            changeBackView(e);
                          }}
                          type="button"
                          style={{ marginRight: "4px" }}
                        >
                          {getLang(lang, "label.CANCEL")}
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        classes={{ root: classes.publishButton }}
                        disableElevation
                        type={"button"}
                        onClick={
                          registerType === "edit"
                            ? handleUpdate
                            : changeToUpdate
                        }
                      >
                        {registerType === "edit"
                          ? getLang(lang, "label.SAVE")
                          : getLang(lang, "label.EDIT")}
                      </Button>
                    </Box>
                  </Box>
                ) : null
              }
            </InstantLuckyDrawRegForm>
          </TabPanel>
        </Box>
      </Box>
    </Dialog>
  );
}
