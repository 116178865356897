import axios from "axios"

export function updateDealerUpline({
  dealerUuid,
  targetDealerUuid,
}) {
  return new Promise((resolve, reject) => {
    axios.put(`/distributor/admin/api/v1/dealers/${dealerUuid}/upline`, {
      target_dealer_uuid: targetDealerUuid,
    })
      .then(response => resolve(response))
      .catch(error => reject(error.response));
  });
}
