/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParticipantInfoFormComponent from "./participantInfoForm.component";
import luckyDrawV2Api from "app/api/luckyDrawV2";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { defaultParticipantFormFields } from "modules/lucky-draw-v2/utils/constants";
import { progressStatus } from "lib/constants/luckyDrawProgress";

export default function ParticipantInfoFormContainer({
  uuid,
  initialValues,
  setCurrentStep,
  getLuckyDrawDetail,
  type,
  currentStep,
  progress,
  ldCurrentState,
  prizeInfo,
}) {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddQuestionOpen, setIsAddQuestionOpen] = useState(false);

  const formRef = useRef();

  useEffect(() => {
    if (initialValues && formRef.current) {
      let isNewlyCreated =
        progress &&
        (progress === progressStatus.setPrize ||
          progress === progressStatus.setPeriod);
      let signInRequired = isNewlyCreated
        ? 1
        : initialValues
        ? Number(initialValues.signInRequired)
        : 1;
      let submitFormRequired = isNewlyCreated
        ? 1
        : initialValues
        ? Number(initialValues.submitFormRequired)
        : 1;
      let formFields = initialValues?.formFields?.length
        ? initialValues.formFields.map((f, index) => ({
            ...f,
            id: `item-${Date.now()}-${index}`,
          }))
        : defaultParticipantFormFields.map((f, index) => ({
            ...f,
            id: `item-${Date.now()}-${index}`,
          }));

      let initialJourney = [
        {
          identifier: "start",
          title: getLang(lang, "label.START"),
        },
        {
          identifier: "lucky-draw",
          title: getLang(
            lang,
            type === 2 ? "label.INSTANT_WIN" : "label.LUCKY_DRAW"
          ),
        },
        {
          identifier: "winning",
          title: getLang(lang, "label.WINNING"),
        },
        {
          identifier: "claim",
          title: getLang(lang, "label.CLAIM_PRIZE"),
        },
      ];

      if (isNewlyCreated || signInRequired) {
        if (isNewlyCreated || Number(initialValues?.signInEvent) === 1) {
          // before joining lucky draw
          let startIndex = initialJourney.findIndex(
            (j) => j.identifier === "start"
          );
          if (startIndex >= 0) {
            initialJourney.splice(startIndex + 1, 0, {
              identifier: "login",
              title: getLang(lang, "label.LOGIN_LABEL"),
            });
          }
        } else if (Number(initialValues?.signInEvent) === 2) {
          // before claim prize
          let winningIndex = initialJourney.findIndex(
            (j) => j.identifier === "winning"
          );
          if (winningIndex >= 0) {
            initialJourney.splice(winningIndex + 1, 0, {
              identifier: "login",
              title: getLang(lang, "label.LOGIN_LABEL"),
            });
          }
        }
      }

      if (isNewlyCreated || submitFormRequired) {
        if (isNewlyCreated || Number(initialValues?.submitFormEvent) === 1) {
          // before joining lucky draw
          let ldIndex = initialJourney.findIndex(
            (j) => j.identifier === "lucky-draw"
          );
          if (ldIndex >= 0) {
            initialJourney.splice(ldIndex, 0, {
              identifier: "submit-form",
              title: getLang(lang, "label.SUBMIT_FORM"),
            });
          }
        } else if (Number(initialValues?.submitFormEvent) === 2) {
          // before claim prize
          let claimIndex = initialJourney.findIndex(
            (j) => j.identifier === "claim"
          );
          if (claimIndex >= 0) {
            initialJourney.splice(claimIndex, 0, {
              identifier: "submit-form",
              title: getLang(lang, "label.SUBMIT_FORM"),
            });
          }
        }
      }

      formRef.current.setValues({
        formFields: formFields,
        signInRequired: isNewlyCreated ? 1 : signInRequired,
        submitFormRequired: isNewlyCreated ? 1 : submitFormRequired,
        signInEvent: isNewlyCreated ? "1" : initialValues?.signInEvent ?? "",
        submitFormEvent: isNewlyCreated
          ? "1"
          : initialValues?.submitFormEvent ?? "",
        journey: initialJourney,
        showConfirmDialog: false,
      });
    }
  }, [initialValues]);

  const handleSubmit = (values) => {
    let isNewlyCreated =
      progress &&
      [progressStatus.setPeriod, progressStatus.setPrize].includes(progress);

    if (
      (values.isEditing &&
        (!ldCurrentState ||
          (ldCurrentState !== progressStatus.ended &&
            ldCurrentState !== progressStatus.drawStart))) ||
      isNewlyCreated
    ) {
      setIsSubmitting(true);
      const body = {
        type: type,
        luckyDrawUuid: uuid,
        signInRequire: values.signInRequired,
        signInEvent: values.signInEvent || null,
        submitFormRequire: values.submitFormRequired,
        submitFormEvent: values.submitFormEvent || null,
        formFields: values.formFields.map((f, index) => ({
          ...f,
          sequence: index + 1,
        })),
      };

      luckyDrawV2Api
        .updateParticipantForm(body)
        .then(() => {
          getLuckyDrawDetail(uuid);

          const params = new URLSearchParams(window.location.search);
          params.set("step", currentStep + 2);
          window.history.replaceState(null, null, `?${params}`);
          setCurrentStep(currentStep + 1);
        })
        .catch((error) => {
          dispatch(
            addAlert({
              severity: "error",
              message: getApiLang(lang, error.code),
            })
          );
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.set("step", currentStep + 2);
      window.history.replaceState(null, null, `?${params}`);
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = (hasPrize) => {
    const params = new URLSearchParams(window.location.search);
    let tempStep = currentStep;
    if (type === 2 && !hasPrize && currentStep === 4) tempStep--;
    params.set("step", tempStep);
    window.history.replaceState(null, null, `?${params}`);
    setCurrentStep(tempStep - 1);
  };

  const handleOnDragEnd = (source, destination, formik) => {
    // Dropped outside the list
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const items = Array.from(formik.values.formFields);
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);
      formik.setFieldValue("formFields", items);

      if (!formik.values.isEditing) {
        formik.setFieldValue("isEditing", true);
      }
    }
  };

  const handleIsAddQuestionOpen = (value) => {
    setIsAddQuestionOpen(value);
  };

  const options = [
    {
      label: getLang(lang, "label.NAME"),
      variantName: "Name",
      fieldName: "name",
    },
    {
      label: getLang(lang, "label.EMAIL"),
      variantName: "Email",
      fieldName: "email",
    },
    {
      label: getLang(lang, "label.CONTACT_NUMBER"),
      variantName: "Contact Number",
      fieldName: "contactNumber",
    },
    {
      label: getLang(lang, "label.ADDRESS"),
      variantName: "Address",
      fieldName: "address",
    },
    {
      label: getLang(lang, "label.GENDER"),
      variantName: "Gender",
      fieldName: "gender",
    },
    {
      label: getLang(lang, "label.PROOF_OF_PURCHASE"),
      variantName: "Proof of Purchase",
      fieldName: "proofOfPurchase",
    },
    {
      label: getLang(lang, "label.PURCHASE_LOCATION"),
      variantName: "Purchase Location",
      fieldName: "purchaseLocation",
    },
    {
      label: getLang(lang, "label.CUSTOM_INPUT_FIELDS"),
      variantName: "Custom Input Fields",
      fieldName: "customInputFields",
    },
  ];

  return (
    <ParticipantInfoFormComponent
      uuid={uuid}
      initialValues={initialValues}
      setCurrentStep={setCurrentStep}
      lang={lang}
      handleSubmitForm={handleSubmit}
      isSubmitting={isSubmitting}
      formRef={formRef}
      handlePrevious={handlePrevious}
      handleOnDragEnd={handleOnDragEnd}
      type={type}
      ldCurrentState={ldCurrentState}
      progress={progress}
      options={options}
      isAddQuestionOpen={isAddQuestionOpen}
      handleIsAddQuestionOpen={handleIsAddQuestionOpen}
      prizeInfo={prizeInfo}
    />
  );
}
