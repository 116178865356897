import React, { useState } from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Paper,
  Typography,
  Divider,
  Button,
  ButtonBase,
  Tooltip,
  InputAdornment,
  MenuItem,
  Menu,
  Grid,
  FormHelperText,
  Backdrop,
  Modal,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DateTimePicker from "components/input/dateTimePicker";
import InputTextField from "components/input/inputTextField";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "moment/min/locales.min";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import clsx from "clsx";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import { AddImageSvg } from "modules/product-mgmt/utils/staticSvg";
import CloseIcon from "@material-ui/icons/Close";
import { isFile, isFileExceedLimit, isImage } from "lib/helper";
import { convertToBigNum } from "lib/generalUtility";
import InputHelper from "lib/InputHelper";
import { getLang } from "app/feature/constants";
import { addAlert } from "modules/notification";
import { useDispatch } from "react-redux";
import ImageEditorPanel from "../../../../../components/panel/landingPageImageEditorPanel";

const useStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  otherQtySelectButton: {
    width: 42,
    height: 42,
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  gridFieldPeriod: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dividerContainer: {
    backgroundColor: "#ECEFF0",
    display: "flex",
  },
  dateView: {
    paddingRight: theme.spacing(1),
  },
  dateContainer: {
    height: 43,
    backgroundColor: "#ECEFF0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    borderRadius: "4px 0px 0px 4px",
  },
  dividerEdit: {
    height: "70%",
    display: "flex",
    alignSelf: "center",
  },
  button: {
    marginBottom: "4px",
    boxShadow: "none",
    fontSize: "14px",
    fontFamily: "roboto",
    color: "#FD646F",
    width: "100%",
    borderRadius: 4,
    display: "flex",
    placeContent: "start",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#ECEFF0",
      boxShadow: "none",
    },
  },
  photoContainer: {
    width: "150px",
    height: "150px",
    marginBottom: "25px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      border: "1px solid #0000001A",
    },
  },
  detailsWrapper: {
    padding: "0px 20px 20px 20px",
  },
  divider: {
    margin: "6px 0px",
  },
  photo: {
    padding: 0,
    marginRight: 20,
    alignItems: "center",
    minWidth: 0,
    cursor: "pointer",
  },
  imageTab: {
    width: 130,
    height: 130,
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(2, 0),
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #0000001A",
    },
  },
  removeButton: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: "50%",
    position: "absolute",
    zIndex: 1,
    bottom: 6,
    right: 6,
  },
}));

export default function GiftDetailsFormComponent({
  hasGiftAddAccessRight,
  hasGiftEditAccessRight,
  gift,
  language,
  handleClose,
  handleSubmit,
  giftType,
  changeToUpdate,
  handleDelete,
  headerType,
  lang,
  changeBackView,
  isCropping,
  handleCloseCropping,
  handleOpenCropping,
}) {
  const dispatch = useDispatch();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const viewType = giftType === "view" ? true : false;

  const renderInputField = (field, index, formik) => {
    switch (field.type) {
      case "number":
        return (
          <Grid item xs={4} sm={4} md={4}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {convertToBigNum(formik.getFieldProps(field.name).value)}
              </Typography>
            ) : (
              <InputTextField
                disabled={formik.isSubmitting}
                required
                variant="filled"
                size="small"
                fullWidth
                type={field.type}
                onPaste={(e) =>
                  InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)
                }
                onKeyDown={(e) =>
                  InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)
                }
                inputProps={{
                  min: 1,
                }}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
                InputProps={
                  field.end && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box px={1} borderLeft="1px solid rgba(0, 0, 0, 0.1)">
                          <Typography variant="body2" color="textSecondary">
                            {getLang(lang, "label.PTS")}
                          </Typography>
                        </Box>
                      </InputAdornment>
                    ),
                  }
                }
              />
            )}
          </Grid>
        );
      case "date":
        return [
          {
            label: getLang(lang, "label.FROM"),
            date: "startAt",
          },
          {
            label: getLang(lang, "label.TO"),
            date: "endAt",
          },
        ].map((field, index) => (
          <Grid
            item
            xs={7}
            sm={4}
            md={4}
            key={index}
            className={classes.gridFieldPeriod}
          >
            <Box display="flex">
              <Box
                className={clsx({
                  [classes.dateView]: viewType,
                  [classes.dateContainer]: !viewType,
                })}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: "bold",
                    color: " rgba(58, 77, 84, 0.33)",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {field.label}
                </Typography>
              </Box>
              <Box className={classes.dividerContainer}>
                <Divider
                  orientation="vertical"
                  className={clsx({
                    [classes.dividerEdit]: !viewType,
                  })}
                />
              </Box>
            </Box>

            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingLeft: "16px",
                }}
              >
                {moment(formik.getFieldProps(field.date).value).format("lll")}
              </Typography>
            ) : (
              <DateTimePicker
                minDate={
                  field.label === getLang(lang, "label.TO")
                    ? moment(formik.values.startAt)
                    : new Date()
                }
                required
                label=""
                disabled={
                  formik.isSubmitting ||
                  (field.label === getLang(lang, "label.FROM") &&
                    moment(gift.startAt).isBefore(moment()))
                }
                disableFuture={false}
                id={field.label + "-picker-dialog"}
                value={formik.getFieldProps(field.date).value}
                handleChange={(v) => {
                  formik.setFieldValue(
                    field.date,
                    v.format("YYYY-MM-DD HH:mm")
                  );
                  if (
                    field.date === "startAt" &&
                    moment(v).isSameOrAfter(moment(formik.values.endAt))
                  ) {
                    formik.setFieldValue(
                      "endAt",
                      moment(v).add(1, "minutes").format("YYYY-MM-DD HH:mm")
                    );
                  }

                  if (
                    field.date === "endAt" &&
                    moment(v).isSameOrBefore(moment(formik.values.startAt))
                  ) {
                    formik.setFieldValue(
                      "endAt",
                      moment(formik.values.startAt)
                        .add(1, "minutes")
                        .format("YYYY-MM-DD HH:mm")
                    );
                  }
                }}
                InputPropStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            )}
          </Grid>
        ));
      case "text":
      case "paragraph":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {formik.getFieldProps(field.name).value}
              </Typography>
            ) : (
              <InputTextField
                required
                variant="filled"
                size="small"
                disabled={formik.isSubmitting}
                fullWidth
                multiline={field.type === "paragraph"}
                rows={field.type === "paragraph" ? 3 : null}
                placeholder={field.label}
                {...formik.getFieldProps(field.name)}
                error={
                  formik.touched[field.name] && formik.errors[field.name]
                    ? true
                    : false
                }
                helperText={ErrorMessage({
                  name: field.name,
                })}
              />
            )}
          </Grid>
        );
      case "image":
        return (
          <Box>
            {viewType ? (
              <Box className={classes.photoContainer}>
                <img src={gift.imgUrl} alt="giftImage" />
              </Box>
            ) : (
              <>
                <input
                  accept="image/*"
                  className={classes.input}
                  id={"contained-button-file" + index}
                  type="file"
                  onChange={(e) => {
                    e.persist();
                    // formik.setFieldValue(field.name, e.currentTarget.files[0])
                    if (e.currentTarget.files.length > 0) {
                      if (
                        isFileExceedLimit(
                          e.currentTarget.files[0].size,
                          12,
                          "MB"
                        )
                      ) {
                        dispatch(
                          addAlert({
                            severity: "error",
                            message: getLang(
                              lang,
                              "message.error.FORM_VALIDATE_FILE_SIZE",
                              { value: 12 }
                            ),
                          })
                        );
                        return;
                      }
                      formik.setFieldValue(
                        "cropping",
                        e.currentTarget.files[0]
                      );
                      handleOpenCropping();
                    }
                    e.target.value = null;
                  }}
                />
                <Box disabled={formik.isSubmitting}>
                  <Box className={clsx(classes.photo)}>
                    <label htmlFor={"contained-button-file" + index}>
                      <Box className={classes.imageTab}>
                        {formik.getFieldProps(field.name).value !== "" ? (
                          <>
                            <img
                              src={
                                formik.getFieldProps(field.name).value
                                  .length === undefined &&
                                isFile(formik.getFieldProps(field.name).value)
                                  ? URL.createObjectURL(
                                      formik.getFieldProps(field.name).value
                                    )
                                  : formik.getFieldProps(field.name).value
                              }
                              alt="poster"
                            />
                            <ButtonBase
                              className={classes.removeButton}
                              onClick={(e) => handleDelete(formik, e)}
                            >
                              <CloseIcon fontSize="small" />
                            </ButtonBase>
                          </>
                        ) : (
                          <AddImageSvg />
                        )}
                      </Box>
                    </label>
                    <FormHelperText
                      error={formik.errors[field.name] ? true : false}
                    >
                      {ErrorMessage({ name: field.name })}
                    </FormHelperText>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ReportProblemOutlinedIcon fontSize="small" color="error" />
                    &ensp;
                    <Typography color="textSecondary" variant="caption">
                      {getLang(
                        lang,
                        "paragraph.RECOMMENDED_IMAGE_SIZE_CUSTOM",
                        {
                          resolution: "1024(w)px X 1024(h)px",
                          size: 12,
                          type: "JPG, JPEG & PNG",
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Modal
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  open={isCropping}
                  onClose={handleCloseCropping}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                >
                  {isCropping && (
                    <Box>
                      <ImageEditorPanel
                        isCircleRadius={false}
                        height={375}
                        width={375}
                        aspectRatio={[1, 1]}
                        img={
                          isFile(formik.values.cropping)
                            ? URL.createObjectURL(formik.values.cropping)
                            : formik.values.cropping
                        }
                        handleCancel={handleCloseCropping}
                        handleResult={async (value) => {
                          const newFileName =
                            formik.values.cropping.name.split(".");
                          newFileName.pop();
                          newFileName.push("webp");

                          const file = await new File(
                            [await (await fetch(value)).blob()],
                            newFileName.join("."),
                            { type: "image/webp" }
                          );

                          formik.setFieldValue("imgUrl", file);
                          handleCloseCropping();
                        }}
                      />
                    </Box>
                  )}
                </Modal>
              </>
            )}
          </Box>
        );
      case "select":
        return (
          <Grid item xs={7} sm={8} md={8}>
            {viewType ? (
              <Typography
                variant="body2"
                color="primary"
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {formik.getFieldProps(field.name).value === 0
                  ? getLang(lang, "label.NO_LIMIT")
                  : formik.getFieldProps(field.name).value}
              </Typography>
            ) : giftOption >= 2 ? (
              <Box display="flex" alignItems="center" justifyItems="flex-end">
                <Typography
                  variant="body2"
                  color="primary"
                  style={{
                    fontWeight: "bold",
                    marginRight: "auto",
                  }}
                >
                  {getLang(lang, "label.OTHER_QUANTITY")}
                </Typography>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row">
                    <InputTextField
                      variant="filled"
                      size="small"
                      margin="dense"
                      type="number"
                      disabled={formik.isSubmitting}
                      color="primary"
                      placeholder={field.label}
                      onPaste={(e) =>
                        InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(
                          e
                        )
                      }
                      onKeyDown={(e) =>
                        InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(
                          e
                        )
                      }
                      {...formik.getFieldProps("itemPerCustomer")}
                      inputProps={{
                        min: 2,
                      }}
                      error={
                        formik.touched.itemPerCustomer &&
                        formik.errors.itemPerCustomer
                          ? true
                          : false
                      }
                    />
                    <ButtonBase
                      className={classes.otherQtySelectButton}
                      onClick={handleClickOtherQtySelect}
                    >
                      <ArrowDropDownIcon fontSize="small" />
                    </ButtonBase>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClickOtherQtyMenuItem();
                          formik.setFieldValue("itemPerCustomer", 0);
                          setGiftOption(0);
                        }}
                      >
                        {getLang(lang, "label.NO_LIMIT")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClickOtherQtyMenuItem();
                          formik.setFieldValue("itemPerCustomer", 1);
                          setGiftOption(1);
                        }}
                      >
                        1
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClickOtherQtyMenuItem();
                          formik.setFieldValue("itemPerCustomer", 2);
                          setGiftOption(2);
                        }}
                      >
                        {getLang(lang, "label.OTHER_QUANTITY")}
                      </MenuItem>
                    </Menu>
                  </Box>
                  <FormHelperText
                    error={formik.errors.itemPerCustomer ? true : false}
                  >
                    {ErrorMessage({ name: "itemPerCustomer" })}
                  </FormHelperText>
                </Box>
              </Box>
            ) : (
              <InputTextField
                variant="filled"
                size="small"
                fullWidth
                margin="dense"
                color="primary"
                disabled={formik.isSubmitting}
                placeholder="Enter points required here"
                {...formik.getFieldProps("itemPerCustomer")}
                select
                hiddenLabel
              >
                <MenuItem value={0} onClick={() => setGiftOption(0)}>
                  {getLang(lang, "label.NO_LIMIT")}
                </MenuItem>
                <MenuItem value={1} onClick={() => setGiftOption(1)}>
                  1
                </MenuItem>
                <MenuItem value={2} onClick={() => setGiftOption(2)}>
                  {getLang(lang, "label.OTHER_QUANTITY")}
                </MenuItem>
              </InputTextField>
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [giftOption, setGiftOption] = useState(gift.itemPerCustomer);

  const handleClickOtherQtySelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOtherQtyMenuItem = () => {
    setAnchorEl(null);
  };
  return (
    <Paper elevation={0} className={classes.root}>
      <Box
        py={1}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body1"
          color="primary"
          style={{ fontWeight: "bold" }}
        >
          {headerType === "view"
            ? getLang(lang, "label.VIEW")
            : headerType === "edit"
            ? getLang(lang, "label.EDIT")
            : getLang(lang, "label.CREATE")}{" "}
          {getLang(lang, "label.GIFT_LOWERCASE")}
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Divider />
      <Formik
        initialValues={{
          ...gift,
          id: gift.id || "",
          name: gift.name || "",
          description: gift.description || "",
          quantity: gift.remainQuantity || "",
          point: gift.point || "",
          imgUrl: gift.imgUrl || "",
          itemPerCustomer: gift.itemPerCustomer || 0,
          startAt: gift.startAt
            ? gift.startAt
            : new moment().format("YYYY-MM-DD HH:mm"),
          endAt: gift.endAt
            ? gift.endAt
            : new moment().add(7, "day").format("YYYY-MM-DD HH:mm"),
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .trim()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          // imgUrl: Yup.string().required(displayFormMessage(lang.FORM_VALIDATE_REQUIRED)),
          imgUrl: Yup.lazy((value) => {
            if (value instanceof File) {
              return Yup.mixed()
                .test(
                  "fileSize",
                  getLang(lang, "message.error.FILE_TOO_LARGE"),
                  (v) => {
                    return !isFileExceedLimit(v.size, 10, "MB");
                  }
                )
                .test(
                  "fileType",
                  getLang(lang, "message.error.FILE_NOT_IMAGE"),
                  (v) => {
                    return isImage(v);
                  }
                );
            } else {
              return Yup.string().required(
                getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
              );
            }
          }),
          description: Yup.string()
            .trim()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          quantity: Yup.number()
            .min(
              1,
              getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
                value: 1,
              })
            )
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          point: Yup.number()
            .min(
              1,
              getLang(lang, "message.error.FORM_VALIDATE_MIN_NUMBER", {
                value: 1,
              })
            )
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          itemPerCustomer: Yup.number()
            .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
        })}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Box
              className={classes.detailsWrapper}
              style={{ flexDirection: "row", alignItems: "stretch" }}
            >
              {[
                {
                  title: getLang(lang, "label.PHOTO"),
                  type: "photo",
                },
                {
                  title: getLang(lang, "label.DETAILS"),
                  type: "detail",
                },
                {
                  title: getLang(lang, "label.REDEMPTION_DETAILS"),
                  type: "redemption",
                },
              ].map((field, index) => (
                <Box key={index}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ winth: "100%" }}
                    >
                      <Box my={2} display="flex">
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontWeight: "bold", marginRight: 5 }}
                        >
                          {field.title}
                        </Typography>
                        {field.tooltip && (
                          <Tooltip title={field.tooltip} arrow>
                            <HelpOutlinedIcon
                              color="primary"
                              fontSize="small"
                            />
                          </Tooltip>
                        )}
                      </Box>
                      {field.type === "photo" ? (
                        [
                          {
                            name: "imgUrl",
                            type: "image",
                          },
                        ].map((field, index) => (
                          <Grid key={index} item xs={12} sm={12} md={12}>
                            {renderInputField(field, index, formik)}
                            <Divider className={classes.divider} />
                          </Grid>
                        ))
                      ) : field.type === "detail" ? (
                        <>
                          {[
                            {
                              title: getLang(lang, "label.GIFT_NAME"),
                              name: "name",
                              label: getLang(
                                lang,
                                "placeholder.ENTER_ITEM_NAME"
                              ),
                              type: "text",
                            },
                            {
                              title: getLang(lang, "label.DESCRIPTION"),
                              name: "description",
                              label: getLang(
                                lang,
                                "placeholder.ENTER_DESCRIPTION"
                              ),
                              type: "paragraph",
                            },
                          ].map((field, index) => (
                            <Grid
                              container
                              key={index}
                              spacing={1}
                              style={{ alignItems: "center" }}
                            >
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography variant="body2" color="primary">
                                  {field.title}
                                  {headerType === "view" ? null : (
                                    <Typography
                                      style={{
                                        color: "#DE350B",
                                        display: "inline-block",
                                      }}
                                    >
                                      &nbsp;*
                                    </Typography>
                                  )}
                                </Typography>
                              </Grid>
                              {renderInputField(field, index, formik)}
                            </Grid>
                          ))}
                          <Divider className={classes.divider} />
                        </>
                      ) : (
                        [
                          {
                            title: getLang(lang, "label.PERIOD"),
                            type: "date",
                            tooltip: getLang(
                              lang,
                              "tooltips.GIFT_REDEEMABLE_PERIOD"
                            ),
                          },
                          {
                            title: getLang(lang, "label.GIFT_PER_CUSTOMER"),
                            name: "itemPerCustomer",
                            label: "",
                            type: "select",
                          },
                          {
                            title: getLang(lang, "label.POINT_REQUIRED"),
                            name: "point",
                            label: "",
                            type: "number",
                            end: true,
                            tooltip: getLang(
                              lang,
                              "tooltips.POINT_IS_REQUIRED"
                            ),
                          },
                          {
                            title: getLang(lang, "label.STOCK_QUANTITY"),
                            name: "quantity",
                            label: getLang(lang, "placeholder.ENTER_AMOUNT"),
                            type: "number",
                          },
                        ].map((field, index) => (
                          <Grid
                            container
                            key={index}
                            spacing={1}
                            style={{ alignItems: "center" }}
                          >
                            <Grid item xs={4} sm={4} md={4}>
                              <Box display="flex">
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  style={{ marginRight: 5 }}
                                >
                                  {field.title}
                                  {headerType === "view" ? null : (
                                    <Typography
                                      style={{
                                        color: "#DE350B",
                                        display: "inline-block",
                                      }}
                                    >
                                      &nbsp;*
                                    </Typography>
                                  )}
                                </Typography>
                                {field.tooltip && (
                                  <Tooltip title={field.tooltip} arrow>
                                    <HelpOutlinedIcon
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                            </Grid>
                            {renderInputField(field, index, formik)}
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
            {(headerType === "create" && hasGiftAddAccessRight) ||
            (headerType === "view" && hasGiftEditAccessRight) ||
            (headerType === "edit" && hasGiftEditAccessRight) ? (
              <>
                <Divider />
                <Box p={2} display="flex" justifyContent="flex-end">
                  {!viewType ? (
                    <Box display="flex" alignItems="center">
                      <Button
                        onClick={(e) => {
                          formik.resetForm();
                          headerType === "edit"
                            ? changeBackView(e)
                            : handleClose(e);
                        }}
                        disabled={formik.isSubmitting}
                        type="button"
                        style={{ marginRight: "4px" }}
                      >
                        {getLang(lang, "label.CANCEL")}
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )}
                  <Box>
                    <Button
                      disabled={formik.isSubmitting}
                      variant="contained"
                      color="secondary"
                      disableElevation
                      type={viewType ? "button" : "submit"}
                      onClick={viewType ? changeToUpdate : null}
                    >
                      {giftType === "create"
                        ? formik.isSubmitting
                          ? getLang(lang, "label.ADDING")
                          : getLang(lang, "label.ADD")
                        : giftType === "edit"
                        ? getLang(lang, "label.SAVE")
                        : getLang(lang, "label.EDIT")}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : null}
          </form>
        )}
      </Formik>
    </Paper>
  );
}
