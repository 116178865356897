import React from "react";

export default function UnknownFlagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="16px"
      viewBox="0 0 24 16"
      version="1.1"
    >
      <g id="surface1">
        <path
          style={{
            stroke: "none",
            fillRule: "evenodd",
            fill: "rgb(44.705882%,43.921569%,43.529412%)",
            fillOpacity: 1,
          }}
          d="M 0 0 L 8 0 L 8 16 L 0 16 Z M 0 0 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "evenodd",
            fill: "rgb(76.078431%,75.686275%,75.686275%)",
            fillOpacity: 1,
          }}
          d="M 8 0 L 16 0 L 16 16 L 8 16 Z M 8 0 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "evenodd",
            fill: "rgb(58.823529%,58.431373%,58.039216%)",
            fillOpacity: 1,
          }}
          d="M 16 0 L 24 0 L 24 16 L 16 16 Z M 16 0 "
        />
        <path
          style={{
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
            strokeWidth: 0.1524,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "rgb(0%,0%,0%)",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M 348.339844 270.410156 L 254.6875 270.410156 L 254.6875 261.035156 C 254.6875 245.117188 256.445312 232.128906 260.058594 222.265625 C 263.671875 212.207031 269.042969 203.222656 276.171875 194.921875 C 283.398438 186.71875 299.414062 172.265625 324.414062 151.464844 C 337.792969 140.625 344.433594 130.664062 344.433594 121.679688 C 344.433594 112.597656 341.699219 105.664062 336.425781 100.585938 C 331.054688 95.605469 322.949219 93.066406 312.109375 93.066406 C 300.390625 93.066406 290.820312 96.972656 283.105469 104.6875 C 275.488281 112.402344 270.605469 125.878906 268.457031 145.117188 L 172.753906 133.203125 C 176.074219 98.046875 188.867188 69.726562 211.035156 48.242188 C 233.398438 26.855469 267.578125 16.113281 313.574219 16.113281 C 349.414062 16.113281 378.320312 23.632812 400.390625 38.476562 C 430.273438 58.789062 445.3125 85.742188 445.3125 119.433594 C 445.3125 133.398438 441.40625 146.972656 433.691406 159.960938 C 425.976562 172.851562 410.15625 188.769531 386.328125 207.519531 C 369.726562 220.703125 359.179688 231.25 354.882812 239.257812 C 350.585938 247.167969 348.339844 257.617188 348.339844 270.410156 Z M 251.367188 295.3125 L 351.855469 295.3125 L 351.855469 383.886719 L 251.367188 383.886719 Z M 251.367188 295.3125 "
          transform="matrix(0.04,0,0,0.04,0,0)"
        />
      </g>
    </svg>
  );
}
