import React, { useEffect } from "react";
import StructureOverviewPanelComponent from "./structureOverviewPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganisationStructureDetails } from '../../../redux/organisationStructure.action'

export default function StructureOverviewPanelContainer() {
  const dispatch = useDispatch();
  const isOrganisationStructureLoading = useSelector(state => state.organisationStructure.isOrganisationStructureLoading)
  const organisationStructure = useSelector(state => state.organisationStructure.organisationStructure)
  const lang = useSelector((state) => state.constant.languages);
  useEffect(() =>{
    dispatch(fetchOrganisationStructureDetails({}))
  }, [dispatch])

  return (
    <StructureOverviewPanelComponent 
      isOrganisationStructureLoading={isOrganisationStructureLoading}
      organisationStructure={organisationStructure}
      lang={lang}
    />
  );
}
