import React, { useState } from "react";
import {
  makeStyles,
  Box,
  IconButton,
  Dialog,
  Typography,
  Backdrop,
  Link,
  Divider,
  Button,
} from "@material-ui/core";
import {
  CloseRounded as CloseRoundedIcon,
  OpenInNewRounded as OpenInNewRoundedIcon,
  CropFree as CropFreeIcon,
} from "@material-ui/icons";
import ReportWarningIcon from "assets/img/reportWarning.png";
import { getLang } from "app/feature/constants";
import { ErrorMessage, Formik } from "formik";
import DashboardSingleScanMap from "../../map/dashboardSingleScanMap";
import LoadingComponent from "components/loading";
import { ROOT as SERIAL_NUMBER_ROOT } from "modules/serial-number";
import { ROOT as CONSUMER_ROOT } from "modules/consumer";
import DefaultImg from "assets/img/img-default.png";
import DefaultProfile from "assets/img/defaultPhoto.png";
import MarkerIcon from "assets/img/markerIcon.png";
import clsx from "clsx";
import moment from "moment";
import InputTextField from "components/input/inputTextField";
import * as Yup from "yup";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import RefreshTable from "components/table/refreshTable";
import "moment/min/locales.min";

const useStyle = makeStyles((theme) => ({
  header: {
    padding: "12px 16px",
    borderBottom: "1px solid #D0D5DD",
  },
  container: {
    backgroundColor: "#E5E5E5",
    overflowY: "auto",
  },
  content: {
    background: "linear-gradient(240.53deg, #EFF4FF 5.63%, #FFFFFF 62.12%)",
    borderRadius: 9,
    boxShadow: "1px 1px 10px 4px #0000001a",
    padding: "10px 20px 20px",
    marginTop: 0,
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      padding: "10px 8px 20px",
    },
  },
  floatBar: {
    marginTop: -80,
    backgroundColor: "#ffffffd1",
    padding: "8px 24px 8px 12px",
    boxShadow: "1px 1px 9px 2px #0000001f",
    borderRadius: 8,
    flexWrap: "noWrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      "& div:last-child": {
        marginTop: 8,
      },
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #F2F4F7",
    width: 110,
    height: 110,
    borderRadius: 15,
    marginRight: 16,
    minWidth: 110,
    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: 20,
    },
  },
  scanCountContainer: {
    backgroundColor: "white",
    padding: "8px 28px 8px 32px",
    borderRadius: 4,
    boxShadow: "1px 2px 6px 1px #00000024",
  },
  statusBox: {
    position: "relative",
    width: 160,
    height: 75,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    zIndex: 1,
    marginLeft: -10,
    "&::after": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      transform: "skew(-15deg)",
      zIndex: -1,
      borderRadius: 5,
    },
  },
  activeStatusBox: {
    "&::after": {
      background: "linear-gradient(180deg, #50EC9E 0%, #32D583 50%)",
    },
  },
  suspendStatusBox: {
    "&::after": {
      background: "linear-gradient(180deg, #F97066 0%, #D92D20 100%)",
    },
  },
  profilePhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    width: "50px",
    minWidth: "50px",
    height: "50px",
    marginRight: "10px",
    borderRadius: "50%",
    "& img": {
      height: "100%",
      width: "100%",
      borderRadius: "50%",
    },
  },
  openNewTab: {
    color: "#98A2B3",
    marginLeft: 8,
    fontSize: 19,
    cursor: "pointer",
  },
  icon: {
    height: 40,
    width: 40,
    marginRight: 10,
    objectFit: "contain",
  },
  locationBox: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: "8px 12px",
    boxShadow: "0px 4px 4px 0px #0000001A",
    maxWidth: "40%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: 10,
    },
  },
  methodBar: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    columnGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  box: {
    border: "1px solid #D0D5DD",
    backgroundColor: "white",
    borderRadius: 4,
    "& div:first-child": {
      backgroundColor: "#F2F4F7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
    },
    "& div:last-child": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 65,
    },
  },
  reportSection: {
    border: "4px solid #D92D20",
    marginTop: 30,
    borderRadius: 8,
  },
  reportHeader: {
    background: "linear-gradient(180deg, #F97066 0%, #D92D20 100%)",
    padding: 8,
    color: "white",
    "& img": {
      height: 35,
      width: 35,
      objectFit: "contain",
      marginRight: 8,
    },
  },
  reportContent: {
    backgroundColor: "#d92d200f",
    padding: 16,
  },
  detail: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  reportPhoto: {
    width: "100%",
    maxWidth: "250px",
    maxHeight: "250px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#EBEDEE",
    cursor: "pointer",
    position: "relative",
  },
  form: {
    background: "white",
    padding: 12,
  },
  statusInactive: {
    backgroundColor: "#FF8000",
  },
  statusActive: {
    backgroundColor: "#1f8d35",
  },
  statusChip: {
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    textAlign: "center",
    width: "fit-content",
    padding: "4px 12px",
  },
  wrapBox: {
    flexWrap: "noWrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
    wordWrap: "break-word",
  },
}));

export default function ScanDetailDialogComponent({
  isOpen,
  handleClose,
  scanDetail,
  language,
  isFetching,
  lang,
  handleReload,
  center,
  hasCounterfeitReportEditAccessRight,
  isSubmitting,
  handleSubmit,
  isLoadingScanDetailError,
}) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const getLocation = (location) => {
    let text = "";

    if (location.city) {
      text += location.city;
    }
    if (location.state) {
      text += (!!text ? ", " : "") + location.state;
    }
    if (location.country) {
      text += (!!text ? ", " : "") + location.country;
    }

    return text || "Unknown";
  };

  const displayStatus = (status) => {
    return (
      <Box
        className={clsx(classes.statusChip, {
          [classes.statusInactive]: status === "pending",
          [classes.statusActive]: status === "processed",
        })}
      >
        {status.toUpperCase()}
      </Box>
    );
  };

  const regionNamesInEnglish = new Intl.DisplayNames(["en"], {
    type: "language",
  });

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="modal-scan-detail"
      aria-describedby="modal-scan-detail"
      BackdropComponent={Backdrop}
      fullWidth
      PaperProps={{
        style: {
          overflowY: "hidden",
        },
      }}
    >
      <Box
        className={classes.header}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">
          <b>{getLang(lang, "label.SCAN_DETAILS")}</b>
        </Typography>
        <IconButton
          onClick={handleClose}
          edge="end"
          size="small"
          aria-label="close dialog"
          // disabled={isLoading}
        >
          <CloseRoundedIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        {!isFetching && scanDetail ? (
          <Box>
            <DashboardSingleScanMap detail={scanDetail} center={center} />
            <Box mx={2} mb={1.5} className={classes.content}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.floatBar}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ maxWidth: "60%" }}
                >
                  <Box className={classes.productPhoto}>
                    {scanDetail.product_image ? (
                      <img
                        src={scanDetail.product_image}
                        alt={scanDetail.product_name}
                      />
                    ) : (
                      <img src={DefaultImg} alt="emptyProduct" />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      style={{ wordBreak: "break-word" }}
                    >
                      <b>{scanDetail.product_name}</b>
                    </Typography>
                    {!!scanDetail.attributes &&
                      !!scanDetail.attributes.length && (
                        <Typography
                          className={classes.attributeText}
                          variant="body2"
                        >
                          {scanDetail.attributes.join(", ")}
                        </Typography>
                      )}
                    <Box
                      mt={1}
                      display="flex"
                      alignItems="center"
                      onClick={() => {
                        window.open(
                          `${SERIAL_NUMBER_ROOT}/details/${scanDetail.enc}`
                        );
                      }}
                    >
                      <Link color="secondary" component="button">
                        <Typography variant="body1">
                          {scanDetail.serial_number}
                        </Typography>
                      </Link>
                      <OpenInNewRoundedIcon className={classes.openNewTab} />
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box textAlign="right" className={classes.scanCountContainer}>
                    <Box>
                      <Typography variant="body1" display="inline">
                        <b>{scanDetail.scan_count}</b>
                      </Typography>
                      <Typography variant="body2" display="inline">
                        <b>
                          /
                          {scanDetail.scan_limit > 0
                            ? scanDetail.scan_limit
                            : getLang(lang, "label.NO_LIMIT")}
                        </b>
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {getLang(lang, "label.SCAN_COUNT")}
                    </Typography>
                  </Box>
                  <Box
                    className={clsx(classes.statusBox, {
                      [classes.activeStatusBox]: scanDetail.status === "active",
                      [classes.suspendStatusBox]:
                        scanDetail.status !== "active",
                    })}
                  >
                    <Typography variant="h5">
                      {scanDetail.status === "active"
                        ? getLang(lang, "label.VALID")
                        : getLang(lang, "label.SUSPENDED")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box mx={2} mt={4} mb={1.5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.wrapBox}
                >
                  <Box display="flex" alignItems="center">
                    <Box className={classes.profilePhoto}>
                      {!scanDetail.user || !scanDetail.user.picture ? (
                        <img src={DefaultProfile} alt="default-profile" />
                      ) : (
                        <img
                          src={scanDetail.user.picture}
                          alt={scanDetail.user.name}
                        />
                      )}
                    </Box>
                    <Box>
                      {scanDetail.user ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            window.open(
                              `${CONSUMER_ROOT}/${scanDetail.user.uuid}`
                            );
                          }}
                        >
                          <Link color="secondary" component="button">
                            <Typography variant="body1">
                              <b>{scanDetail.user.name}</b>
                            </Typography>
                          </Link>
                          <OpenInNewRoundedIcon
                            className={classes.openNewTab}
                          />
                        </Box>
                      ) : (
                        <Typography variant="body1">
                          <i>{getLang(lang, "label.UNKNOWN")}</i>
                        </Typography>
                      )}
                      <Box mt={0.4}>
                        <Typography color="textSecondary" variant="body2">
                          {getLang(lang, "label.SCAN_DATE")}:{" "}
                          {moment(scanDetail.date).format("lll")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.locationBox}>
                    <Box display="flex" alignItems="center">
                      <img
                        src={MarkerIcon}
                        alt="marker"
                        className={classes.icon}
                      />
                      <Box>
                        <Typography variant="body2">
                          <b>{getLocation(scanDetail.location)}</b>
                        </Typography>
                        <Link
                          color="secondary"
                          component="button"
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${scanDetail.location.latitude},${scanDetail.location.longitude}`
                            );
                          }}
                        >
                          <Typography variant="body2">
                            ({scanDetail.location.latitude}°
                            {scanDetail.location.latitude < 0 ? "S" : "N"},{" "}
                            {scanDetail.location.longitude}°
                            {scanDetail.location.longitude < 0 ? "W" : "E"})
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                    <Box
                      ml={1}
                      onClick={() => {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${scanDetail.location.latitude},${scanDetail.location.longitude}`
                        );
                      }}
                    >
                      <OpenInNewRoundedIcon className={classes.openNewTab} />
                    </Box>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="body1">
                    <b>{getLang(lang, "label.SCANNING_METHOD")}</b>
                  </Typography>
                  <Box className={classes.methodBar} mt={1}>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="body1">
                          {getLang(lang, "label.PLATFORM")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          <b>{scanDetail.platform}</b>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="body1">
                          {getLang(lang, "label.BROWSER")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          <b>{scanDetail.browser}</b>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="body1">
                          {getLang(lang, "label.DEVICE")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          <b>{scanDetail.device}</b>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.box}>
                      <Box>
                        <Typography variant="body1">
                          {getLang(lang, "label.LANGUAGE")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          <b>{regionNamesInEnglish.of(scanDetail.language)}</b>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {!!scanDetail.counterfeit_report && (
                  <Box className={classes.reportSection}>
                    <Box
                      className={classes.reportHeader}
                      display="flex"
                      alignItems="center"
                    >
                      <img src={ReportWarningIcon} alt="warning" />
                      <Typography variant="body1">
                        {getLang(lang, "label.COUNTERFEIT_REPORT")}
                      </Typography>
                    </Box>
                    <Box className={classes.reportContent}>
                      <Box mb={1}>
                        <Typography display="inline" variant="h6">
                          <b>{getLang(lang, "label.REFERENCE_CODE")}:&nbsp;</b>
                        </Typography>
                        <Typography display="inline" variant="h6">
                          <b>{scanDetail.counterfeit_report.ref_code}</b>
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {moment(
                          scanDetail.counterfeit_report.created_at
                        ).format("lll")}
                      </Typography>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.PURCHASE_DETAILS")}</b>
                      </Typography>
                      <Box className={classes.detail} mt={2}>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.PROOF_OF_PURCHASE")}</b>
                          </Typography>
                          {scanDetail.counterfeit_report.image_url ? (
                            <>
                              <Box
                                className={classes.reportPhoto}
                                onClick={() => setOpen(true)}
                              >
                                <img
                                  src={scanDetail.counterfeit_report.image_url}
                                  alt="product"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    maxWidth: "250px",
                                    maxHeight: "250px",
                                    objectFit: "contain",
                                  }}
                                />
                                <CropFreeIcon
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                    background: "white",
                                    borderRadius: 4,
                                    padding: 2,
                                  }}
                                />
                              </Box>
                              {!!open && (
                                <Lightbox
                                  mainSrc={
                                    scanDetail.counterfeit_report.image_url
                                  }
                                  nextSrc={undefined}
                                  prevSrc={undefined}
                                  onCloseRequest={() => setOpen(false)}
                                  reactModalStyle={{
                                    overlay: {
                                      zIndex: 9999,
                                    },
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <Typography variant="body2">-</Typography>
                          )}
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.PURCHASED_DATE")}</b>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {scanDetail.counterfeit_report.purchased_date}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.PURCHASED_FROM")}</b>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {scanDetail.counterfeit_report.purchased_from}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="body2" style={{ marginBottom: 4 }}>
                          <b>{getLang(lang, "label.REMARKS")}</b>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {scanDetail.counterfeit_report.consumer_remarks}
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Typography variant="body1">
                        <b>{getLang(lang, "label.CONSUMER_DETAILS")}</b>
                      </Typography>
                      <Box className={classes.detail} mt={2}>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.NAME")}</b>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {scanDetail.counterfeit_report.consumer_name}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.CONTACT_NUMBER")}</b>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {scanDetail.counterfeit_report.contact || "-"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            style={{ marginBottom: 4 }}
                          >
                            <b>{getLang(lang, "label.EMAIL")}</b>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {scanDetail.counterfeit_report.email || "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Formik
                        initialValues={{
                          status: scanDetail.counterfeit_report.status,
                          adminRemarks:
                            scanDetail.counterfeit_report.admin_remarks,
                        }}
                        validationSchema={Yup.object({
                          adminRemarks: Yup.string()
                            .trim()
                            .max(
                              500,
                              getLang(lang, "message.error.FORM_VALIDATE_MAX", {
                                value: 500,
                              })
                            ),
                        })}
                      >
                        {(formik) => (
                          <Box className={classes.form}>
                            <Typography variant="body1">
                              <b>{getLang(lang, "label.ADMIN_REMARKS")}</b>
                            </Typography>
                            <Box mt={2}>
                              <Typography
                                variant="body2"
                                style={{ marginBottom: 4 }}
                              >
                                <b>{getLang(lang, "label.STATUS")}</b>
                              </Typography>
                              <Box>{displayStatus(formik.values.status)}</Box>
                            </Box>
                            <Box mt={2}>
                              <Typography
                                variant="body2"
                                style={{ marginBottom: 4 }}
                              >
                                <b>{getLang(lang, "label.REMARK")}</b>
                              </Typography>
                              <Box width="100%">
                                <InputTextField
                                  multiline
                                  rows={4}
                                  className={classes.textArea}
                                  value={formik.values.adminRemarks}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "adminRemarks",
                                      e.target.value || ""
                                    );
                                  }}
                                  disabled={
                                    isSubmitting ||
                                    !hasCounterfeitReportEditAccessRight
                                  }
                                  error={
                                    formik.touched.adminRemarks &&
                                    formik.errors.adminRemarks
                                      ? true
                                      : false
                                  }
                                  helperText={ErrorMessage({
                                    name: "adminRemarks",
                                  })}
                                  InputStyle={{
                                    background: "white",
                                    border: "1px solid #D0D5DD",
                                    flex: 1,
                                    borderRadius: 0,
                                  }}
                                  fullWidth
                                />
                              </Box>
                            </Box>
                            {hasCounterfeitReportEditAccessRight ? (
                              <Box
                                mt={2}
                                className={classes.button}
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disableElevation
                                  disabled={isSubmitting}
                                  onClick={() => {
                                    handleSubmit(formik);
                                  }}
                                >
                                  {getLang(lang, "label.UPDATE")}
                                </Button>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : !isFetching && isLoadingScanDetailError ? (
          <RefreshTable handleReload={handleReload} />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={350}
          >
            <LoadingComponent />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
