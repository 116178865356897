import React, { useContext } from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Grid,
  withStyles,
  IconButton,
} from "@material-ui/core";
import SetTranditionalRaffleWinnerSelect from "../../select/tranditionalRaffleWinnerSelect";
import ViewWinnerTable from "../../table/viewTranditionalRaffleWinnerTable";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getLang } from "app/feature/constants";
import {
  PRIZE_DRAW_ALL,
  PRIZE_DRAW_ONE_BY_ONE,
  PRIZE_DRAW_ONE_BY_ONE_CAROUSEL,
  PRIZE_RANKING,
} from "modules/lucky-draw-v2/constants";
import { DustBinSVG } from "../../../utils/static";
import { PrizeContext } from "../../dialog/tranditionalRaffleWinnerDialog/tranditionalRaffleWinnerDialog.container";
import clsx from "clsx";

const style = (theme) => ({
  viewPrizeContainer: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    margin: "8px 24px 24px",
  },
  viewPrizePhoto: {
    width: 100,
    height: 100,
    display: "flex",
    alignItems: "center",
    padding: "4px",
    placeContent: "center",
  },
  button: {
    minWidth: 0,
    padding: 13,
  },
  publishButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  discardButton: {
    color: theme.palette.error.main,
  },
  prizeCard: {
    flex: 1,
    display: "flex",
    gap: "20px",
    borderRadius: "4px",
    padding: "10px",
    alignItems: "center",
  },
});

const Accordion = withStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: "0 16px",
    borderBottom: "1px solid rgba(34, 45, 48, 0.15)",
    borderRadius: "0px 0px 10px 10px",
    marginBottom: -1,
    minHeight: 49,
    "&$expanded": {
      minHeight: 49,
      borderRadius: "0px",
    },
  },
  content: {
    justifyContent: "space-between",
    minHeight: 49,
    display: "flex",
    alignItems: "center",
    margin: 0,
    "&$expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
    background: "rgba(58, 77, 84, 0.1)",
    borderRadius: "6px",
    padding: "4px",
    margin: "0px",
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: "block",
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(style);

export default function TranditionalRaffleWinnerCardComponent({
  prizeDetail,
  prizeIndex,
  lang,
  isExpand,
  onToggle,
  isNewRank,
}) {
  const classes = useStyles();
  const { isEdit, handleDelete } = useContext(PrizeContext);

  return (
    <Box>
      {isNewRank ? (
        <Typography style={{ fontWeight: 600, padding: "0.5px 24px 0px 24px" }}>
          {PRIZE_RANKING[prizeDetail.rank_position]}
        </Typography>
      ) : null}
      <Box className={classes.viewPrizeContainer}>
        <Accordion expanded={isExpand} onChange={onToggle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className={classes.prizeCard}>
              <Box className={clsx({ [classes.viewPrizePhoto]: !!isExpand })}>
                <img
                  src={prizeDetail.image_url}
                  alt="prize"
                  style={{
                    width: isExpand ? "100%" : "50px",
                    height: isExpand ? "100%" : "50px",
                    objectFit: "contain",
                    borderRadius: "4px",
                  }}
                />
              </Box>
              {isExpand ? (
                <Box width="100%">
                  <Box display="flex" pt={1} pb={1}>
                    <Typography
                      variant="body2"
                      style={{ color: " rgba(58, 77, 84, 0.33)" }}
                    >
                      {getLang(lang, "label.PRIZE_NAME")}:&nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {prizeDetail.name}
                    </Typography>
                  </Box>

                  <Box display="grid" gridTemplateColumns="2fr 6fr">
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        style={{ color: " rgba(58, 77, 84, 0.33)" }}
                      >
                        {getLang(lang, "label.QUANTITY")}:&nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {prizeDetail.quantity.toLocaleString("en-US")}
                      </Typography>
                    </div>
                  </Box>

                  <Box display="flex" pt={1} pb={1}>
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="body2"
                        style={{ color: " rgba(58, 77, 84, 0.33)" }}
                      >
                        {getLang(lang, "label.DRAW_PRIZE_METHOD")}:&nbsp;
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {prizeDetail.draw_type ===
                        PRIZE_DRAW_ONE_BY_ONE_CAROUSEL
                          ? getLang(lang, "label.ONE_BY_ONE_CAROUSEL")
                          : prizeDetail.draw_type === PRIZE_DRAW_ONE_BY_ONE
                          ? getLang(lang, "label.ONE_BY_ONE")
                          : prizeDetail.draw_type === PRIZE_DRAW_ALL
                          ? getLang(lang, "label.ALL_AT_ONCE")
                          : prizeDetail.draw_type}
                        {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  <>
                    {prizeDetail.name}
                    <span style={{ margin: "0px 4px" }}> • </span>
                    {`${prizeDetail.quantity} pc(s)`}
                    <span style={{ margin: "0px 4px" }}> • </span>
                    {getLang(lang, "label.COUNT_WINNERS", {
                      count: prizeDetail.quantity - prizeDetail.balance,
                    })}
                  </>
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />
            {!isEdit ? (
              <ViewWinnerTable data={prizeDetail.winners} lang={lang} />
            ) : (
              <Box p={2}>
                <Grid
                  container
                  spacing={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {prizeDetail.winners.map((winner, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={2} sm={2} md={2}>
                        <Typography variant="body2" color="primary">
                          {getLang(lang, "label.WINNER")} #{index + 1}{" "}
                          <span
                            style={{
                              color: "#DE350B",
                              display: "inline-block",
                            }}
                          >
                            *
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9}>
                        <SetTranditionalRaffleWinnerSelect
                          prizeIndex={prizeIndex}
                          winnerIndex={index}
                          value={winner}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}>
                        {!winner.status && (
                          <IconButton
                            style={{ padding: 4, marginLeft: 8 }}
                            onClick={() => {
                              handleDelete(
                                prizeIndex,
                                index,
                                winner.serial_number,
                                winner.enc
                              );
                            }}
                          >
                            <DustBinSVG />
                          </IconButton>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
