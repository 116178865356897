import React from 'react';
import { Box, Button, Dialog, FormControlLabel, Grid, IconButton, makeStyles, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { getLang } from 'app/feature/constants';
import DealerSelectContainer from '../../select/dealerSelect';
import LoadingComponent from 'components/loading';
import { TIER_ICON_PROPS } from "modules/stockflow/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 'none'
  },
  container: {
    fontSize: "1rem",
    "@media (max-width: 600px)": {
      maxWidth: "40em"
    },
    "@media (min-width: 600px)": {
      width: "34em"
    }
  },
  header: {
    padding: '0 0 0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontWeight: '700',
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2em 0',
  },
  body: {
    padding: '16px',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    padding: '2em 16px',
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '5px',
  },
  asteriskLabel: {
    color: theme.palette.error.main,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px',
    gap: '5px'
  },
  dealerField: {
    display: "flex",
    alignItems: "center"
  },
  dealerProfileTierCombo: {
    display: "flex",
    flexDirection: "row",
    marginRight: "0.5em"
  },
  tierIconDealer: {
    width: "1em",
    height: "1em",
    borderRadius: "3px",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center"
  },
  tierLabelDealer: {
    color: "#FFFFFF",
    fontSize: "0.625rem"
  },
  profilePhotoDealer: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E4E7EC",
    borderRadius: "50%",
    width: "33px",
    height: "33px",
    marginRight: "0.25em",
    marginLeft: "0.25em",
    marginTop: "0.25em",
    "& img": {
      borderRadius: "50%",
      height: "33px",
      width: "33px"
    },
  },
  dealerLabel: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
}));

function UpdateDealerUplineDialogComponent({
  open,
  handleClose,
  handleUplineUpdate,
  lang,
  details,
  isFetching,
  uplineType,
  handleUplineTypeChange,
  handleUplineSelectChange,
  isSubmitting,
  selectedDealer,
  dealerDropdown,
  isFetchingDealerDropdown,
  SELECT_UPLINE,
  NO_UPLINE,
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paperWidthSm: classes.root }}>
      <Paper elevation={0} className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="subtitle2" className={classes.headerText}>{getLang(lang, 'paragraph.CHANGE_DEALER_UPLINE')}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box className={classes.body}>
          {
            (isFetching || !details) ?
              <Box className={classes.loadingBox}>
                <LoadingComponent />
              </Box> :
              <Box className={classes.content}>
                <Grid container>
                  <Grid item xs={12} md={4} className={classes.label} style={{ marginTop: '0.65em' }}>
                    <Typography variant="body2">{getLang(lang, 'label.DEALER')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.label} style={{ marginTop: '0.12em' }}>
                    <Box className={classes.dealerField}>
                      <Box className={classes.dealerProfileTierCombo}>
                        <Box
                          className={classes.tierIconDealer}
                          style={{
                            backgroundColor: details.tier ? TIER_ICON_PROPS.find(({ tier }) => tier === details.tier).color : null
                          }}
                        >
                          <Typography className={classes.tierLabelDealer}>
                            T{details.tier}
                          </Typography>
                        </Box>
                        <Box className={classes.profilePhotoDealer}>
                          <img src={details.picture} alt={details.name} />
                        </Box>
                      </Box>
                      <Typography className={classes.dealerLabel}>
                        {details.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.label} style={{ marginTop: '1.2em' }}>
                    <Typography variant="body2">{getLang(lang, 'label.UPLINE')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={8} className={classes.label} style={{ marginTop: '0.65em' }}>
                    {
                      details.upline ?
                      <Box className={classes.dealerField}>
                        <Box className={classes.dealerProfileTierCombo}>
                          <Box
                            className={classes.tierIconDealer}
                            style={{
                              backgroundColor: details.upline.tier
                                ? TIER_ICON_PROPS.find(({ tier }) => tier === details.upline.tier).color
                                : null
                            }}
                          >
                            <Typography className={classes.tierLabelDealer}>
                              T{details.upline.tier}
                            </Typography>
                          </Box>
                          <Box className={classes.profilePhotoDealer}>
                            <img src={details.upline.picture} alt={details.upline.name} />
                          </Box>
                        </Box>
                        <Typography className={classes.dealerLabel}>
                          {details.upline.name}
                        </Typography>
                      </Box> :
                      <Typography variant="body2" style={{ fontWeight: 700, marginTop: '0.65em' }}>-</Typography>
                    }
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.label} style={{ marginTop: '1.5em' }}>
                    <Typography variant="body2">{getLang(lang, 'label.BRANCH')}</Typography>
                    <Typography className={classes.asteriskLabel}>*</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body2" style={{ fontWeight: 700, marginTop: '1.7em' }}>{details.branch?.name}</Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: '2em' }}>
                  <Grid item xs={12} md={4} className={classes.label} style={{ marginTop: '9px' }}>
                    <Typography variant="body2">{getLang(lang, 'label.ASSIGN_DEALER_TO')}</Typography>
                    <Typography variant="body2" className={classes.asteriskLabel}>*</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RadioGroup
                      aria-labelledby="dealer-upline-label"
                      defaultValue={uplineType}
                      name="dealer-upline-group"
                      onChange={handleUplineTypeChange}
                    >
                      <FormControlLabel
                        value={NO_UPLINE}
                        control={<Radio />}
                        label={getLang(lang, 'label.T1_DEALER')}
                      />
                      <FormControlLabel
                        value={SELECT_UPLINE}
                        control={<Radio />}
                        label={getLang(lang, 'label.SELECT_UPLINE')}
                      />
                    </RadioGroup>
                    <DealerSelectContainer
                      searchable={true}
                      value={selectedDealer}
                      placeholder={getLang(lang, 'placeholder.SELECT_UPLINE_DEALER')}
                      searchPlaceholder={getLang(lang, 'placeholder.SEARCH_DEALER_NAME')}
                      disabled={isSubmitting || uplineType === NO_UPLINE}
                      emptyMessage={getLang(lang, 'message.info.NO_DEALER_FOUND')}
                      handleChange={handleUplineSelectChange}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      dropdownItem={dealerDropdown.filter(i => i.uuid !== details.uuid)}
                      isFetching={isFetchingDealerDropdown}
                      isEmpty={selectedDealer === null}
                    />
                  </Grid>
                </Grid>
              </Box>
          }
        </Box>
        <Box className={classes.footer}>
          <Button
            type="button"
            onClick={handleClose}
            variant="text"
          >
            {getLang(lang, 'label.CANCEL')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleUplineUpdate}
            disabled={isFetching || !details || isSubmitting}
          >
            {getLang(lang, 'label.UPDATE')}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default UpdateDealerUplineDialogComponent;
