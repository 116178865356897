import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GENERATE_RANGE_TYPE_LAST_NUMBER,
  SERIAL_NUMBER,
  SERIAL_NUMBER_TYPE_NUMBER,
} from "../../constants";
import serialNumberApi from "app/api/serialnumber";

export const getListingSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER}/getListingSerialNumber`,
  async (payload, thunkApi) => {
    return serialNumberApi.listingSerialNumber
      .post(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const submitSerialNumberGenerate = createAsyncThunk(
  `${SERIAL_NUMBER}/submitSerialNumberGenerate`,
  async (payload, { getState, rejectWithValue }) => {
    const saveTagResponse = serialNumberApi.tagSerialNumber.post({
      prefix: payload.prefix,
      snDigit: payload.digitLength,
      postfix: payload.postfix,
      serialType: payload.type,
    });

    let generateRange = [];

    if (payload.generateRangeType === GENERATE_RANGE_TYPE_LAST_NUMBER) {
      if (payload.type === SERIAL_NUMBER_TYPE_NUMBER) {
        generateRange.push({
          start:
            getState().serialNumber.generateSerialNumber.batchRange
              .startingDigit,
          quantity: payload.generateAmount,
        });
      } else {
        generateRange.push({
          quantity: payload.generateAmount,
        });
      }
    } else {
      for (let i = 0; i < payload.generateRanges.length; i++) {
        generateRange.push({
          start: payload.generateRanges[i].from,
          quantity:
            payload.generateRanges[i].to - payload.generateRanges[i].from + 1,
        });
      }
    }

    const generateResponse = serialNumberApi.generateSerialNumber({
      prefix: payload.prefix,
      sn_digit: payload.digitLength,
      postfix: payload.postfix,
      serial_type: payload.type,
      pin_type: payload.pinType,
      pin_required: payload.pinRequired,
      node_id: payload.branch,
      range: generateRange,
      product_id: payload.product,
      is_use_auth_app: Number(payload.appAuthrorizeRequire),
      is_qr_link_shorten: Number(payload.shortenQrUrl),
    });

    let response = null;
    try {
      response = await Promise.all([saveTagResponse, generateResponse]);
    } catch (e) {
      return rejectWithValue(e);
    }

    return response[1].data;
  }
);

export const getUnassignedSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER}/getUnassigned`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getRangeList()
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const getUnassignedSerialNumberByBranch = createAsyncThunk(
  `${SERIAL_NUMBER}/getUnassignedSNByBranch`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getRangeListByBranch(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const assignSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER}/assign`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .assignSerialNumber(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const getDetailsSerialNumber = createAsyncThunk(
  `${SERIAL_NUMBER}/getDetailsSerialNumber`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .detailsSerialNumber({
        enc: payload.enc,
      })
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const updateSNStatus = createAsyncThunk(
  `${SERIAL_NUMBER}/updateSNStatus`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .updateSerialNumberStatus({
        enc: payload.enc,
        status: payload.status,
      })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error));
  }
);

export const resetSNScanCount = createAsyncThunk(
  `${SERIAL_NUMBER}/resetSNScanCount`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .resetScanCount({
        enc: payload.enc,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getSerialNumberScanListing = createAsyncThunk(
  `${SERIAL_NUMBER}/getSerialNumberScanListing`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getSerialNumberScanListing(payload)
      .then((res) => res)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const getCampaignAssigned = createAsyncThunk(
  `${SERIAL_NUMBER}/getCampaignAssigned`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getCampaignAssigned(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const getSerialNumberActivityLog = createAsyncThunk(
  `${SERIAL_NUMBER}/getSerialNumberActivityLog`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getSerialNumberActivityLog(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const getScanTrackDetail = createAsyncThunk(
  `${SERIAL_NUMBER}/getScanTrackDetail`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .getScanTrackDetail(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const checkSerialNumberAvailability = createAsyncThunk(
  `${SERIAL_NUMBER}/checkSerialNumberAvailability`,
  async (payload, { rejectWithValue }) => {
    return serialNumberApi
      .checkSerialNumberAvailability(payload)
      .then((res) => res.data)
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

export const fetchTableLength = createAsyncThunk(
  `${SERIAL_NUMBER}/fetchTableLength`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .fetchTableLength(payload)
      .then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);

export const getSnSummaryCount = createAsyncThunk(
  `${SERIAL_NUMBER}/getSnSummaryCount`,
  async (payload, thunkApi) => {
    return serialNumberApi
      .getSnSummaryCount(payload)
      .then((response) => response.data)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
