import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  Checkbox,
} from "@material-ui/core";
import "react-image-lightbox/style.css";
import { getLang } from "app/feature/constants";
import { Formik } from "formik";
import Input from "components/input/inputTextField";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import AntSwitch from "components/switch";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingComponent from "components/loading";
import DefaultImg from "assets/img/img-default.png";

const useStyle = makeStyles((theme) => ({
  container: {
    boxShadow: "1px 0px 11px 2px #1018281A",
  },
  inputRootProduct: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
    border: "1px solid #E4E7EC",
    borderRadius: 4,
  },
  searchContainer: {
    backgroundColor: theme.palette.background.paper,
    display: "grid",
    gridTemplateColumns: "5fr 1fr",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      gridRowGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
  },
  selected: {
    padding: 0,
    paddingRight: 10,
    "&.Mui-checked": {
      color: "#6AAF68",
    },
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #F2F4F7",
    width: 38,
    height: 38,
    marginRight: 4,
    minWidth: 38,
    overflow: "hidden",
    borderRadius: 8,
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  productRow: {
    "& .name": {
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    "&:hover": {
      backgroundColor: "#ECEEEE",
    },
  },
  errorContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

export default function ClusterProductListingPanelComponent({
  isFetching,
  lang,
  handleReload,
  totalRecords,
  totalFiltered,
  products,
  isError,
  filter,
  handleSearch,
  updateRevealProductStatus,
  revealProduct,
  isSelectAll,
  handleSelectAll,
  selectedIds,
  handleFetchMoreItems,
  handleSelectProduct,
}) {
  const classes = useStyle();

  return (
    <Box
      position="relative"
      height="100%"
      flexDirection="column"
      display="flex"
      className={classes.container}
    >
      <Box py={1} px={1.5}>
        <Typography display="inline">
          <b>{getLang(lang, "label.PRODUCT_TAB")}&nbsp;</b>
        </Typography>
        <Typography display="inline" color="textSecondary">
          <b>({totalFiltered || 0})</b>
        </Typography>
      </Box>
      <Divider />
      <Box p={1.5}>
        <Formik
          initialValues={{
            search: filter.search,
          }}
          enableReinitialize
          onSubmit={handleSearch}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Box className={classes.searchContainer}>
                <Input
                  hiddenLabel
                  variant="filled"
                  size="small"
                  placeholder={getLang(lang, "placeholder.SEARCH_PRODUCT")}
                  margin="none"
                  InputProps={{
                    disableUnderline: true,
                    margin: "none",
                    style: {
                      backgroundColor: "white",
                    },
                    classes: { input: classes.inputRootProduct },
                  }}
                  inputStyle={{ fontSize: 14 }}
                  {...formik.getFieldProps("search")}
                />
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  size="small"
                  type="submit"
                >
                  {getLang(lang, "label.SEARCH")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Divider />
      <Box p={1.5} display="flex" alignItems="center">
        <Box>
          <Checkbox
            className={classes.selected}
            checked={isSelectAll}
            onChange={(event) => handleSelectAll(event.target.checked)}
          />
        </Box>
        <Typography variant="body2">
          {getLang(lang, "label.DISPLAY_ALL_PRODUCT")}
        </Typography>
      </Box>
      <Divider />
      <Box flex={1} backgroundColor="#F9FAFB" style={{ overflowY: "auto" }}>
        {!!isError ? (
          <Box py={1.5} px={2} className={classes.errorContainer}>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "paragraph.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.RETRY")}
            </Button>
          </Box>
        ) : (
          <InfiniteScroll
            height="100%"
            dataLength={products.length}
            next={handleFetchMoreItems}
            hasMore={totalFiltered > products.length}
            loader={<LoadingComponent height={100} />}
            endMessage={
              isFetching ? (
                <LoadingComponent height={100} />
              ) : !products.length ? (
                <Box px={1.5} py={4}>
                  <Typography variant="body2" style={{ textAlign: "center" }}>
                    <b>{getLang(lang, "label.NO_PRODUCT")}</b>
                  </Typography>
                </Box>
              ) : null
            }
          >
            {products.map((p, i) => (
              <Box
                key={i}
                display="flex"
                alignItems="center"
                px={1.5}
                py={1}
                className={classes.productRow}
              >
                <Checkbox
                  className={classes.selected}
                  checked={
                    (isSelectAll && !selectedIds.includes(p.uuid)) ||
                    (!isSelectAll && selectedIds.includes(p.uuid))
                  }
                  onChange={(event) =>
                    handleSelectProduct(p.uuid, event.target.checked)
                  }
                />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={1}
                >
                  <Box display="flex" alignItems="center" pr={1.5}>
                    <Box className={classes.productPhoto}>
                      {p.picture ? (
                        <img src={p.picture} alt={p.name} />
                      ) : (
                        <img src={DefaultImg} alt="emptyProduct" />
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Typography variant="body2" style={{ wordBreak: "break-word"}}>{p.name}</Typography>
                      {!!p.attributes && !!p.attributes.length && (
                        <Typography
                          className={classes.attributeText}
                          variant="body2"
                        >
                          {p.attributes.join(", ")}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {p.total_scan?.toLocaleString("en-US") || 0}
                  </Typography>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1.5}
      >
        <Box display="flex" alignItems="center" mr={3}>
          <RemoveRedEyeOutlinedIcon style={{ marginRight: 8, fontSize: 18 }} />
          <Typography variant="body2">
            {getLang(lang, "label.REVEAL_PRODUCTS")}
          </Typography>
        </Box>
        <AntSwitch
          checked={revealProduct}
          onChange={(ev) => {
            updateRevealProductStatus(ev.target.checked);
          }}
        />
      </Box>
    </Box>
  );
}
