import React from "react";
import {
  makeStyles,
  Box,
  Paper, Divider, Button
} from "@material-ui/core";
import CompaniesReactSortableTree from "../../companiesReactSortableTree";
import LoadingComponent from "components/loading";
import { useDispatch } from "react-redux";
import { collapseAll, expandAll } from "../../../redux";
import { getLang } from "../../../../../app/feature/constants";

const style = (theme) => ({
  root: {
    width: '100%',
  }
});

const useStyles = makeStyles(style);

export default function StructureOverviewPanelComponent({
  isOrganisationStructureLoading,
  organisationStructure,
  lang
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.root}>
      <Paper>
        {!isOrganisationStructureLoading ? (
          <Box>
            <Box display="flex" justifyContent="flex-end" pt={1} pr={1} pb={1}>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="medium"
                onClick={() => dispatch(collapseAll())}
              >
                {getLang(lang, "label.COLLAPSE_ALL")}
              </Button>
              <Button
                variant="text"
                disableElevation
                color="primary"
                size="medium"
                onClick={() => dispatch(expandAll())}
              >
                {getLang(lang, "label.EXPAND_ALL")}
              </Button>
            </Box>
            <Divider />
          </Box>
        ) : null}
        <Box style={{ paddingBottom: 25, paddingTop: 25, overflowX: "auto" }}>
          {isOrganisationStructureLoading ? (
            <LoadingComponent />
          ) : (
            <CompaniesReactSortableTree
              organisationStructure={organisationStructure}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
