import axios from "axios";

const endpoint_url = "/account/api/v1/company";

const put = ({
  name,
  description,
  registrationNo,
  addressLine1,
  addressLine2,
  city,
  country,
  postalCode,
  state,
  contactNumber,
  code,
  companyEmail,
  industry,
  image_logo_url,
  articleAssociationUrl,
  directorIdentificationUrl,
  timezone,
  currencyCode
}) => {
  const body = {
    name: name,
    description: description,
    reg_no: registrationNo,
    line1: addressLine1,
    line2: addressLine2,
    city: city,
    country:  country,
    postal_code: postalCode,
    state: state,
    contact: contactNumber,
    contact_code: code,
    email: companyEmail,
    industry: industry,
    image_logo_url: image_logo_url,
    article_assoclation: articleAssociationUrl,
    director_identification: directorIdentificationUrl,
    timezone: timezone,
    currency_code: currencyCode
  };

  return new Promise((resolve, reject) => {
    axios
      .put(endpoint_url, body)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const post = ({
  name,
  shortName,
  branchShortName,
  registrationNo,
  addressLine1,
  addressLine2,
  city,
  country,
  postalCode,
  state,
  contactNumber,
  code,
  companyEmail,
  industry,
  companyLogoUrl,
  articleAssociationUrl,
  directorIdentificationUrl,
  timezone,
  currencyCode
}) => {
  const body = {
    name: name,
    short_name: shortName,
    branch_short_name: branchShortName,
    reg_no: registrationNo,
    line1: addressLine1,
    line2: addressLine2,
    city: city,
    country: country,
    postal_code: postalCode,
    state: state,
    contact: contactNumber,
    contact_code: code,
    email: companyEmail,
    industry: industry,
    image_logo_url: companyLogoUrl,
    article_assoclation: articleAssociationUrl,
    director_identification: directorIdentificationUrl,
    timezone: timezone,
    currency_code: currencyCode
  };

  return new Promise((resolve, reject) => {
    axios
      .post(endpoint_url, body)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const company = {
  put,
  post,
  get
};

export default company;
