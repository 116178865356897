import {
  closeViewEditDialog,
  fetchAllInstantPrize,
  openCreationDialog,
  openPrizeDialogV2,
  openViewEditDialog,
  publishInstantLuckyDraw,
} from "modules/instant-lucky-draw/redux";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InstantLuckyDrawTableComponent from "./instantLuckyDrawTable.component";
import {
  setType,
  fetchInstantLuckyDrawDetail,
  fetchJSONURL,
  openPrizeDialog,
  openAllocationDialog,
  fetchAllInstantPrizeLegacy,
  openWinnerDialog,
  changeInstantLuckyDrawProgress,
} from "modules/instant-lucky-draw/redux";
import { hideBackdrop, showBackdrop } from "modules/backdrop";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ViewEditInstantLuckyDrawDialog,
  PrizeAllocationDialog,
  WinnerDialog,
} from "../../dialog";
import { ROOT } from "../../../config/instant-lucky-draw.route";
import { useHistory } from "react-router-dom";
import getWinnerExcel from "app/api/instantLuckyDraw/getWinnerExcel.api";
import { progressStatus } from "lib/constants/instantLuckyDrawProgress";
import ConfirmationDialogComponent from "components/dialog/confirmation";
import { getLang } from "app/feature/constants";

export default function InstantLuckyDrawTableContainer({
  hasInstantLuckyDrawAddAccessRight,
  hasInstantLuckyDrawEditAccessRight,
  hasInstantLuckyDrawActivateOrDeactivateAccessRight,
  isFetching,
  data,
  rowsPerPage,
  page,
  search,
  handlePageChange,
  handleRowPerPageChange,
  totalRecords,
  handleStatusChange,
  handleFetchInstantLuckyDrawList,
  paginationOptions,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state.session.accessToken);
  const [confirmationDialogModal, setConfirmationDialogModal] = useState(false);
  const isLoadingInstantError = useSelector(
    (state) => state.instantLuckyDraw.isLoadingInstantError
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);

  const [selectedIld, setSelectedIld] = useState(null);

  const handleCreate = () => {
    dispatch(openCreationDialog());
  };

  const handleView = async (id) => {
    dispatch(setType({ index: -1, type: "view" }));
    dispatch(showBackdrop());

    dispatch(fetchInstantLuckyDrawDetail(id))
      .then(unwrapResult)
      .then(async (response) => {
        if (response.tnc_url.substr(response.tnc_url.length - 3) !== "pdf") {
          await dispatch(fetchJSONURL(response.tnc_url))
            .then(unwrapResult)
            .then(() => {
              dispatch(hideBackdrop());
              dispatch(openViewEditDialog());
            });
        } else {
          dispatch(hideBackdrop());
          dispatch(openViewEditDialog());
        }
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        dispatch(closeViewEditDialog());
        return;
      });
  };

  async function handleViewParticipants(id) {
    history.push(`${ROOT}/View/${id}`);
  }

  async function handleViewPrize(item, dialogCategory) {
    dispatch(showBackdrop());

    setSelectedIld(item);

    const fetchPrizeTableInfo = {
      type: "dialog",
      instantLuckyDrawId: item.id,
      isWinnerShow: dialogCategory === "winner" ? 1 : 0,
    };

    let isCurrent = item && item.type === "current";

    await dispatch(
      isCurrent
        ? fetchAllInstantPrize(fetchPrizeTableInfo)
        : fetchAllInstantPrizeLegacy(fetchPrizeTableInfo)
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(hideBackdrop());

        if (dialogCategory === "prize") {
          if (isCurrent) {
            dispatch(
              openPrizeDialogV2({ type: "view", instantLuckyDraw: item })
            );
          } else {
            dispatch(openPrizeDialog({ type: "view" }));
          }
        } else if (dialogCategory === "winner") {
          dispatch(setType({ index: 3, type: "view" }));
          dispatch(openWinnerDialog());
        }
      })
      .catch((error) => {
        dispatch(hideBackdrop());
        return;
      });
  }

  async function handleViewTemplate(id, progress) {
    dispatch(showBackdrop());
    dispatch(hideBackdrop());
    dispatch(openAllocationDialog());
  }

  const handleDownloadWinner = (id) => {
    dispatch(showBackdrop());
    getWinnerExcel.get({ id: id, token: accessToken }).finally(() => {
      dispatch(hideBackdrop());
      return;
    });
  };

  const handlePublished = (item) => {
    setSelectedIld(item);
    setConfirmationDialogModal(true);
  };

  async function handleChangeProgress() {
    dispatch(showBackdrop());
    await dispatch(
      selectedIld.type === "current"
        ? publishInstantLuckyDraw({ id: selectedIld.id })
        : changeInstantLuckyDrawProgress({
            id: selectedIld.id,
            progress: progressStatus.published,
          })
    ).finally(() => {
      dispatch(hideBackdrop());
      setConfirmationDialogModal(false);
    });
  }

  return (
    <>
      <InstantLuckyDrawTableComponent
        hasInstantLuckyDrawAddAccessRight={hasInstantLuckyDrawAddAccessRight}
        hasInstantLuckyDrawEditAccessRight={hasInstantLuckyDrawEditAccessRight}
        hasInstantLuckyDrawActivateOrDeactivateAccessRight={
          hasInstantLuckyDrawActivateOrDeactivateAccessRight
        }
        isFetching={isFetching}
        data={data}
        rowsPerPage={rowsPerPage}
        page={page}
        search={search}
        handlePageChange={handlePageChange}
        handleRowPerPageChange={handleRowPerPageChange}
        totalRecords={totalRecords}
        handleStatusChange={handleStatusChange}
        handleCreate={handleCreate}
        handleView={handleView}
        handleViewParticipants={handleViewParticipants}
        handleViewPrize={handleViewPrize}
        handleViewTemplate={handleViewTemplate}
        handleDownloadWinner={handleDownloadWinner}
        handlePublished={handlePublished}
        handleReload={handleFetchInstantLuckyDrawList}
        isLoadingInstantError={isLoadingInstantError}
        paginationOptions={paginationOptions}
        lang={lang}
        language={language}
      />
      <ViewEditInstantLuckyDrawDialog
        handleClose={() => dispatch(closeViewEditDialog())}
      />
      <PrizeAllocationDialog />
      <WinnerDialog instantLuckyDraw={selectedIld} />
      <ConfirmationDialogComponent
        isOpen={confirmationDialogModal}
        handleClose={() => setConfirmationDialogModal(false)}
        handleProceed={handleChangeProgress}
        type={"success"}
        remark={getLang(lang, "paragraph.PUBLISH_ILD_REMARK")}
        description={getLang(lang, "paragraph.PLEASE_CONFIRM")}
        title={getLang(lang, "paragraph.PUBLISH_ILD_QUESTION")}
      />
    </>
  );
}
