import axios from "axios";

const generateSerialNumber = ({
  prefix,
  sn_digit,
  postfix,
  serial_type,
  pin_type,
  pin_required,
  node_id,
  range,
  product_id,
  is_use_auth_app,
  is_qr_link_shorten,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/serialnumber/api/v1/generateV2", {
        prefix,
        sn_digit,
        postfix,
        serial_type,
        pin_type,
        pin_required,
        node_id,
        range,
        product_id,
        is_use_auth_app,
        is_qr_link_shorten,
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        return reject(error.response.data);
      });
  });
};

export default generateSerialNumber;
