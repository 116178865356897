import React from "react";
import { Box, Paper, Typography, makeStyles } from "@material-ui/core";
import DefaultImg from "assets/img/img-default.png";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2),
  },
  productPhoto: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0000001a",
    width: "55px",
    height: "55px",
    borderRadius: "4px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  greyText: {
    color: "#98A2B3",
  },
  field: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 13,
    whiteSpace: "break-spaces",
  },
}));

export default function ProductInfoCardComponent({
  product,
  isFetching,
  lang,
}) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.container}>
      {!isFetching ? (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" mr={1} alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Box className={classes.productPhoto}>
                {product?.picture === "" ? (
                  <img src={DefaultImg} alt="emptyProduct" />
                ) : (
                  <img src={product?.picture} alt={product?.name} />
                )}
              </Box>
            </Box>
            <Box
              mr={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                style={{ fontWeight: "700", textTransform: "capitalize", wordBreak: "break-word"}}
              >
                {product?.name}
              </Typography>
              {!!product?.attributes && !!product?.attributes.length && (
                <Typography className={classes.attributeText} variant="body2">
                  {product.attributes.join(", ")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ textTransform: "capitalize" }}
            >
              {getLang(lang, "label.AVAILABLE")}
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "capitalize" }}
            >
              <Typography display="inline" style={{ fontWeight: "700" }}>
                {product?.quantity || 0}
              </Typography>{" "}
              <Typography display="inline" color="textSecondary">
                {getLang(lang, "label.ITEMS")}
              </Typography>
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flex={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Skeleton variant="rect" width={40} height={40} />
            </Box>
            <Skeleton variant="text" height={40} width={100} />
          </Box>
          <Skeleton variant="text" height={40} width={100} />
        </Box>
      )}
    </Paper>
  );
}
