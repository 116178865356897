import { createSlice } from "@reduxjs/toolkit";
import { INSTANT_LUCKY_DRAW } from "../constants";
import {
  fetchAllInstantPrizeLegacy,
  // fetchSerialNumberList,
  searchSerialNumber,
  fetchInstantLuckyDrawListing,
  fetchInstantLuckyDrawParticipantTable,
  changeInstantLuckyDrawProgress,
  fetchAllInstantPrize,
} from "./instant-lucky-draw.action";

const initialState = {
  instantLuckyDrawListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  prizeListTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  prizeAllocation: {
    isOpenAllocation: false,
  },
  participantTable: {
    data: [],
    totalRecords: 0,
    filteredTotalRecords: 0,
  },
  participantsDetail: {
    detailDialogSetting: {
      isOpen: false,
    },
    id: "",
    address: "",
    consumerProfileUrl: "",
    contactNumber: "",
    customField: [],
    email: "",
    enc: "",
    name: "",
    productId: "",
    productName: "",
    serialNumber: "",
    status: "",
  },
  createInstantLuckyDrawDialogSetting: {
    isOpen: false,
    step: 0,
    demoOpen: false,
    demoType: "",
    demoTemplate: "",
  },
  viewEditInstantLuckyDrawDialogSetting: {
    isOpen: false,
    settingType: "view",
    registerType: "view",
    templateType: "view",
  },
  prizeDialogSetting: {
    isOpen: false,
    type: "view",
  },
  prizeDialogSettingV2: {
    isOpen: false,
    type: "view",
    selectedInstantLuckyDraw: null,
  },
  adjustDialogSetting: {
    isOpen: false,
  },
  winnerDialogSetting: {
    isOpen: false,
    winnerType: "view",
  },
  InstantLuckyDrawDialogInfo: {
    info: {
      id: "",
      name: "",
      eventPeriodStart: "",
      eventPeriodStartStatic: "",
      eventPeriodEnd: "",
      description: "",
      image: "",
      game: "",
      termsConditionText: true,
      termsConditionDocument: false,
      termsConditionDetail: { value: "<p></p>\n", data: [] },
      termsConditionDetailFile: "",
      termsAndConditions: "",
      productIds: [],
      progress: "",
      status: "",
    },
    registrationForm: [
      {
        fieldName: "name",
        labelType: "Text Field",
        type: "textField",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "Name",
        choice: [],
      },
      {
        fieldName: "address",
        labelType: "Address",
        type: "address",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "Address",
        choice: [],
      },
      {
        fieldName: "email",
        labelType: "Email Text Field",
        type: "textField",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "Email",
        choice: [],
      },
      {
        fieldName: "gender",
        labelType: "Multiple Choice",
        type: "multipleChoice",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "Gender",
        choice: ["Male", "Female"],
      },
      {
        fieldName: "contactNumber",
        labelType: "Contact Number",
        type: "contactNumber",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "Contact Number",
        choice: [],
      },
      {
        fieldName: "ic",
        labelType: "Identification Text Field",
        type: "textField",
        mandatory: true,
        visible: true,
        enableDelete: false,
        editing: false,
        fieldType: "standard",
        variantName: "IC Number",
        choice: [],
      },
    ],
  },
  prizeDialogInfo: {
    defaultPrizeDetail: {},
    prizeList: [],
    hasWinRate: 1,
  },
  serialNumberList: {
    // data: [],
    data: null,
    totalRecords: 0,
    filteredTotalRecords: 0,
    hasMoretoLoad: false,
    currentSearch: "",
  },
  excludeWinnerList: [],
  searchForSerialNumber: "",
  isLoadingInstanError: false,
  isLoadingInstantParticipantError: false,
  settingFormEditing: false,
  instantLuckyDrawAddField: {
    choice: ["", "", ""],
    enableDelete: true,
    labelType: "",
    value: "",
  },
  pageSetting: {
    length: 25,
    start: 0,
    search: "",
    product: [],
  },
};

const instantLuckyDrawSlice = createSlice({
  name: INSTANT_LUCKY_DRAW,
  initialState,
  reducers: {
    openCreationDialog(state) {
      state.createInstantLuckyDrawDialogSetting.isOpen = true;
    },
    closeCreationDialog(state) {
      state.createInstantLuckyDrawDialogSetting =
        initialState.createInstantLuckyDrawDialogSetting;
      state.InstantLuckyDrawDialogInfo.info = {
        id: state.InstantLuckyDrawDialogInfo.info.id,
        name: "",
        eventPeriodStart: "",
        eventPeriodEnd: "",
        description: "",
        image: "",
        game: "",
        termsConditionText: true,
        termsConditionDocument: false,
        termsConditionDetail: { value: "<p></p>", data: [] },
        termsAndConditions: "",
        productIds: [],
        progress: "",
        status: "",
      };
      state.InstantLuckyDrawDialogInfo.registrationForm =
        initialState.InstantLuckyDrawDialogInfo.registrationForm;
    },
    openViewEditDialog(state) {
      state.viewEditInstantLuckyDrawDialogSetting.isOpen = true;
    },
    closeViewEditDialog(state) {
      state.viewEditInstantLuckyDrawDialogSetting =
        initialState.viewEditInstantLuckyDrawDialogSetting;
      state.InstantLuckyDrawDialogInfo =
        initialState.InstantLuckyDrawDialogInfo;
    },
    openDemoDialog(state, action) {
      const { type, template } = action.payload;

      state.createInstantLuckyDrawDialogSetting.demoType = type;
      state.createInstantLuckyDrawDialogSetting.demoTemplate = template;
      state.createInstantLuckyDrawDialogSetting.demoOpen = true;
    },
    closeDemoDialog(state) {
      state.createInstantLuckyDrawDialogSetting.demoOpen = false;
    },
    openPrizeDialog(state, action) {
      const { payload } = action;
      state.prizeDialogSetting.isOpen = true;
      state.prizeDialogSetting.type = payload.type;
    },
    openPrizeDialogV2(state, action) {
      const { payload } = action;
      state.prizeDialogSettingV2.isOpen = true;
      state.prizeDialogSettingV2.type = payload.type;
      state.prizeDialogSettingV2.selectedInstantLuckyDraw =
        payload.instantLuckyDraw;
    },
    closePrizeDialog(state) {
      state.prizeDialogSetting = initialState.prizeDialogSetting;
      state.prizeDialogSettingV2 = initialState.prizeDialogSettingV2;
      state.prizeDialogInfo = initialState.prizeDialogInfo;
      state.excludeWinnerList = [];
    },
    openAllocationDialog(state) {
      state.prizeAllocation.isOpenAllocation = true;
    },
    setRegistrationForm(state, action) {
      const { payload } = action;

      state.registrationForm = payload.registrationForm;
    },
    closeAllocationDialog(state) {
      state.prizeAllocation.isOpenAllocation = false;
    },
    openAdjustDialog(state) {
      state.adjustDialogSetting.isOpen = true;
    },
    closeAdjustDialog(state) {
      state.adjustDialogSetting.isOpen = false;
    },
    openWinnerDialog(state) {
      state.winnerDialogSetting.isOpen = true;
    },
    closeWinnerDialog(state) {
      state.winnerDialogSetting.isOpen = false;
      state.excludeWinnerList = [];
    },
    openParticipantDialog(state) {
      state.participantsDetail.detailDialogSetting.isOpen = true;
    },
    closeParticipantDialog(state) {
      state.participantsDetail.detailDialogSetting.isOpen = false;
    },
    nextStep(state) {
      state.createInstantLuckyDrawDialogSetting.step =
        state.createInstantLuckyDrawDialogSetting.step + 1;
    },
    backStep(state) {
      state.createInstantLuckyDrawDialogSetting.step =
        state.createInstantLuckyDrawDialogSetting.step - 1;
    },
    setType(state, action) {
      const { payload } = action;
      switch (payload.index) {
        case 0:
          state.viewEditInstantLuckyDrawDialogSetting.settingType =
            payload.type;
          break;
        case 1:
          state.viewEditInstantLuckyDrawDialogSetting.templateType =
            payload.type;
          break;
        case 2:
          state.viewEditInstantLuckyDrawDialogSetting.registerType =
            payload.type;
          break;
        case 3:
          state.winnerDialogSetting.winnerType = payload.type;
          break;
        default:
          state.viewEditInstantLuckyDrawDialogSetting.settingType =
            payload.type;
          state.viewEditInstantLuckyDrawDialogSetting.templateType =
            payload.type;
          state.viewEditInstantLuckyDrawDialogSetting.registerType =
            payload.type;
          state.winnerDialogSetting.winnerType = payload.type;
          break;
      }
    },
    //For create
    setInstantLuckyDrawDetail(state, action) {
      const { payload } = action;

      state.InstantLuckyDrawDialogInfo.info.name = payload.name || "";
      state.InstantLuckyDrawDialogInfo.info.description =
        payload.description || "";
      state.InstantLuckyDrawDialogInfo.info.image = payload.image || "";
      state.InstantLuckyDrawDialogInfo.info.game = payload.game || "";
      state.InstantLuckyDrawDialogInfo.info.eventPeriodStart =
        payload.eventPeriodStart || "";
      state.InstantLuckyDrawDialogInfo.info.eventPeriodEnd =
        payload.eventPeriodEnd || "";
      state.InstantLuckyDrawDialogInfo.info.productIds =
        payload.productIds || "";
      state.InstantLuckyDrawDialogInfo.info.termsConditionText =
        payload.termsConditionText || false;
      state.InstantLuckyDrawDialogInfo.info.termsConditionDocument =
        payload.termsConditionDocument || false;
      if (payload.termsConditionText) {
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetail =
          payload.termsConditionDetail || "";
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetailFile = "";
      }
      if (payload.termsConditionDocument) {
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetailFile =
          payload.termsConditionDetailFile || "";
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetail = "";
      }
    },

    setInstantLuckyDrawTNC(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.info.termsConditionDetail = payload;
    },

    setInstantLuckyDrawDetailFromApi(state, action) {
      const { payload } = action;

      state.InstantLuckyDrawDialogInfo.info.name = payload.title || "";
      state.InstantLuckyDrawDialogInfo.info.description =
        payload.description || "";
      state.InstantLuckyDrawDialogInfo.info.image = payload.image_url || "";
      state.InstantLuckyDrawDialogInfo.info.game = payload.game || "";
      state.InstantLuckyDrawDialogInfo.info.eventPeriodStart =
        payload.start_at || "";
      state.InstantLuckyDrawDialogInfo.info.eventPeriodStartStatic =
        payload.start_at || "";
      state.InstantLuckyDrawDialogInfo.info.eventPeriodEnd =
        payload.end_at || "";
      state.InstantLuckyDrawDialogInfo.info.progress = payload.progress || "";

      const products = [];
      payload.products.forEach((product) => {
        const id = {
          id: product.uuid,
          name: product.name,
          picture: product.picture,
          attributes: product.attributes || [],
        };
        products.push(id);
      });

      state.InstantLuckyDrawDialogInfo.info.productIds = products;
      state.InstantLuckyDrawDialogInfo.info.termsConditionText =
        payload.termsConditionText || false;
      state.InstantLuckyDrawDialogInfo.info.termsConditionDocument =
        payload.termsConditionDocument || false;
      state.InstantLuckyDrawDialogInfo.info.termsConditionDetail =
        payload.termsConditionDetail || "";
      state.InstantLuckyDrawDialogInfo.info.termsAndConditions =
        payload.tnc_url || "";

      if (payload.tnc_url.substr(payload.tnc_url.length - 3) === "pdf") {
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetail =
          payload.tnc_url
            .split("/")
            [payload.tnc_url.split("/").length - 1].split(".pdf")[0] + ".pdf";
        state.InstantLuckyDrawDialogInfo.info.termsConditionDocument = true;
        state.InstantLuckyDrawDialogInfo.info.termsConditionText = false;
      } else {
        state.InstantLuckyDrawDialogInfo.info.termsConditionText = true;
        state.InstantLuckyDrawDialogInfo.info.termsConditionDocument = false;
      }
      state.InstantLuckyDrawDialogInfo.registrationForm =
        payload.custom_field || [];
      state.InstantLuckyDrawDialogInfo.info.id = payload.id;
      state.InstantLuckyDrawDialogInfo.info.uuid = payload.uuid;
    },

    setInstantLuckyDrawDetailUpdate(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.info.name =
        payload.title || state.InstantLuckyDrawDialogInfo.info.name;
      state.InstantLuckyDrawDialogInfo.info.description =
        payload.description ||
        state.InstantLuckyDrawDialogInfo.info.description;
      state.InstantLuckyDrawDialogInfo.info.image =
        payload.imgUrl || state.InstantLuckyDrawDialogInfo.info.image;
      state.InstantLuckyDrawDialogInfo.info.eventPeriodStart =
        payload.instantLuckyDrawDetail.eventPeriodStart ||
        state.InstantLuckyDrawDialogInfo.info.eventPeriodStart;
      state.InstantLuckyDrawDialogInfo.info.eventPeriodEnd =
        payload.instantLuckyDrawDetail.eventPeriodEnd ||
        state.InstantLuckyDrawDialogInfo.info.eventPeriodEnd;
      if (payload.productId) {
        const products = [];

        payload.productId.forEach((product) => {
          const id = {
            id: product,
          };
          products.push(id);
        });
        state.InstantLuckyDrawDialogInfo.info.productIds = products;
      }
      if (payload.tncUrl) {
        state.InstantLuckyDrawDialogInfo.info.termsConditionText =
          payload.termsConditionText ||
          state.InstantLuckyDrawDialogInfo.info.termsConditionText;
        state.InstantLuckyDrawDialogInfo.info.termsConditionDocument =
          payload.termsConditionDocument ||
          state.InstantLuckyDrawDialogInfo.info.termsConditionDocument;
        state.InstantLuckyDrawDialogInfo.info.termsConditionDetail =
          payload.termsConditionDetail ||
          state.InstantLuckyDrawDialogInfo.info.termsConditionDetail;
        state.InstantLuckyDrawDialogInfo.info.termsAndConditions =
          payload.tncUrl ||
          state.InstantLuckyDrawDialogInfo.info.termsAndConditions;
        if (payload.tncUrl.substr(payload.tncUrl.length - 3) === "pdf") {
          state.InstantLuckyDrawDialogInfo.info.termsConditionDetail =
            payload.tncUrl
              .split("/")
              [payload.tncUrl.split("/").length - 1].split(".pdf")[0] + ".pdf";
          state.InstantLuckyDrawDialogInfo.info.termsConditionDocument = true;
          state.InstantLuckyDrawDialogInfo.info.termsConditionText = false;
        } else {
          state.InstantLuckyDrawDialogInfo.info.termsConditionText = true;
          state.InstantLuckyDrawDialogInfo.info.termsConditionDocument = false;
        }
      }
      state.InstantLuckyDrawDialogInfo.registrationForm =
        payload.metadata || state.InstantLuckyDrawDialogInfo.registrationForm;
      state.InstantLuckyDrawDialogInfo.info.game =
        payload.game || state.InstantLuckyDrawDialogInfo.info.game;
    },

    setRegisterForm(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.registrationForm = payload;
      state.settingFormEditing = false;
      state.instantLuckyDrawAddField.labelType = "";
      state.instantLuckyDrawAddField.value = "";
      state.instantLuckyDrawAddField.enableDelete = true;
      state.instantLuckyDrawAddField.choice = ["", "", ""];
    },

    setRegisterFormEdit(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.registrationForm.forEach(
        (element, index) => {
          if (index === payload) {
            element.editing = true;
            state.settingFormEditing = true;
          } else element.editing = false;
        }
      );
    },

    setRegisterFormFinishEdit(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.registrationForm[
        payload.index
      ].variantName = payload.variantName;
      state.InstantLuckyDrawDialogInfo.registrationForm[payload.index].visible =
        payload.visible;
      state.InstantLuckyDrawDialogInfo.registrationForm[
        payload.index
      ].mandatory = payload.mandatory;
      state.InstantLuckyDrawDialogInfo.registrationForm[
        payload.index
      ].editing = false;
      state.InstantLuckyDrawDialogInfo.registrationForm[
        payload.index
      ].fieldType = payload.fieldType;
      state.InstantLuckyDrawDialogInfo.registrationForm[payload.index].choice =
        payload.choice;
      if (payload.fieldType !== "standard")
        state.InstantLuckyDrawDialogInfo.registrationForm[
          payload.index
        ].fieldName = payload.variantName.toLowerCase().replaceAll(" ", "_");
      state.settingFormEditing = false;
    },

    setInstantLuckDrawNewField(state, action) {
      const { payload } = action;
      state.instantLuckyDrawAddField.labelType = payload.labelType;
      state.instantLuckyDrawAddField.value = payload.value;
      state.instantLuckyDrawAddField.enableDelete = payload.enableDelete;
      state.instantLuckyDrawAddField.choice = payload.choice;
      state.settingFormEditing = payload.settingFormEditing;
    },

    setRegisterFormDelete(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.registrationForm.splice(payload, 1);
      state.settingFormEditing = false;
    },

    setInstantLuckyDrawID(state, action) {
      const { payload } = action;
      state.InstantLuckyDrawDialogInfo.info.id = payload.id;
    },

    setDefaultPrize(state, action) {
      const { payload } = action;
      state.prizeDialogInfo.defaultPrizeDetail = {
        ...payload.prizes[0],
        instantLuckyDrawId: payload.prizes[0].instantLuckyDrawId,
      };
    },

    setPrizeImage(state, action) {
      const { payload } = action;
      state.prizeDialogInfo.defaultPrizeDetail.prizeImage = payload;
    },

    setParticipantTable(state, action) {
      const { payload } = action;
      const tempData = [];

      state.participantTable.totalRecords = payload.records_total;
      state.participantTable.filteredTotalRecords = payload.records_filtered;

      payload.data.forEach((element) => {
        const order = {
          id: element.id || "",
          name: element.name || "",
          address: element.address || "",
          email: element.email || "",
          contactNumber: element.contact_no || "",
          enc: element.enc || "",
          serialNumber: element.serial_number || "",
          productId: element.product_id || "",
          productName: element.product_name || "",
          status: element.status || "",
          customField: element.custom_field || "",
          userId: element.user_id || "",
          createdAt: element.created_at || "",
          updatedAt: element.updated_at || "",
        };
        tempData.push(order);
      });

      state.participantTable.data = tempData;
    },

    resetParticipantTable(state, action) {
      state.participantTable = initialState.participantTable;
    },

    resetRegisterFormType(state, action) {
      state.createInstantLuckyDrawDialogSetting =
        initialState.createInstantLuckyDrawDialogSetting;
      state.viewEditInstantLuckyDrawDialogSetting =
        initialState.viewEditInstantLuckyDrawDialogSetting;
      state.settingFormEditing = initialState.settingFormEditing;
      state.instantLuckyDrawAddField = initialState.instantLuckyDrawAddField;
    },

    setParticipantDetail(state, action) {
      const { payload } = action;

      state.participantsDetail.id = payload.uuid;
      state.participantsDetail.address = payload.address
        ? payload.address
        : "-";
      state.participantsDetail.consumerProfileUrl =
        payload.consumer_profile_url;
      state.participantsDetail.contactNumber = payload.contact_no;
      for (const field of payload.custom_field) {
        field.value =
          (typeof field.value === "object" &&
            Object.keys(field.value).length === 0) ||
          !field.value
            ? ""
            : field.value;
      }
      state.participantsDetail.customField = payload.custom_field;
      state.participantsDetail.email = payload.email;
      state.participantsDetail.enc = payload.enc;
      state.participantsDetail.name = payload.name;
      state.participantsDetail.productId = payload.product_id;
      state.participantsDetail.productName = payload.product_name;
      state.participantsDetail.createdAt = payload.created_at;
      state.participantsDetail.serialNumber = payload.serial_number;
      state.participantsDetail.status = payload.status;
    },

    setPrizeEdit(state, action) {
      const { payload } = action;
      state.prizeDialogInfo.prizeList.forEach((element, index) => {
        if (index === payload) element.editing = true;
        else element.editing = false;
      });
    },

    setPrizeList(state, action) {
      const { payload } = action;
      state.prizeDialogInfo.prizeList = payload;
    },

    addBlankWinnerIntoPrize(state, action) {
      const { payload } = action;
      const info = {
        serialNumber: "",
        serialNumberId: "",
        isNew: true,
        prizeType: "preset",
        status: null,
      };
      state.prizeDialogInfo.prizeList[payload].winners.push(info);
    },

    setWinnerIntoPrize(state, action) {
      const { payload } = action;

      const previousWinner =
        state.prizeDialogInfo.prizeList[payload.index].winners[
          payload.winnerIndex
        ];
      const excludeWinnerIndex = state.excludeWinnerList.findIndex(
        (e) => e.enc === previousWinner.enc
      );
      const info = {
        serialNumber: payload.serialNumber,
        enc: payload.enc,
        prizeType: "preset",
        status: null,
      };
      const excludeInfo = {
        serialNumber: payload.serialNumber,
        enc: payload.enc,
      };

      if (previousWinner.isNew || payload.isNew)
        state.excludeWinnerList.push(excludeInfo);
      else state.excludeWinnerList[excludeWinnerIndex] = excludeInfo;

      state.prizeDialogInfo.prizeList[payload.index].winners[
        payload.winnerIndex
      ] = info;
    },

    deleteWinnerFromPrize(state, action) {
      const { payload } = action;
      // const winner =
      //   state.prizeDialogInfo.prizeList[payload.index].winners[
      //     payload.winnerIndex
      //   ];
      // const excludeWinnerIndex = state.excludeWinnerList.findIndex(
      //   (e) => e.enc === winner.enc
      // );
      //remove winner from prize using index
      state.prizeDialogInfo.prizeList[payload.index].winners.splice(
        payload.winnerIndex,
        1
      );
      // state.excludeWinnerList.splice(excludeWinnerIndex, 1);
    },

    setCancelEdit(state, action) {
      const { payload } = action;

      state.prizeDialogInfo.prizeList[payload.index].editing = false;
    },

    setPrizeFinishEdit(state, action) {
      const { payload } = action;
      state.prizeDialogInfo.prizeList[payload.index].prizeName =
        payload.prizeName ||
        state.prizeDialogInfo.prizeList[payload.index].prizeName;
      state.prizeDialogInfo.prizeList[payload.index].description =
        payload.description ||
        state.prizeDialogInfo.prizeList[payload.index].description;
      state.prizeDialogInfo.prizeList[payload.index].quantity =
        payload.quantity ||
        state.prizeDialogInfo.prizeList[payload.index].quantity;
      state.prizeDialogInfo.prizeList[payload.index].balance =
        payload.balance ||
        state.prizeDialogInfo.prizeList[payload.index].balance;
      state.prizeDialogInfo.prizeList[payload.index].prizeImage =
        payload.prizeImage ||
        state.prizeDialogInfo.prizeList[payload.index].prizeImage;

      state.prizeDialogInfo.prizeList[payload.index].isGiftCode =
        payload.isGiftCode;
      state.prizeDialogInfo.prizeList[payload.index].editing = false;
    },

    setPrizeListDelete(state, action) {
      const { payload } = action;

      state.prizeDialogInfo.prizeList.splice(payload, 1);
    },

    resetPrizeList(state, action) {
      state.prizeListTable = initialState.prizeListTable;
    },

    setSerialNumberSearch(state, action) {
      const { payload } = action;
      state.searchForSerialNumber = payload;
    },

    resetSerialNumberSearch(state, action) {
      state.searchForSerialNumber = initialState.searchForSerialNumber;
    },

    resetSerialNumberList(state, action) {
      state.serialNumberList = initialState.serialNumberList;
    },

    reorderRegisterFormField(state, action) {
      const { payload } = action;

      const newForm = Array.from(payload.latestform);
      const [removed] = newForm.splice(payload.sourceIndex, 1);
      newForm.splice(payload.destinationIndex, 0, removed);

      state.InstantLuckyDrawDialogInfo.registrationForm = newForm;
    },
    activeOrDeactivePrize(state, action) {
      const { payload } = action;
      const index = state.prizeDialogInfo.prizeList.findIndex(
        (e) => e.id === payload
      );
      state.prizeDialogInfo.prizeList[index].status =
        !state.prizeDialogInfo.prizeList[index].status;
    },
    updateIldPageSetting(state, action) {
      const { payload } = action;
      state.pageSetting = {
        ...state.pageSetting,
        ...payload,
      };
    },
  },
  extraReducers: {
    [fetchAllInstantPrize.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      state.InstantLuckyDrawDialogInfo.info.id = payload.id;
      state.excludeWinnerList = [];
      const defaultPrize = payload.prizes.find((e) => e.is_default);
      const defaultPrizeIndex = payload.prizes.findIndex((e) => e.is_default);

      if (defaultPrize) {
        state.prizeDialogInfo.defaultPrizeDetail = {
          ...defaultPrize,
          instantLuckyDrawId: payload.id,
          prizeName: defaultPrize.title,
          prizeImage: defaultPrize.image_url,
          winRate: defaultPrize.win_rate || 100,
        };
      }
      payload.prizes.forEach((element, index) => {
        if (index !== defaultPrizeIndex)
          tempArray.push({
            prizeName: element.title || "",
            description: element.description || "",
            quantity: element.quantity || 0,
            prizeImage: element.image_url || "",
            order: element.order,
            id: element.id,
            uuid: element.uuid,
            balance: element.balance || 0,
            winQuantity: element.win_quantity || 0,
            winners: element.winners
              ? element.winners.map((w) => ({
                  enc: w.enc,
                  serialNumber: w.serial_number,
                  instantLuckyDrawId: w.instant_lucky_draw_id,
                  prizeId: w.prize_id,
                  status: w.status,
                  participantId: w.participant_id,
                  prizeType: w.prize_type,
                }))
              : [],
            status: element.status === "active" ? true : false,
            editing: false,
            winRate: element.win_rate || 0,
            prizeType: element.prize_type,
            type:
              element.prize_type === 1
                ? 0
                : element.disbursement_company_id ?? 0,
            credit: element.credit_value || 0,
            provider: element.disbursement_provider,
          });

        for (const winner of element.winners) {
          const info = {
            serialNumber: winner.serial_number,
            enc: winner.enc,
          };
          state.excludeWinnerList.push(info);
        }
      });
      state.prizeDialogInfo.prizeList = tempArray;
      state.prizeDialogInfo.hasWinRate = payload.has_win_rate;
    },
    [fetchAllInstantPrizeLegacy.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];
      state.InstantLuckyDrawDialogInfo.info.id = payload.id;
      state.excludeWinnerList = [];
      if (payload.type === "dialog") {
        const defaultPrize = payload.data.find((e) => e.isDefault);
        const defaultPrizeIndex = payload.data.findIndex((e) => e.isDefault);

        if (defaultPrize) {
          state.prizeDialogInfo.defaultPrizeDetail = {
            ...defaultPrize,
            instantLuckyDrawId: defaultPrize.instantLuckyDraw.id,
            prizeName: defaultPrize.title,
            prizeImage: defaultPrize.imgUrl,
          };
        }
        payload.data.forEach((element, index) => {
          if (index !== defaultPrizeIndex)
            tempArray.push({
              prizeName: element.title || "",
              description: element.description || "",
              quantity: element.quantity || "",
              prizeImage: element.imgUrl || "",
              order: element.order,
              id: element.id,
              balance: element.balance || "",
              winners: element.winners || [],
              status: element.status === "active" ? true : false,
              isGiftCode: element.isGiftCode === 1 ? true : false,
              editing: false,
              winRate: element.winRate || 0,
              type: element.type || 1,
              credit: element.credit || 0,
            });

          for (const winner of element.winners) {
            const info = {
              serialNumber: winner.serialNumber,
              enc: winner.enc,
            };
            state.excludeWinnerList.push(info);
          }
        });
        state.prizeDialogInfo.prizeList = tempArray;
      } else {
        state.prizeListTable.totalRecords = payload.recordsTotal;
        state.prizeListTable.filteredTotalRecords = payload.recordsFiltered;
        payload.data.forEach((element, index) => {
          tempArray.push({
            prizeName: element.title || "",
            description: element.description || "",
            quantity: element.quantity || "",
            prizeImage: element.imgUrl || "",
            order: element.order,
            id: element.id,
            winners: element.winners || [],
            status: element.status === "active" ? true : false,
            isGiftCode: element.isGiftCode === 1 ? true : false,
            editing: false,
          });
        });

        state.prizeListTable.data = tempArray;
      }
    },
    // [fetchSerialNumberList.fulfilled]: (state, action) => {
    //   const { payload } = action;
    //   let tempArray = [];

    //   if (
    //     state.searchForSerialNumber === state.serialNumberList.currentSearch
    //   ) {
    //     tempArray = state.serialNumberList.data;
    //   }

    //   state.serialNumberList.currentSearch = state.searchForSerialNumber;
    //   state.serialNumberList.totalRecords = payload.recordsTotal;
    //   state.serialNumberList.filteredTotalRecords = payload.recordsFiltered;

    //   payload.data.forEach((item) => {
    //     tempArray.push({
    //       serialNumberId: item.id,
    //       serialNumber: item.serial_number,
    //       enc: item.enc,
    //       status: item.status,
    //     });
    //   });

    //   state.serialNumberList.hasMoretoLoad =
    //     state.serialNumberList.filteredTotalRecords >
    //     state.serialNumberList.data.length;

    //   state.serialNumberList.data = tempArray;
    // },
    [searchSerialNumber.fulfilled]: (state, action) => {
      const { payload } = action;

      state.serialNumberList.data = payload.data;
    },
    [fetchInstantLuckyDrawListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempData = [];

      state.instantLuckyDrawListTable.totalRecords = payload.records_total;
      state.instantLuckyDrawListTable.filteredTotalRecords =
        payload.records_filtered;

      payload.instant_lucky_draws.forEach((element) => {
        const order = {
          id: element.id || "",
          name: element.title || "",
          description: element.description || "",
          customField: element.custom_field || "",
          status: element.status === "active" ? true : false,
          startAt: element.start_at || "",
          endAt: element.end_at || "",
          createdAt: element.created_at || "",
          progress: element.progress || "",
          imgUrl: element.image_url || "",
          type: element.version || "current",
          prizeQty: element.prize_quantity || 0,
          transactionRemaining: element.transaction_remaining || 0,
          participantCount: element.participants_count || 0,
          products: element.products || [],
        };

        tempData.push(order);
      });

      state.isLoadingInstantError = false;
      state.instantLuckyDrawListTable.data = tempData;
    },
    [fetchInstantLuckyDrawListing.rejected]: (state, action) => {
      state.isLoadingInstantError = true;
    },
    [fetchInstantLuckyDrawParticipantTable.fulfilled]: (state, action) => {
      state.isLoadingInstantParticipantError = false;
    },
    [fetchInstantLuckyDrawParticipantTable.rejected]: (state, action) => {
      state.isLoadingInstantParticipantError = true;
    },
    [changeInstantLuckyDrawProgress.fulfilled]: (state, action) => {
      //status
      const { payload } = action;

      const index = state.instantLuckyDrawListTable.data.findIndex(
        (response) => response.id === payload.id
      );
      if (index > -1) {
        state.instantLuckyDrawListTable.data[index].status =
          payload.status === "active" ? true : false;
      }
    },
  },
});

export const instantLuckyDrawReducer = instantLuckyDrawSlice.reducer;
export const {
  openCreationDialog,
  closeCreationDialog,
  openViewEditDialog,
  closeViewEditDialog,
  openDemoDialog,
  closeDemoDialog,
  openPrizeDialog,
  closePrizeDialog,
  openAllocationDialog,
  closeAllocationDialog,
  openAdjustDialog,
  closeAdjustDialog,
  openWinnerDialog,
  closeWinnerDialog,
  openParticipantDialog,
  closeParticipantDialog,
  nextStep,
  backStep,
  setType,
  setInstantLuckyDrawDetail,
  setInstantLuckyDrawTNC,
  setInstantLuckyDrawDetailFromApi,
  setInstantLuckyDrawDetailUpdate,
  setRegisterForm,
  setRegistrationForm,
  setRegisterFormEdit,
  setRegisterFormFinishEdit,
  setRegisterFormDelete,
  setInstantLuckDrawNewField,
  setInstantLuckyDrawID,
  setParticipantTable,
  setParticipantDetail,
  setDefaultPrize,
  setPrizeList,
  setPrizeEdit,
  setPrizeImage,
  setCancelEdit,
  setPrizeFinishEdit,
  setPrizeListDelete,
  resetParticipantTable,
  resetPrizeList,
  resetRegisterFormType,
  setSerialNumberSearch,
  resetSerialNumberSearch,
  resetSerialNumberList,
  reorderRegisterFormField,
  addBlankWinnerIntoPrize,
  deleteWinnerFromPrize,
  activeOrDeactivePrize,
  setWinnerIntoPrize,
  updateIldPageSetting,
  openPrizeDialogV2,
} = instantLuckyDrawSlice.actions;
