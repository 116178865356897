import React, { useRef, useState } from "react";
import CompanyLogoPanelComponent from "./companyLogoPanel.component";
import { connect, useSelector } from "react-redux";
import Modal from "../../modal";
import { selectLoading } from "modules/notification";
import { updateAccountProfilePicture } from "../../../redux";
import { addAlert } from "modules/notification";
import { COMPANY_INFO_EDIT } from "lib/constants/accessRights";
import { ImageEditorPanel } from "components/panel";
import { getLang } from "app/feature/constants";
import {setImgFormatAndSize} from "../../../../../lib/FileHelper";

function CompanyLogoPanelContainer({
  logo,
  companyId,
  updateAccountProfilePicture,
  isUploading,
  addAlert
}) {
  const hasCompanyInfoEditAccessRight = useSelector(state => state.profile.accessRights.includes(COMPANY_INFO_EDIT));
  const inputImgRef = useRef();
  const [bufferImg, setBufferImg] = useState();
  const [isCroping, setCroping] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allowedImageExtension = [".jpeg", ".jpg", ".png"];
  const lang = useSelector(state => state.constant.languages)

  const handleImgChange = (e) => {
    const rawFile = e.target.files[0];

    validateImg(rawFile);
  };

  const handleDrop = (dropped) => {
    setDragging(false);
    const rawFile = dropped[0];
    
    validateImg(rawFile);
  };

  const validateImg = ( rawFile ) => {
    if (!ValidImageFormat(rawFile.name)) {
      addAlert({ severity: "error", message: getLang(lang, 'message.error.FORM_VALIDATE_IMAGE_TYPE') });
      return;
    }

    if ((rawFile.size / 1024 / 1024) > 6) {
      addAlert({ severity: "error", message: getLang(lang, "message.error.FORM_VALIDATE_FILE_SIZE", { value: 6 }) })
      return;
    }

    const image = URL.createObjectURL(rawFile);

    setBufferImg(image);
    setIsModalOpen(true);
    setCroping(true);
  }

  const ValidImageFormat = (file) => {
    for (let index = 0; index < allowedImageExtension.length; index++) {
      if (file.indexOf(allowedImageExtension[index]) !== -1) {
        return true;
      }
    }
    return false;
  };

  const handleClose = () => setIsModalOpen(false);

  const handleResult = async (base64Image) => {
    setCroping(false);
    setIsModalOpen(false);

    const fileName = `companyProfile${companyId}${Date.now()}.webp`;

    base64Image = await setImgFormatAndSize(base64Image, 2160, 2160, 'image/webp')

    updateAccountProfilePicture({
      fileName,
      base64Image,
    });
  };

  const handleDragEnter = () => {
    setDragging(true);
  };
  const handleDragLeave = () => {
    setDragging(false);
  };

  return (
    <>
      <CompanyLogoPanelComponent
        hasCompanyEditAccessRight={hasCompanyInfoEditAccessRight}
        logo={logo}
        inputImgRef={inputImgRef}
        handleImgChange={handleImgChange}
        handleDragEnter={handleDragEnter}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        dragging={dragging}
        lang={lang}
      />
      <Modal isOpen={isModalOpen || isUploading} handleClose={handleClose}>
        {isCroping && (
          <ImageEditorPanel
            img={bufferImg}
            handleCancel={handleClose}
            handleResult={handleResult}
          />
        )}
        {isUploading && <h3>{getLang(lang, "label.UPLOADING")}</h3>}
      </Modal>
    </>
  );
}


const mapStateToProps = (state) => ({
  logo: state.account.logoImg,
  companyId: state.account.id,
  isUploading: selectLoading(state, updateAccountProfilePicture.type),
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountProfilePicture: (payload) =>
    dispatch(updateAccountProfilePicture(payload)),
  addAlert: (payload) => dispatch(addAlert(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyLogoPanelContainer);
